import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CategoryModelGen } from '@prisma/components/generic-category-selection/generic-category-selection.service';
import { ErrorMessageService } from '@prisma/services/error-message.service';
import { TagsService } from '@prisma/services/tags.service';
import { _isDev, _isFeDev, _log, _warn } from '@shared/aux_helper_environment';
import {
  _cloneDeep,
  _equal,
  _get,
  _getNewNumberId,
  _hasValue,
  _immutable,
  _lGet,
  _normaliceArrayToCompare,
  _orderBy,
  _patchNgXsSatateWithSet,
  _reset_getNewNumberId,
  _set,
  _timeout,
  _uniqueElementsByKey,
} from '@shared/aux_helper_functions';
import {
  _addShelfsPrecalcDataNoModules,
  _checkProdIsFiltered,
  _mapProdWithAction,
} from 'app/spaces/static_common/spaces_aux_calc_prod_data';
import { _auxCheckConstraints } from 'app/spaces/static_common/spaces_aux_constraints';
import { _auxAvoidCollisions_simplePeg } from 'app/spaces/svgComponents/svgShelfs/svgDecorator-simplePegShelf';
import { PossibleErrorType } from 'app/store/app-state.model';
import { AttributeItemModel } from 'core/models/attribute.model';
import { GenericEntity } from 'core/models/generic-entity.model';
import { diff } from 'deep-diff';
import { Observable } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import {
  DEFAULT_OFFSET_MIN_PRD_SEP_X,
  DEFAULT_PRD_LIST_PAGE_SIZE,
  _auxSetFloatInfo,
  _auxSetModuleOrderInfo,
  _calcPlanogramPosAndSize,
  _clonePlanogram,
  _getAllUniqueStoresFromPlanogram,
  _getTmpProdId,
  _getTmpShelfId,
  _normaliceProdFilters,
  _recalcPlanogramProdsDataByStoresFilter,
} from '../../static_common/spaces_aux_helpers';
import { FormGeneral } from '../general-tab/store/planograms-tab.models';
import { PlanogramService } from '../planogram.service';
import {
  DEFAULT_AVOID_PEGSHELF_OVERLAY,
  _calcSizeProd,
  _getTmpModuleId,
  _set_internalItemId,
} from './../../static_common/spaces_aux_helpers';
import { ConstraintData, PartitionArea, PlanogramModel, PlanogramStateModel } from './planogram.interfaces';

const DEFAULT_MAX_UNDOS = 25;

function __normalizeModulesToCompare(planogramModules) {
  if (!(planogramModules?.length > 0)) return planogramModules;

  const normMod = planogramModules.map(mod => {
    if (mod == null) return mod;

    const newShelfs = (mod.shelfs || []).map(shelf => {
      if (shelf == null) return shelf;
      return { ...shelf, _size: null };
    });

    return { ...mod, shelfs: newShelfs, boxData: null, bottomData: null, _size: null };
  });

  return normMod;
}

/*ACTIONS*/
const FEATURE_KEY = '[spcaes-planogram]';
export class LoadPlanogramToEdit {
  static readonly type = `${FEATURE_KEY} LoadPlanogramToEdit`;
  constructor(readonly id?: number) {}
}
export class NewPlanogramToEdit {
  static readonly type = `${FEATURE_KEY} NewPlanogramToEdit`;
}
export class SaveEditedPlanogram {
  static readonly type = `${FEATURE_KEY} SaveEditedPlanogram`;
}
export class SaveEditedPlanogramAndTotalSlots {
  static readonly type = `${FEATURE_KEY} SaveEditedPlanogramAndTotalSlots`;
}

export class ChangePlanogramObj {
  static readonly type = `${FEATURE_KEY} ChangePlanogramObj`;
  constructor(readonly payload: { key: string; value: any }) {}
}
export class LoadPlanogramTimeLine {
  static readonly type = `${FEATURE_KEY} LoadPlanogramTimeLine`;
}
export class LoadPlanogramAudit {
  static readonly type = `${FEATURE_KEY} LoadPlanogramAudit`;
}
export class LoadComments {
  static readonly type = `${FEATURE_KEY} LoadPlanograLoadCommentsmAudit`;
}
export class EditProdProp {
  static readonly type = `${FEATURE_KEY} EditProdProp`;
  constructor(readonly prodId: number | string, readonly prop: string | string[], readonly value: any) {}
}
export class AddProdToShelf {
  static readonly type = `${FEATURE_KEY} addProdToShelf`;
  constructor(readonly prod: any, readonly shelfId: number | string, readonly removeId?: number | string) {}
}
export class AddModule {
  static readonly type = `${FEATURE_KEY} AddModule`;
  constructor(readonly module: any) {}
}
export class EditProdRemove {
  static readonly type = `${FEATURE_KEY} EditProdRemove`;
  constructor(readonly prodId: number | string) {}
}
export class EditProdRemoveMultipleByEan {
  static readonly type = `${FEATURE_KEY} EditProdRemoveMultipleByEan`;
  constructor(readonly prodEan: number | string) {}
}
export class EditShelfProp {
  static readonly type = `${FEATURE_KEY} EditShelfProp`;
  constructor(readonly shelfId: number | string, readonly prop: string | string[], readonly value: any) {}
}
export class EditShelfHeight {
  static readonly type = `${FEATURE_KEY} EditShelfHeight`;
  constructor(readonly shelfId: number | string, readonly newHeight: number) {}
}
export class EditShelfRemove {
  static readonly type = `${FEATURE_KEY} EditShelfRemove`;
  constructor(readonly shelfId: number | string) {}
}
export class EditShelfAdd {
  static readonly type = `${FEATURE_KEY} EditShelfAdd`;
  constructor(readonly moduleId: number | string, readonly shelf: any) {}
}
export class EditReorderModule {
  static readonly type = `${FEATURE_KEY} EditReorderModule`;
  constructor(readonly moduleId: number | string, readonly down: boolean) {}
}
export class EditModuleRemove {
  static readonly type = `${FEATURE_KEY} EditModuleRemove`;
  constructor(readonly moduleId: number | string) {}
}
export class EditModuleProp {
  static readonly type = `${FEATURE_KEY} EditModuleProp`;
  constructor(readonly moduleId: number | string, readonly prop: string | string[], readonly value: any) {}
}
export class EditModuleRemoveAllProds {
  static readonly type = `${FEATURE_KEY} EditModuleRemoveAllProds`;
  constructor(readonly moduleId: number | string) {}
}

export class EditModuleRemoveAllNullProds {
  static readonly type = `${FEATURE_KEY} EditModuleRemoveAllNullProds`;
  constructor(readonly moduleId: number | string) {}
}

export class EditRemoveAllNullProds {
  static readonly type = `${FEATURE_KEY} EditRemoveAllNullProds`;
}
export class EditRemoveAllProds {
  static readonly type = `${FEATURE_KEY} EditRemoveAllProds`;
}
export class EditReorderShelf {
  static readonly type = `${FEATURE_KEY} EditReorderShelf`;
  constructor(readonly shelfId: number | string, readonly down: boolean) {}
}
export class EditUndoLastAction {
  static readonly type = `${FEATURE_KEY} EditUndoLastAction`;
}
export class EditRedoLastAction {
  static readonly type = `${FEATURE_KEY} EditRedoLastAction`;
}
export class ChangeViewAction {
  static readonly type = `${FEATURE_KEY} ChangeViewAction`;
  constructor(readonly newVal: string) {}
}
export class UpdateProdList {
  static readonly type = `${FEATURE_KEY} UpdateProdList`;
  constructor(readonly filters: {}, readonly defaultFilters?: {}, readonly force?: boolean) {}
}
export class UpdateProdFilters {
  static readonly type = `${FEATURE_KEY} UpdateFilters`;
  constructor(readonly filters: {}, readonly defaultFilters?: {}, readonly force?: boolean) {}
}
export class UpdateProdFiltersTag {
  static readonly type = `${FEATURE_KEY} UpdateProdFiltersTag`;
  constructor(readonly selectedTags: {}) {}
}
export class UpdatePopList {
  static readonly type = `${FEATURE_KEY} UpdatePopList`;
  constructor(readonly filters: {}, readonly defaultFilters?: {}, readonly force?: boolean) {}
}
export class UpdateTotalSlots {
  static readonly type = `${FEATURE_KEY} UpdateTotalSlots`;
  constructor(readonly floatId: number, readonly value: number) {}
}
export class SaveTotalSlots {
  static readonly type = `${FEATURE_KEY} SaveTotalSlots`;
}
export class UpdateGeneralData {
  static readonly type = `${FEATURE_KEY} UpdateGeneralData`;
  constructor(readonly data: FormGeneral) {}
}
export class ResetFilters {
  static readonly type = `${FEATURE_KEY} ResetFilters`;
  constructor(readonly noList?: boolean) {}
}
export class ResetFiltersPop {
  static readonly type = `${FEATURE_KEY} ResetFiltersPop`;
}
export class PlanogramsFormGetABMAttributeAction {
  static readonly type = `${FEATURE_KEY} PlanogramsFormGetABMAttributeAction`;
}
export class PlanogramsFormGetABMAttributeSuccessAction {
  static readonly type = `${FEATURE_KEY} PlanogramsFormGetABMAttributeSuccessAction`;
  constructor(readonly payload: AttributeItemModel[]) {}
}
export class PlanogramsFormSetDinamicTagsSelectedGeneralAction {
  static readonly type = `${FEATURE_KEY} PlanogramsFormSetDinamicTagsSelectedGeneralAction`;
  constructor(readonly payload: AttributeItemModel[]) {}
}
export class EditFloatProp {
  static readonly type = `${FEATURE_KEY} EditFloatProp`;
  constructor(readonly floatId: number | string, readonly prop: string | string[], readonly value: any) {}
}
export class RemoveFloat {
  static readonly type = `${FEATURE_KEY} RemoveFloat`;
  constructor(readonly floatId: number | string) {}
}
export class AddFloat {
  static readonly type = `${FEATURE_KEY} addFloat`;
  constructor(readonly float: any) {}
}
export class SetFilerProdsByStores {
  static readonly type = `${FEATURE_KEY} SetFilerProdsByStores`;
  constructor(readonly filerProdsByStores: (number | string)[]) {}
}
export class SetAttrGroup {
  static readonly type = `${FEATURE_KEY} SetAttrGroup`;
  constructor(readonly attrGroup: any) {}
}
export class ResetFilerProdsByStores {
  static readonly type = `${FEATURE_KEY} ResetFilerProdsByStores`;
}
export class ResetAllFiltersAnalitica {
  static readonly type = `${FEATURE_KEY} ResetAllFiltersAnalitica`;
}
export class SetTimeLineIndex {
  static readonly type = `${FEATURE_KEY} SetTimeLineIndex`;
  constructor(readonly index: number) {}
}
export class ResetTimeLineIndex {
  static readonly type = `${FEATURE_KEY} ResetTimeLineIndex`;
}
export class ResetTimeLine {
  static readonly type = `${FEATURE_KEY} ResetTimeLine`;
}
export class AddConstraints {
  static readonly type = `${FEATURE_KEY} addConstraints`;
  constructor(readonly payload: ConstraintData) {}
}
export class RemoveConstraints {
  static readonly type = `${FEATURE_KEY} RemoveConstraints`;
  constructor(readonly id: number | string) {}
}
export class SetConstraintsView {
  static readonly type = `${FEATURE_KEY} SetConstraintsView`;
  constructor(readonly payload: number | string) {}
}

export class PlanogramForExportAction {
  static readonly type = `${FEATURE_KEY} Get list for export`;
}
export class PlanogramForExportSuccessAction {
  static readonly type = `${FEATURE_KEY} Get list for export succes`;
  constructor(readonly payload: string) {}
}

export class PlanogramSetErrorAction {
  static readonly type = `${FEATURE_KEY} Set errors`;
  constructor(readonly payload: PossibleErrorType) {}
}

export class CloneModuleAction {
  static readonly type = `${FEATURE_KEY} PlanogramForCloneModuleAction`;
  constructor(readonly moduleId: number | string, readonly cloneProds: boolean) {}
}

export class MirrorProdsInShelfs {
  static readonly type = `${FEATURE_KEY} MirrorProdsShelfs`;
  constructor(readonly shelfIds?: number[] | string[]) {}
}

export class AutoCompleteShelf {
  static readonly type = `${FEATURE_KEY} AutoCompleteShelf`;
  constructor(readonly shelfId: number | string, readonly remainingWidthSpace?: number) {}
}

export class AddSavedAreaAction {
  static readonly type = `${FEATURE_KEY} AddSavedArea`;
  constructor(public payload: PartitionArea) {}
}
export class DeleteSavedAreaAction {
  static readonly type = `${FEATURE_KEY} DeleteSavedArea`;
  constructor(public payload: number) {}
}

export class DeleteAllSavedAreaAction {
  static readonly type = `${FEATURE_KEY} DeleteAllSavedArea`;
  constructor() {}
}

export class EditSavedAreaAction {
  static readonly type = '[Planogram] Edit Saved Area';
  constructor(public payload: { id: number; data: Partial<PartitionArea> }) {}
}

const getDefaultPlanogram = () => {
  return _cloneDeep({
    id: null,
    categoriesSelected: [],
    planogramName: '',
    description: '',
    storesSelected: [],

    /**/

    imageUrl: null,
    width: null,
    height: null,
    depth: null,

    /**/

    _viewType: 'PITSHELFVIEW' /* --NORMALVIEW o Si es PITSHELFVIEW calcula los tamaños como vista de pozo */,
    modules: null,
    floats: null,
    publishedDate: null,
    stageSize: { w: 700, h: 500 },
    constraints: [],
    planogramType: 'Planogram', // | 'Structure' | 'Planogram'| 'GenericSpace' | String
    itemScope: {
      itemIds: [],
      selectionType: 1,
      genericCategorysSelection: null,
      tagValueIdsToInclude: [],
      tagValueIdsToExclude: [],
      onlyItemsAllowedForSelling: true,
    },
    promotionMaterial: [],
    savedAreas: [],
  });
};

const _hasFilters = (actualFilters, defaultFilters = null) => {
  if (defaultFilters && _equal({ ...actualFilters, pageIndex: null, stores: null }, { ...defaultFilters, pageIndex: null, stores: null }))
    return false;

  let hasVals = false;

  Object.entries(actualFilters).forEach(([key, value]) => {
    if (!hasVals && key !== 'pageIndex' && _hasValue(value)) hasVals = true;
  });

  return hasVals;
};

const _filterPlanogramByStores = (planogram, stores) => {
  const rv = _recalcPlanogramProdsDataByStoresFilter(planogram, stores);
  if (!true) _log('[_filterPlanogramByStores]', { rv, planogram, stores });
  return rv;
};

export const FEATURE_ID = 'SpcaesPlanogramStore';
@Injectable({ providedIn: 'root' })
@State<PlanogramStateModel>({
  name: FEATURE_ID,
  defaults: {
    planogramToEdit: getDefaultPlanogram(),
    planogramToEditInLoad: false,
    planogramToEditInSave: false,
    planogramToEditOriginal: null,
    planogramToEditUndos: [],
    planogramToEditUndosIndex: 0,
    allUniqueStores: null,
    filerProdsByStores: [],
    planogramTimeLine: null,
    planogramTimeLineIndex: null,
    planogramAudit: null,
    planogramComments: null,
    planogramForExport: [],
    excelUrl: null,
    constraintView: null,
    prodList: {
      actualFilters: {},
      defaultFilters: {},
      prodListdata: null,
      totalItems: null,
      inLoad: false,
    },
    popList: {
      actualFilters: {},
      defaultFilters: {},
      popListdata: null,
      totalItems: null,
      inLoad: false,
    },
    generalList: {
      categoryOptions: [],
      storeOptions: [],
      allTags: [],
      selectedTags: [],
    },
    itemScope: {
      itemIds: [],
      selectionType: 1,
      genericCategorysSelection: {
        categorys: [],
        categorysExcluded: [],
        subCategorys: [],
        subCategorysExcluded: [],
      },
      tagValueIdsToInclude: [],
      tagValueIdsToExclude: [],
      onlyItemsAllowedForSelling: true,
    },
    vendorsAsyncList: [],
    brandsAsyncList: [],
    attrGroup: null,
    promotionMaterial: [],
  },
})
export class PlanogramState {
  constructor(
    private service: PlanogramService,
    private store: Store,
    private tagService: TagsService,
    private readonly errorMessageService: ErrorMessageService
  ) {}

  @Selector()
  static getGeneral(state: PlanogramStateModel): FormGeneral {
    const actualPE = state.planogramToEdit;
    const rv = {
      id: actualPE.id,
      code: actualPE.code,
      name: actualPE.planogramName,
      categories: actualPE.categoriesSelected,
      stores: actualPE.storesSelected,
      description: actualPE.description,
      planogramType: actualPE.planogramType,

      imageUrl: actualPE.imageUrl,
      width: actualPE.width,
      height: actualPE.height,
      depth: actualPE.depth,
    };
    return rv;
  }

  @Selector()
  static getGeneralOriginal(state: PlanogramStateModel): FormGeneral {
    const actualPE = state.planogramToEditOriginal;
    const rv = {
      id: actualPE.id,
      name: actualPE.planogramName,
      code: actualPE.code,
      categories: actualPE.categoriesSelected,
      stores: actualPE.storesSelected,
      description: actualPE.description,
      planogramType: actualPE.planogramType,

      imageUrl: actualPE.imageUrl,
      width: actualPE.width,
      height: actualPE.height,
      depth: actualPE.depth,
    };
    return rv;
  }

  @Selector()
  static getPlanogramToEdit(state: PlanogramStateModel): PlanogramModel {
    //is planogramTimeLine
    let planogramTimeLine =
      state.planogramTimeLineIndex !== null && state.planogramTimeLine && state.planogramTimeLine[state.planogramTimeLineIndex]
        ? state.planogramTimeLine[state.planogramTimeLineIndex]
        : null;

    let rvPlanoGram = planogramTimeLine ? { ...planogramTimeLine, _isTimeLine: true } : state.planogramToEdit;

    //filerProdsByStores
    if (
      state.allUniqueStores &&
      state.allUniqueStores.length &&
      state.filerProdsByStores &&
      state.filerProdsByStores.length &&
      !_equal(state.filerProdsByStores, state.allUniqueStores)
    ) {
      rvPlanoGram = _filterPlanogramByStores(rvPlanoGram, state.filerProdsByStores);
    }

    //Filtra prods
    const _filters = _normaliceProdFilters(_get(state, 'prodList.actualFilters'));
    if (_filters && !_equal(_filters, {})) {
      rvPlanoGram = _mapProdWithAction(rvPlanoGram, prd => {
        return { ...prd, itemData: { ...prd.itemData, _isFiltered: _checkProdIsFiltered(prd, _filters) } };
      });
    }

    //dataCalc
    const minMaxDataNoFilters = _addShelfsPrecalcDataNoModules(rvPlanoGram, false);
    const minMaxData = _addShelfsPrecalcDataNoModules(rvPlanoGram, true);

    rvPlanoGram = { ...rvPlanoGram, minMaxData, minMaxDataNoFilters, planogramName: state.planogramToEdit.planogramName };

    //Si hay vista de constraints
    if (state.constraintView !== null) {
      //Si van todos los constraints ('ALL') manda el parámetro en nulo, si no lo pasa tal cual
      let constraintOnly = state.constraintView !== 'ALL' ? state.constraintView : null;
      if (constraintOnly !== null && !Array.isArray(constraintOnly)) constraintOnly = [constraintOnly];
      rvPlanoGram = _auxCheckConstraints(rvPlanoGram, minMaxData, constraintOnly);
    }

    return rvPlanoGram;
  }

  @Selector()
  static getAllUniqueStores(state: PlanogramStateModel): any[] {
    return _cloneDeep(state.allUniqueStores);
  }

  @Selector()
  static getFilerProdsByStores(state: PlanogramStateModel): any[] {
    return state.filerProdsByStores;
  }

  @Selector()
  static getAttrGroup(state: PlanogramStateModel): any[] {
    return state.attrGroup;
  }

  @Selector()
  static getCategoryOptions(state: PlanogramStateModel): Array<CategoryModelGen> {
    return state.generalList.categoryOptions;
  }

  @Selector()
  static getStoreOptions(state: PlanogramStateModel): Array<GenericEntity> {
    return state.generalList.storeOptions;
  }

  @Selector()
  static getProdsItemData(state: PlanogramStateModel): Array<any> {
    return _getPlanogramToEditProdsItemData(state);
  }

  @Selector()
  static getTags(state: PlanogramStateModel): Array<AttributeItemModel> {
    return state.generalList.allTags;
  }

  @Selector()
  static getSelectedTags(state: PlanogramStateModel): Array<AttributeItemModel> {
    return state.generalList.selectedTags;
  }

  @Selector()
  static getIsEdit(state: PlanogramStateModel): boolean {
    return !!state.planogramToEdit.id && state.planogramToEdit.id > 0;
  }

  @Selector()
  static getUndosIndex(state: PlanogramStateModel): Number {
    return state.planogramToEditUndosIndex;
  }

  @Selector()
  static getTimeLineIndex(state: PlanogramStateModel): Number {
    return state.planogramTimeLineIndex;
  }

  @Selector()
  static getAllTimeLine(state: PlanogramStateModel): PlanogramModel[] {
    return state.planogramTimeLine;
  }

  @Selector()
  static getAudit(state: PlanogramStateModel): PlanogramModel[] {
    return state.planogramAudit;
  }

  @Selector()
  static getComments(state: PlanogramStateModel): PlanogramModel[] {
    return state.planogramComments;
  }

  @Selector()
  static planogramToEditHasChanged(state: PlanogramStateModel): boolean {
    //Compara si hay datos distintos al planograma original
    if (!state.planogramToEdit || !state.planogramToEdit.modules) return false;
    if (!state.planogramToEditOriginal) return false;

    const planogramToEdit = {
      ...state.planogramToEdit,
      //
      _viewType: null,
      stageSize: null,
      hasInverseTraffic: null,
      publishedDate: null,
      sellingArea: null,
      //
      modules: __normalizeModulesToCompare(state.planogramToEdit?.modules),
      categoriesSelected: _normaliceArrayToCompare(state.planogramToEdit?.categoriesSelected),
      storesSelected: _normaliceArrayToCompare(state.planogramToEdit?.storesSelected),
      promotionMaterial: _normaliceArrayToCompare(state.planogramToEdit?.promotionMaterial),
    };

    const planogramToEditOriginal = {
      ...state.planogramToEditOriginal,
      //
      _viewType: null,
      stageSize: null,
      hasInverseTraffic: null,
      publishedDate: null,
      sellingArea: null,
      //
      modules: __normalizeModulesToCompare(state.planogramToEditOriginal?.modules),
      categoriesSelected: _normaliceArrayToCompare(state.planogramToEditOriginal?.categoriesSelected),
      storesSelected: _normaliceArrayToCompare(state.planogramToEditOriginal?.storesSelected),
      promotionMaterial: _normaliceArrayToCompare(state.planogramToEditOriginal?.promotionMaterial),
    };

    let rv = !_equal(planogramToEdit, planogramToEditOriginal);

    if (rv && false) {
      _log(diff(planogramToEdit.modules, planogramToEditOriginal.modules), planogramToEdit.modules);
    }

    return rv;
  }

  @Selector()
  static editHasUndo(state: PlanogramStateModel): boolean {
    const undosSize = state.planogramToEditUndos.length;
    const index = state.planogramToEditUndosIndex;
    return undosSize > index;
  }

  @Selector()
  static isPlanogramToEditInLoad(state: PlanogramStateModel): boolean {
    const planogramToEditInLoad = state.planogramToEditInLoad;
    return planogramToEditInLoad;
  }

  @Selector()
  static editHasRedo(state: PlanogramStateModel): boolean {
    const index = state.planogramToEditUndosIndex;
    return index > 1;
  }

  @Selector()
  static getViewAction(state: PlanogramStateModel): string {
    return state.planogramToEdit._viewType;
  }

  @Selector()
  static getActualprodList(state: PlanogramStateModel) {
    return _cloneDeep(state.prodList);
  }

  @Selector()
  static getActualFilters(state: PlanogramStateModel) {
    return _cloneDeep(state.prodList.actualFilters);
  }

  @Selector()
  static getActualpopList(state: PlanogramStateModel) {
    return _cloneDeep(state.popList);
  }

  @Selector()
  static getActualPopFilters(state: PlanogramStateModel) {
    return _cloneDeep(state.popList.actualFilters);
  }

  @Selector()
  static hasFilters(state: PlanogramStateModel) {
    return _hasFilters(state.prodList.actualFilters, _get(state, 'prodList.defaultFilters'));
  }

  @Selector()
  static getVendorsAsyncList(state: PlanogramStateModel) {
    return state.vendorsAsyncList;
  }

  @Selector()
  static getBrandsAsyncList(state: PlanogramStateModel) {
    return state.brandsAsyncList;
  }

  @Selector()
  static getConstraintView(state: PlanogramStateModel) {
    return state.constraintView;
  }

  @Selector()
  static getConstraints(state: PlanogramStateModel): ConstraintData[] {
    return state.planogramToEdit.constraints;
  }

  @Selector()
  static getExcel(state: PlanogramStateModel): string {
    return state.excelUrl;
  }

  @Selector()
  static getSavedAreas(state: PlanogramStateModel) {
    return state.planogramToEdit.savedAreas;
  }

  @Action(EditSavedAreaAction)
  editSavedArea(context: StateContext<PlanogramStateModel>, action: EditSavedAreaAction): void {
    const state = context.getState();
    const savedAreas = state.planogramToEdit.savedAreas || [];

    const updatedSavedAreas = savedAreas.map(area => (area.id === action.payload.id ? { ...area, ...action.payload.data } : area));

    context.patchState({
      planogramToEdit: { ...state.planogramToEdit, savedAreas: updatedSavedAreas },
    });
  }

  @Action(AddSavedAreaAction)
  addSavedArea(context: StateContext<PlanogramStateModel>, action: AddSavedAreaAction): void {
    const state = context.getState();
    const savedAreas = state.planogramToEdit.savedAreas || [];

    context.patchState({
      planogramToEdit: { ...state.planogramToEdit, savedAreas: [...savedAreas, action.payload] },
    });
  }

  @Action(DeleteSavedAreaAction)
  deleteSavedArea(context: StateContext<PlanogramStateModel>, action: DeleteSavedAreaAction): void {
    const state = context.getState();
    const savedAreas = state.planogramToEdit.savedAreas.filter(area => area.id !== action.payload);

    context.patchState({
      planogramToEdit: { ...state.planogramToEdit, savedAreas: [...savedAreas] },
    });
  }

  @Action(DeleteAllSavedAreaAction)
  deleteAllSavedArea(context: StateContext<PlanogramStateModel>): void {
    const state = context.getState();

    context.patchState({
      planogramToEdit: { ...state.planogramToEdit, savedAreas: [] },
    });
  }

  @Action(ResetFilters)
  async resetFilters(context: StateContext<PlanogramStateModel>, payload: ResetFilters) {
    const actualState = context.getState();
    if (!_hasFilters(actualState.prodList.actualFilters, _get(actualState, 'prodList.defaultFilters'))) return;

    const { noList } = payload;
    if (noList === true) {
      this.store.dispatch(new UpdateProdFilters(_cloneDeep(_get(actualState, 'prodList.defaultFilters', {}))));
      return;
    }

    this.store.dispatch(new UpdateProdList(_cloneDeep(_get(actualState, 'prodList.defaultFilters', {}))));
  }

  @Action(UpdateProdFilters)
  async updateProdFilters(context: StateContext<PlanogramStateModel>, payload: UpdateProdFilters) {
    const actualState = context.getState();
    const newFilters: any = payload.filters || {};
    const defaultFilters = payload.defaultFilters || actualState.prodList.defaultFilters || {};

    if (_isFeDev()) _log('[UpdateProdFilters]', newFilters);

    context.patchState({
      prodList: {
        actualFilters: { ...newFilters },
        defaultFilters: defaultFilters,
      },
    });
  }

  @Action(UpdateProdFiltersTag)
  async updateProdFiltersTags(context: StateContext<PlanogramStateModel>, payload: UpdateProdFiltersTag) {
    let newSelectedTags = payload.selectedTags || null;

    const actualState = context.getState();
    const actualFilters = actualState.prodList.actualFilters;

    const newSelectedTagsId = _lGet(newSelectedTags, 'includedValues[0]');
    const actualFiltersTagId = _lGet(actualFilters, 'selectedTags[0].includedValues[0]');

    if (actualFiltersTagId !== undefined && newSelectedTagsId === actualFiltersTagId) {
      newSelectedTags = null;
    }

    let rv = {
      prodList: {
        ...actualState.prodList,
        actualFilters: { ...actualFilters, selectedTags: [newSelectedTags] },
      },
    };

    if (!newSelectedTags) delete rv.prodList.actualFilters.selectedTags;

    context.patchState(rv);
  }

  @Action(UpdateProdList)
  async updateProdList(context: StateContext<PlanogramStateModel>, payload: UpdateProdList) {
    const actualState = context.getState();
    const newFilters: any = payload.filters || {};
    const defaultFilters = payload.defaultFilters || actualState.prodList.defaultFilters || {};
    const actualFilters = actualState.prodList.actualFilters;
    const pageIndex = newFilters.pageIndex || actualFilters.pageIndex || 0;

    let changeDefFilters = !_equal(defaultFilters, actualState.prodList.defaultFilters);

    // Mismos filtros y ya tiene listado, aborta
    if (
      !changeDefFilters &&
      _equal(newFilters, actualState.prodList.actualFilters) &&
      actualState.prodList.prodListdata &&
      actualState.prodList.prodListdata.length
    ) {
      return;
    }

    const newFiltersWithOutPage = _cloneDeep(newFilters);
    const actualFiltersWithOutPage = _cloneDeep(actualFilters);
    delete newFiltersWithOutPage.pageIndex;
    delete actualFiltersWithOutPage.pageIndex;
    let changeFilters = changeDefFilters || !_equal(newFiltersWithOutPage, actualFiltersWithOutPage);

    if (!changeFilters) {
      //Cambio la página / suma

      let totalItems = actualState.prodList.totalItems || 0;
      let pageSize = defaultFilters.pageSize || DEFAULT_PRD_LIST_PAGE_SIZE;

      if (totalItems && pageSize && pageIndex) {
        //validacion Tope de páginas
        let totalPages = Math.ceil(totalItems / pageSize);
        if (false) _log({ currentPage: pageIndex, totalPages, totalItems, pageSize });
        if (pageIndex >= totalPages) return;
      }

      if (totalItems && _get(actualState, 'prodList.prodListdata.length') >= totalItems) {
        //Los items actuales son o superan los totales / otra validacion Tope de páginas
        return;
      }

      context.patchState({
        prodList: {
          ...actualState.prodList,
          defaultFilters: defaultFilters,
          inLoad: true,
        },
      });
    } else {
      //Cambio un filtro / resetea
      newFilters.pageIndex = 0;
      context.patchState({
        prodList: {
          actualFilters: newFilters,
          defaultFilters: defaultFilters,
          prodListdata: [],
          inLoad: true,
          totalItems: null,
        },
      });
    }

    const _hasTags =
      newFilters && newFilters.selectedTags && newFilters.selectedTags.length
        ? newFilters.selectedTags.reduce((acc, el) => {
            if (el && el.includedValues && el.includedValues.length) acc = true;
            return acc;
          }, false)
        : false;

    const _hasNoCategoriesSelected =
      (!newFilters.subCategories || !newFilters.subCategories.length) && (!newFilters.categories || !newFilters.categories.length);

    /*Si tiene TAGs pero no cats, fuerza al servicio para que no filtre por categorías*/
    const _noFilterCategories = true && _hasTags && _hasNoCategoriesSelected;

    if (true) _log('\n\n[_noFilterCategories]', { _noFilterCategories, _hasTags, _hasCategoriesSelected: _hasNoCategoriesSelected });

    this.service
      .getMenuProdList(newFilters, defaultFilters, actualState.planogramToEdit.id, _noFilterCategories)
      .pipe(first())
      .subscribe(listData => {
        if (!listData) return;

        const listDataItems = listData.items;
        const totalItems = listData.totalItems || 0;
        if (!listDataItems) return;

        if (true) _log('\n[getMenuProdList]', { listData, newFilters, defaultFilters, changeFilters });

        let newlistData;
        if (!changeFilters) {
          //Suma
          newlistData = [...(actualState.prodList.prodListdata || []), ...listDataItems];
        } else {
          //Reseta
          newlistData = listDataItems;
        }

        if (true) _log('[UpdateProdList]', { filters: newFilters });

        let newActualState = context.getState();

        let patchFilters = true && newActualState.prodList ? newActualState.prodList.actualFilters : newFilters;
        let patchFiltersDefault = true && newActualState.prodList ? newActualState.prodList.defaultFilters : defaultFilters;
        patchFilters = { ...patchFilters, pageIndex: changeFilters ? 0 : pageIndex || 0 };

        context.patchState({
          prodList: {
            actualFilters: patchFilters,
            defaultFilters: patchFiltersDefault,
            prodListdata: newlistData,
            totalItems: totalItems,
            inLoad: false,
          },
        });
      });
  }

  @Action(ResetFiltersPop)
  async resetFiltersPop(context: StateContext<PlanogramStateModel>, payload: ResetFiltersPop) {
    const actualState = context.getState();
    if (!_hasFilters(actualState.popList.actualFilters)) return;
    this.store.dispatch(new UpdatePopList(_cloneDeep(_get(actualState, 'popList.defaultFilters', {}))));
  }

  @Action(UpdatePopList)
  async updatePopList(context: StateContext<PlanogramStateModel>, payload: UpdatePopList) {
    const actualState = context.getState();
    const newFilters: any = payload.filters || {};
    newFilters.stores = actualState.planogramToEdit.storesSelected;
    const defaultFilters = payload.defaultFilters || actualState.popList.defaultFilters || {};
    const actualFilters = actualState.popList.actualFilters;
    const pageIndex = actualFilters.pageIndex || 0;

    const isPopMaterialEspecial = newFilters.isPopMaterialEspecial; //DES-4393

    let changeDefFilters = !_equal(defaultFilters, actualState.prodList.defaultFilters);

    // Mismos filtros y ya tiene listado, aborta
    if (
      !changeDefFilters &&
      _equal(newFilters, actualState.popList.actualFilters) &&
      actualState.popList.popListdata &&
      actualState.popList.popListdata.length
    ) {
      return;
    }

    const newFiltersWithOutPage = _cloneDeep(newFilters);
    const actualFiltersWithOutPage = _cloneDeep(actualFilters);
    delete newFiltersWithOutPage.pageIndex;
    delete actualFiltersWithOutPage.pageIndex;
    let changeFilters = changeDefFilters || !_equal(newFiltersWithOutPage, actualFiltersWithOutPage);

    if (!changeFilters) {
      //Cambio la página / suma

      let totalItems = actualState.popList.totalItems || 0;
      let pageSize = defaultFilters.pageSize || DEFAULT_PRD_LIST_PAGE_SIZE;

      if (totalItems && pageSize && pageIndex) {
        //validacion Tope de páginas
        let totalPages = Math.ceil(totalItems / pageSize);
        if (!false) _log({ currentPage: pageIndex, totalPages, totalItems, pageSize });
        if (pageIndex >= totalPages) return;
      }

      if (totalItems && _get(actualState, 'popList.popListdata.length') >= totalItems) {
        //Los items actuales son o superan los totales / otra validacion Tope de páginas
        return;
      }

      context.patchState({
        popList: {
          ...actualState.popList,
          defaultFilters: defaultFilters,
          inLoad: true,
        },
      });
    } else {
      //Cambio un filtro / resetea
      newFilters.pageIndex = 0;
      context.patchState({
        popList: {
          actualFilters: newFilters,
          defaultFilters: defaultFilters,
          popListdata: [],
          inLoad: true,
          totalItems: null,
        },
      });
    }

    this.service
      .getMenuPopList(newFilters, defaultFilters, actualState.planogramToEdit.id, isPopMaterialEspecial)
      .pipe(first())
      .subscribe(listData => {
        if (!listData) return;

        const listDataItems = listData.items;
        const totalItems = listData.totalItems || 0;
        if (!listDataItems) return;

        if (true) _log('\n[getMenuPopList]', { listData, newFilters, defaultFilters, changeFilters });

        let newlistData;
        if (!changeFilters) {
          //Suma
          newlistData = [...(actualState.popList.popListdata || []), ...listDataItems];
        } else {
          //Reseta
          newlistData = listDataItems;
        }

        context.patchState({
          popList: {
            actualFilters: { ...newFilters },
            popListdata: newlistData,
            totalItems: totalItems,
            defaultFilters: defaultFilters,
            inLoad: false,
          },
        });
      });
  }

  @Action(ChangeViewAction)
  async changeViewAction(context: StateContext<PlanogramStateModel>, payload: ChangeViewAction) {
    let state = context.getState();
    const { newVal } = payload;

    if (state.planogramToEdit._viewType === newVal) return;

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds(state.planogramToEdit, state, true, newVal),
    };

    context.patchState(newState);
  }

  @Action(EditUndoLastAction)
  async undoLastAction(context: StateContext<PlanogramStateModel>) {
    let state = context.getState();

    //sube el index
    let newIndex = state.planogramToEditUndosIndex + 1;

    //Si está en el último, agrega este al estado y sube el index uno más
    if (state.planogramToEditUndosIndex === 0) {
      context.patchState({
        ..._addUndo(state),
      });
      await _timeout(32);
      newIndex++;
    }

    state = context.getState();
    const undos = state.planogramToEditUndos;
    const undosSize = undos.length;
    const invertIndex = undosSize - newIndex;
    const undoItem = undos[invertIndex];

    if (!undoItem) {
      _warn('[undoLastAction] no undoItem', { newIndex, undosSize, invertIndex, undoItem });
      return;
    }

    let newundoItem = _auxRecalcModulesAndChilds(undoItem, state, true);
    if (true && _isDev()) _log('[undoLastAction]', { newIndex, undosSize, invertIndex, newundoItem });

    context.patchState({
      planogramToEdit: {
        ...newundoItem,
        /*No cambia los siguientes valores*/
        _viewType: state.planogramToEdit._viewType,
        planogramName: state.planogramToEdit.planogramName,
        categoriesSelected: state.planogramToEdit.categoriesSelected,
        storesSelected: state.planogramToEdit.storesSelected,
        description: state.planogramToEdit.description,
        constraints: state.planogramToEdit.constraints,
        planogramType: state.planogramToEdit.planogramType,
        promotionMaterial: state.planogramToEdit.promotionMaterial,

        imageUrl: state.planogramToEdit.imageUrl,
        width: state.planogramToEdit.width,
        height: state.planogramToEdit.height,
        depth: state.planogramToEdit.depth,
      },
      planogramToEditUndosIndex: newIndex,
      planogramTimeLineIndex: null,
    });
  }

  @Action(EditRedoLastAction)
  async redoLastAction(context: StateContext<PlanogramStateModel>) {
    let state = context.getState();

    //sube el index
    let newIndex = state.planogramToEditUndosIndex - 1;

    if (newIndex <= 0) {
      _warn('[redoLastAction] no redoItem', newIndex);
      context.patchState({
        planogramToEditUndosIndex: 0,
      });
      return;
    }

    const undos = state.planogramToEditUndos;
    const undosSize = undos.length;
    const invertIndex = undosSize - newIndex;
    const undoItem = undos[invertIndex];

    if (!undoItem) {
      _warn('[redoLastAction] no redoItem', { newIndex, undosSize, invertIndex, undoItem });
      context.patchState({
        planogramToEditUndosIndex: 0,
      });
      return;
    }

    //Pasa el planograma por el auxiliar para recalcular las posiciones según la vista actual
    let newundoItem = _auxRecalcModulesAndChilds(undoItem, state, true);

    if (true && _isDev()) _log('[redoLastAction]', { newIndex, undosSize, invertIndex, newundoItem });

    context.patchState({
      planogramToEdit: {
        ...newundoItem,
        /*No cambia los siguientes valores*/
        _viewType: state.planogramToEdit._viewType,
        planogramName: state.planogramToEdit.planogramName,
        categoriesSelected: state.planogramToEdit.categoriesSelected,
        storesSelected: state.planogramToEdit.storesSelected,
        description: state.planogramToEdit.description,
        constraints: state.planogramToEdit.constraints,
        planogramType: state.planogramToEdit.planogramType,
        promotionMaterial: state.planogramToEdit.promotionMaterial,

        imageUrl: state.planogramToEdit.imageUrl,
        width: state.planogramToEdit.width,
        height: state.planogramToEdit.height,
        depth: state.planogramToEdit.depth,
      },
      planogramToEditUndosIndex: newIndex,
      planogramTimeLineIndex: null,
    });
  }

  @Action(LoadPlanogramToEdit)
  async loadPlanogramToEdit(context: StateContext<PlanogramStateModel>, payload: LoadPlanogramToEdit) {
    context.patchState({
      planogramToEditInLoad: true,
    });

    this.service
      .getPlanogram(payload.id)
      .pipe(first())
      .subscribe(planogram => {
        if (!(planogram && planogram.id !== undefined && planogram.modules)) {
          if (payload.id !== 0 && payload.id !== -1) {
            this.errorMessageService.show({ forceShow: true, forceMsg___: 'test' });
            console.warn('[getPlanogram] error', planogram);
          }
          return;
        }

        planogram = _auxRecalcModulesAndChilds(planogram, context.getState(), true);
        if (true && _isDev()) _log('[loadPlanogramToEdit]', payload.id, planogram);

        context.patchState({
          planogramToEdit: _clonePlanogram(planogram),
          planogramToEditOriginal: _clonePlanogram(planogram),
          allUniqueStores: _getAllUniqueStoresFromPlanogram(planogram),
          planogramToEditUndos: [],
          planogramToEditInLoad: false,
          planogramTimeLineIndex: null,
          planogramTimeLine: null,
        });
      });
  }

  @Action(NewPlanogramToEdit)
  async newPlanogramToEdit(context: StateContext<PlanogramStateModel>, payload: NewPlanogramToEdit) {
    context.patchState({
      planogramToEditInLoad: true,
    });

    let planogramToEdit = getDefaultPlanogram();
    delete planogramToEdit._viewType;
    planogramToEdit.id = 0;
    planogramToEdit.modules = [];
    planogramToEdit.planogramName = '';
    planogramToEdit.description = '';
    planogramToEdit.categoriesSelected = [];
    planogramToEdit.storesSelected = [];
    planogramToEdit.publishedDate = null;
    planogramToEdit.planogramType = 'Planogram';
    planogramToEdit.promotionMaterial = [];

    planogramToEdit.imageUrl = null;
    planogramToEdit.width = null;
    planogramToEdit.height = null;
    planogramToEdit.depth = null;

    await _timeout(32);

    context.patchState({
      planogramToEdit: _clonePlanogram(planogramToEdit),
      planogramToEditOriginal: _clonePlanogram(planogramToEdit),
      allUniqueStores: [],
      planogramToEditUndos: [],
      planogramAudit: null,
      planogramComments: null,
      planogramTimeLineIndex: null,
      planogramTimeLine: null,
      planogramToEditInLoad: false,
      planogramToEditInSave: false,
    });
  }

  @Action(ChangePlanogramObj)
  changeScopePlanogram(context: StateContext<PlanogramStateModel>, action: ChangePlanogramObj) {
    const { key, value } = action.payload;
    _patchNgXsSatateWithSet(context, 'planogramToEdit.' + key, value);
  }

  @Action(SaveEditedPlanogram)
  async savePlanogramToEdit(context: StateContext<PlanogramStateModel>, payload: SaveEditedPlanogram) {
    return new Promise((resolve, reject) => {
      let state = context.getState();
      let planoGram2save = state.planogramToEdit;
      let isInSave = state.planogramToEditInSave;
      let calcData = _addShelfsPrecalcDataNoModules(_clonePlanogram(planoGram2save), false);

      _log('[SaveEditedPlanogram]');

      if (!planoGram2save || isInSave) return;

      context.patchState({
        planogramTimeLineIndex: null,
        planogramToEditInSave: true,
        planogramToEditInLoad: true,
      });

      if (planoGram2save?.savedAreas) {
        let savedAreas = _reset_getNewNumberId(planoGram2save.savedAreas, ['id']) as PartitionArea[];
        planoGram2save = { ...planoGram2save, savedAreas: savedAreas };
      }

      //Patchea con el objeto que devuelve el servicio
      this.service
        .savePlanogram(_cloneDeep(planoGram2save), calcData)
        .pipe(first())
        .subscribe(savedPlanogram => {
          if (savedPlanogram && savedPlanogram.id !== undefined && savedPlanogram.modules) {
            savedPlanogram = _auxRecalcModulesAndChilds(savedPlanogram, state, true);
            context.patchState({
              planogramToEditInLoad: false,
              planogramToEditInSave: false,
              planogramToEditUndos: [],
              planogramToEditUndosIndex: 0,
              planogramToEdit: _clonePlanogram(savedPlanogram),
              planogramToEditOriginal: _clonePlanogram(savedPlanogram),
              allUniqueStores: _getAllUniqueStoresFromPlanogram(savedPlanogram),
              planogramTimeLine: null,
            });
            resolve(true);
          } else {
            this.errorMessageService.show({ forceShow: true, forceMsg___: 'test' });
            console.warn('[savePlanogram error]', savedPlanogram);
            reject();
          }
        });
    });
  }

  @Action(LoadPlanogramTimeLine)
  async loadPlanogramTimeLine(context: StateContext<PlanogramStateModel>, payload: LoadPlanogramTimeLine) {
    const state = context.getState();
    const _id = state.planogramToEdit.id;

    if (!state.planogramToEdit) return;
    if (state.planogramTimeLine && state.planogramTimeLine.length && state.planogramTimeLine[0].id === _id) return;

    if (state.planogramTimeLine || state.planogramTimeLineIndex) {
      context.patchState({
        planogramTimeLine: null,
        planogramTimeLineIndex: null,
      });
      await _timeout(32);
    }

    this.service
      .getPlanogramTimeLine(_id, 10)
      .pipe(first())
      .subscribe(timeLine => {
        if (true && _isDev()) _log('[LoadPlanogramTimeLine]', _id, timeLine.length);
        context.patchState({
          planogramTimeLine: (timeLine || []).map(pog => _auxRecalcModulesAndChilds(pog, state, true)),
          planogramTimeLineIndex: null,
        });
      });
  }

  @Action(LoadPlanogramAudit)
  async loadPlanogramAudit(context: StateContext<PlanogramStateModel>, payload: LoadPlanogramAudit) {
    const state = context.getState();
    const _id = state.planogramToEdit.id;

    if (!state.planogramToEdit) return;

    this.service
      .getPlanogramAudit(_id)
      .pipe(first())
      .subscribe($audit => {
        if (true && _isDev()) _log('[LoadPlanogramAudit]', _id, $audit);
        context.patchState({
          planogramAudit: $audit,
        });
      });
  }

  @Action(LoadComments)
  async getPlanogramComments(context: StateContext<PlanogramStateModel>, payload: LoadComments) {
    const state = context.getState();
    const _id = state.planogramToEdit.id;

    if (!state.planogramToEdit) return;

    this.service
      .getPlanogramComments(_id)
      .pipe(first())
      .subscribe($comments => {
        if (true && _isDev()) _log('[LoadComments]', _id, $comments);
        context.patchState({
          planogramComments: $comments,
        });
      });
  }

  @Action(EditProdRemove)
  editProdRemove(context: StateContext<PlanogramStateModel>, payload: EditProdRemove): void {
    const state = context.getState();

    if (state?.planogramToEdit?.modules == null) return;

    const editModulesProds = state.planogramToEdit.modules.map(mod => {
      const newShelfs = mod.shelfs.map(shelf => {
        const newProds = shelf.prods.filter(prod => {
          return String(prod.id) !== String(payload.prodId);
        });
        return { ...shelf, prods: newProds };
      });
      return { ...mod, shelfs: newShelfs };
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editModulesProds }, state, false),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditProdRemoveMultipleByEan)
  editProdRemoveMultipleByEan(context: StateContext<PlanogramStateModel>, payload: EditProdRemoveMultipleByEan): void {
    const state = context.getState();

    if (state?.planogramToEdit?.modules == null) return;

    const editModulesProds = state.planogramToEdit.modules.map(mod => {
      const newShelfs = mod.shelfs.map(shelf => {
        const newProds = shelf.prods.filter(prod => {
          return String(prod?.itemData?.ean) !== String(payload.prodEan) || prod?.itemData?.ean == null;
        });
        return { ...shelf, prods: newProds };
      });
      return { ...mod, shelfs: newShelfs };
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editModulesProds }, state, false),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(AddProdToShelf)
  addProdToShelf(context: StateContext<PlanogramStateModel>, payload: AddProdToShelf) {
    const state = context.getState();
    let removeId = payload.removeId || null;

    if (state?.planogramToEdit?.modules == null) return;

    const editModulesProds = state.planogramToEdit.modules.map(mod => {
      const newShelfs = mod.shelfs.map(shelf => {
        if (shelf.id !== payload.shelfId && !removeId) return shelf;

        let _prods = !removeId ? shelf.prods : shelf.prods.filter(obj => obj.id !== removeId);

        if (shelf.id === payload.shelfId) _prods = [..._prods, payload.prod];

        // Checkeo de colisiones para gancheras //DES-2079
        if (DEFAULT_AVOID_PEGSHELF_OVERLAY && shelf.type === 'simplePeg-level' && shelf.id === payload.shelfId) {
          if (false) _log(['_auxAvoidCollisions'], { shelf, prod: payload.prod });
          _prods = _auxAvoidCollisions_simplePeg(_prods, payload.prod.id, shelf);
        }

        return { ...shelf, prods: _prods };
      });
      return { ...mod, shelfs: newShelfs };
    });

    const newPlanogram = _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editModulesProds }, state, false);

    let newState = {
      planogramToEdit: newPlanogram,
      allUniqueStores: _getAllUniqueStoresFromPlanogram(newPlanogram),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditProdProp)
  editProdProp(context: StateContext<PlanogramStateModel>, payload: EditProdProp): void {
    const state = context.getState();

    const { prodId, prop, value } = payload;
    let _prdHasChange = false;

    if (state?.planogramToEdit?.modules == null) return;

    const editModulesProds = state.planogramToEdit.modules.map(mod => {
      const newShelfs = mod.shelfs.map(shelf => {
        const newProds = shelf.prods.map(prod => {
          if (prod.id !== prodId) return prod;
          _prdHasChange = true;
          const newprod = _cloneDeep(prod);

          if (typeof prop === 'string') {
            newprod[prop] = value;
          } else if (Array.isArray(prop)) {
            prop.forEach((_p, i) => {
              newprod[prop[i] as string] = value[i];
            });
          }

          if (_equal(newprod, prod)) _prdHasChange = false;

          return newprod;
        });
        return { ...shelf, prods: newProds };
      });
      return { ...mod, shelfs: newShelfs };
    });

    if (!_prdHasChange) return;

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editModulesProds }, state, false),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditShelfProp)
  editShelfProp(context: StateContext<PlanogramStateModel>, payload: EditShelfProp): void {
    const state = context.getState();

    const { shelfId, prop, value } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const editedModules = state.planogramToEdit.modules.map(mod => {
      const newShelfs = mod.shelfs.map(shelf => {
        if (shelf.id === shelfId) {
          let newShelf = _clonePlanogram(shelf);

          if (typeof prop === 'string') {
            newShelf[prop] = value;
          } else if (Array.isArray(prop)) {
            prop.forEach((_p, i) => {
              newShelf[prop[i] as string] = value[i];
            });
          }

          return newShelf;
        }
        return shelf;
      });
      return { ...mod, shelfs: newShelfs };
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditShelfHeight)
  editShelfHeight(context: StateContext<PlanogramStateModel>, payload: EditShelfHeight): void {
    const state = context.getState();

    const { shelfId, newHeight } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const editedModules = state.planogramToEdit.modules.map(mod => {
      const newShelfs = mod.shelfs.map(shelf => {
        if (shelf.id === shelfId) {
          let newShelf = _clonePlanogram(shelf);
          const deltaY = newHeight - shelf.height;
          newShelf.height = newHeight;
          const newProds = newShelf.prods.map(prod => {
            /*
              Reajusta los prods de abajo hacia arriba (pos.y)
              TODO: Ver si en algún caso (tipo de estante) hace falta
            */
            const reCalcYWithDelta = false;

            if (reCalcYWithDelta) prod.position.y += deltaY;
            return prod;
          });
          return { ...newShelf, prods: newProds };
        }
        return shelf;
      });
      return { ...mod, shelfs: newShelfs };
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditShelfRemove)
  editShelfRemove(context: StateContext<PlanogramStateModel>, payload: EditShelfRemove): void {
    const state = context.getState();

    const { shelfId } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const editedModules = state.planogramToEdit.modules
      .map(mod => {
        const newShelfs = mod.shelfs.filter(shelf => {
          return shelf.id !== shelfId;
        });
        return { ...mod, shelfs: newShelfs };
      })
      .filter(mod => {
        return mod.shelfs && mod.shelfs.length;
      });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditReorderModule)
  async reorderModule(context: StateContext<PlanogramStateModel>, payload: EditReorderModule) {
    const state = context.getState();
    const { moduleId, down } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    let tmpModules = _auxSetModuleOrderInfo(state.planogramToEdit.modules);

    if (moduleId !== -1 && tmpModules.length) {
      let module = tmpModules.find(m => String(m.id) === String(moduleId));
      if (module) {
        down ? (module.order += 1.5) : (module.order -= 1.5);
        tmpModules = _orderBy(tmpModules, ['order']);
      }
    }

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: tmpModules }, state, true),
      ..._addUndo(state),
    };

    if (true && _isDev()) _log('[reOrderModules]', payload);

    context.patchState(newState);
  }

  @Action(AddModule)
  addModule(context: StateContext<PlanogramStateModel>, payload: AddModule) {
    const state = context.getState();
    let newModule = payload.module;

    if (true && _isDev()) _log('[add new module]', newModule);

    if (state?.planogramToEdit?.modules == null) return;

    let tmpModules = _orderBy([...(state.planogramToEdit.modules || []), newModule], ['order']);

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: tmpModules }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditModuleRemove)
  async removeModule(context: StateContext<PlanogramStateModel>, payload: EditModuleRemove) {
    const state = context.getState();
    const { moduleId } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const editedModules = state.planogramToEdit.modules.filter(mod => {
      return mod.id !== moduleId;
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    if (true && _isDev()) _log('[reOrderModules]', payload);

    context.patchState(newState);
  }

  @Action(EditModuleProp)
  async editModuleProp(context: StateContext<PlanogramStateModel>, payload: EditModuleProp) {
    const state = context.getState();
    const { moduleId, prop, value } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const editedModules = state.planogramToEdit.modules.map(mod => {
      if (mod.id === moduleId) {
        let newMod = _clonePlanogram(mod);

        if (typeof prop === 'string') {
          _set(newMod, prop, value);
        } else if (Array.isArray(prop)) {
          prop.forEach((_p, i) => {
            _set(newMod, prop[i], value[i]);
          });
        }

        return newMod;
      }
      return mod;
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    if (true && _isDev()) _log('[editModuleProp]', payload);

    context.patchState(newState);
  }

  @Action(EditModuleRemoveAllProds)
  async moduleRemoveAllProds(context: StateContext<PlanogramStateModel>, payload: EditModuleRemoveAllProds) {
    const state = context.getState();
    const { moduleId } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const editedModules = state.planogramToEdit.modules.map(mod => {
      if (mod.id === moduleId || moduleId === 'ALL_MODULES') {
        let newMod = _clonePlanogram(mod);

        newMod.shelfs = newMod.shelfs.map(shelf => {
          return { ...shelf, prods: [] };
        });

        return newMod;
      }
      return mod;
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules, floats: [] }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditRemoveAllProds)
  async removeAllProds(context: StateContext<PlanogramStateModel>, payload: EditRemoveAllProds) {
    return this.store.dispatch(new EditModuleRemoveAllProds('ALL_MODULES'));
  }

  @Action(EditModuleRemoveAllNullProds)
  async moduleRemoveAllNullProds(context: StateContext<PlanogramStateModel>, payload: EditModuleRemoveAllNullProds) {
    const state = context.getState();
    const { moduleId } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const editedModules = state.planogramToEdit.modules.map(mod => {
      if (mod.id === moduleId || moduleId === 'ALL_MODULES') {
        let newMod = _clonePlanogram(mod);

        newMod.shelfs = newMod.shelfs.map(shelf => {
          const updatedProds = shelf.prods.filter(prod => {
            // Verificar si itemData está definido y si isEmptySpace es true
            return !(prod.itemData && prod.itemData.isEmptySpace === true);
          });

          return { ...shelf, prods: updatedProds };
        });

        return newMod;
      }
      return mod;
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules, floats: [] }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(EditRemoveAllNullProds)
  async removeAllNullProds(context: StateContext<PlanogramStateModel>, payload: EditRemoveAllNullProds) {
    return this.store.dispatch(new EditModuleRemoveAllNullProds('ALL_MODULES'));
  }

  @Action(EditReorderShelf)
  async reorderShelf(context: StateContext<PlanogramStateModel>, payload: EditReorderShelf) {
    const state = context.getState();
    const { shelfId, down } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    let tmpModules = _clonePlanogram(state.planogramToEdit.modules);

    if (shelfId !== -1 && tmpModules.length) {
      tmpModules = tmpModules.map(mod => {
        let shelf = mod.shelfs.find(s => String(s.id) === String(shelfId));
        if (!shelf) return mod;

        let tmpShelfs = mod.shelfs;
        down ? (shelf.order += 1.5) : (shelf.order -= 1.5);
        tmpShelfs = _orderBy(tmpShelfs, ['order']);
        return { ...mod, shelfs: tmpShelfs };
      });
    }

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: tmpModules }, state, true),
      ..._addUndo(state),
    };

    if (true && _isDev()) _log('[reOrderShelfs]', payload);

    context.patchState(newState);
  }

  @Action(EditShelfAdd)
  editShelfAdd(context: StateContext<PlanogramStateModel>, payload: EditShelfAdd): void {
    const state = context.getState();

    const { moduleId, shelf } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    let editedModules = state.planogramToEdit.modules.map(mod => {
      if (mod.id !== moduleId) return mod;

      let tmpShelfs = [...mod.shelfs, shelf];
      tmpShelfs = _orderBy(tmpShelfs, ['order']);
      return { ...mod, shelfs: tmpShelfs };
    });

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(UpdateGeneralData)
  updateGeneralData(context: StateContext<PlanogramStateModel>, payload: UpdateGeneralData): void {
    const state = context.getState();
    const _data = payload.data;

    let newData = _cloneDeep({
      id: state.planogramToEdit.id, // <-- el id no lo cambia

      planogramName: _data.name || state.planogramToEdit.planogramName,
      code: _data.code || state.planogramToEdit.code,
      categoriesSelected: _data.categories || state.planogramToEdit.categoriesSelected,
      storesSelected: _data.stores || state.planogramToEdit.storesSelected,
      description: _data.description || state.planogramToEdit.description,
      planogramType: _data.planogramType || state.planogramToEdit.planogramType,

      imageUrl: _data.imageUrl ?? state.planogramToEdit.imageUrl,
      width: _data.width || state.planogramToEdit.width,
      height: _data.height || state.planogramToEdit.height,
      depth: _data.depth || state.planogramToEdit.depth,
    });

    let currentData = {
      id: state.planogramToEdit.id,
      planogramName: state.planogramToEdit.planogramName,
      code: state.planogramToEdit.code,
      categoriesSelected: state.planogramToEdit.categoriesSelected,
      storesSelected: state.planogramToEdit.storesSelected,
      description: state.planogramToEdit.description,
      planogramType: state.planogramToEdit.planogramType,

      imageUrl: state.planogramToEdit.imageUrl,
      width: state.planogramToEdit.width,
      height: state.planogramToEdit.height,
      depth: state.planogramToEdit.depth,
    };

    //Si ya tiene un ID no deja cambiarlo
    if (state.planogramToEdit.id !== 0 && state.planogramToEdit.id !== undefined) newData.id = state.planogramToEdit.id;

    if (_equal(newData, currentData)) return;

    let newState = {
      planogramToEdit: { ...state.planogramToEdit, ...newData },
    };

    context.patchState(newState);
  }

  @Action(PlanogramsFormGetABMAttributeAction)
  getABMAttribute(
    context: StateContext<PlanogramStateModel>,
    action: PlanogramsFormGetABMAttributeAction
  ): Observable<Array<AttributeItemModel>> {
    const moduleStore = 2; // storeModle
    return this.tagService.getDinamicttribute(moduleStore).pipe(
      tap(data => this.store.dispatch(new PlanogramsFormGetABMAttributeSuccessAction(data))),
      catchError(errors => {
        this.errorMessageService.show({ forceShow: false, forceMsg___: 'no tags' });
        console.warn('[getTags error]', errors);
        return [];
      })
    );
  }

  @Action(PlanogramsFormGetABMAttributeSuccessAction)
  getABMAttributeSuccessAction(context: StateContext<PlanogramStateModel>, action: PlanogramsFormGetABMAttributeSuccessAction): void {
    const { allTags, ...rest } = context.getState().generalList;
    context.patchState({
      generalList: {
        ...rest,
        allTags: action.payload,
      },
    });
  }

  @Action(PlanogramsFormSetDinamicTagsSelectedGeneralAction)
  setSelectedTagsAction(context: StateContext<PlanogramStateModel>, action: PlanogramsFormSetDinamicTagsSelectedGeneralAction): void {
    const { selectedTags, ...rest } = context.getState().generalList;
    context.patchState({
      generalList: {
        selectedTags: action.payload,
        ...rest,
      },
    });
  }

  @Action(EditFloatProp)
  editFloatProp(context: StateContext<PlanogramStateModel>, payload: EditFloatProp): void {
    const state = context.getState();
    const { floatId, prop, value } = payload;

    if (floatId === undefined || !prop) return;

    let floatIndex = state.planogramToEdit.floats.findIndex(f => String(f.id) === String(floatId));
    if (floatIndex === -1) {
      _warn('float not found', { floatId, floats: state.planogramToEdit.floats });
      return;
    }

    let key = `floats.${floatIndex}.${prop}`;
    const planogramToEdit = _immutable.set(state.planogramToEdit, key, value);

    const newState = {
      planogramToEdit: planogramToEdit,
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(RemoveFloat)
  removeFloat(context: StateContext<PlanogramStateModel>, payload: RemoveFloat): void {
    const state = context.getState();
    const { floatId } = payload;

    if (floatId === undefined) return;

    let floatIndex = state.planogramToEdit.floats.findIndex(f => String(f.id) === String(floatId));
    if (floatIndex === -1) {
      _warn('float not found', { floatId, floats: state.planogramToEdit.floats });
      return;
    }

    const planogramToEdit = _immutable.del(state.planogramToEdit, `floats.${floatIndex}`);

    const newState = {
      planogramToEdit: planogramToEdit,
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(AddFloat)
  addFloat(context: StateContext<PlanogramStateModel>, payload: AddFloat): void {
    const state = context.getState();
    const { float } = payload;
    if (!float) return;

    const planogramToEdit = _auxRecalcModulesAndChilds(
      { ...state.planogramToEdit, floats: [...(state.planogramToEdit.floats || []), float] },
      state,
      false
    );

    const newState = {
      planogramToEdit: planogramToEdit,
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(SetFilerProdsByStores)
  setFilerProdsByStores(context: StateContext<PlanogramStateModel>, action: SetFilerProdsByStores): void {
    const { filerProdsByStores } = action;
    const state = context.getState();
    if (_equal(state.filerProdsByStores, filerProdsByStores)) return;
    context.patchState({
      filerProdsByStores: filerProdsByStores,
      planogramTimeLineIndex: null /* Resetea el timeline para hacer los calculos */,
    });
  }

  @Action(ResetFilerProdsByStores)
  resetFilerProdsByStores(context: StateContext<PlanogramStateModel>, action: ResetFilerProdsByStores): void {
    const state = context.getState();

    if (state.filerProdsByStores && !state.filerProdsByStores.length) return;

    context.patchState({
      filerProdsByStores: [],
      planogramTimeLineIndex: null /* Resetea el timeline para hacer los calculos */,
    });
  }

  @Action(ResetAllFiltersAnalitica)
  async resetAllFiltersAnalitica(context: StateContext<PlanogramStateModel>, payload: ResetAllFiltersAnalitica) {
    this.store.dispatch([new ResetFilters(true), new ResetFilerProdsByStores(), new SetAttrGroup(null)]);
  }

  @Action(ResetTimeLineIndex)
  resetTimeLineIndex(context: StateContext<PlanogramStateModel>, action: ResetTimeLineIndex): void {
    const state = context.getState();

    if (state.planogramTimeLineIndex == null) return;

    context.patchState({
      planogramTimeLineIndex: null,
    });
  }

  @Action(ResetTimeLine)
  resetTimeLine(context: StateContext<PlanogramStateModel>, action: ResetTimeLine): void {
    context.patchState({
      planogramTimeLine: null,
      planogramTimeLineIndex: null,
    });
  }

  @Action(SetTimeLineIndex)
  setTimeLineIndex(context: StateContext<PlanogramStateModel>, action: SetTimeLineIndex): void {
    const state = context.getState();

    let index = action.index;
    if (index === -1) index = null;

    if (state.planogramTimeLineIndex === index) return;

    index = state.planogramTimeLine && state.planogramTimeLine[index] ? index : null;

    context.patchState({
      planogramTimeLineIndex: index,
    });
  }

  @Action(SetAttrGroup)
  setAttrGroup(context: StateContext<PlanogramStateModel>, action: SetAttrGroup): void {
    const { attrGroup } = action;
    const state = context.getState();
    if (_equal(state.attrGroup, attrGroup)) return;
    context.patchState({
      attrGroup: attrGroup,
    });
  }

  @Action(AddConstraints)
  addConstraints(context: StateContext<PlanogramStateModel>, action: AddConstraints): void {
    const constraint = _cloneDeep(action?.payload);
    if (!constraint || constraint.id == null) return;

    const state = context.getState();
    let constraintsToPush = _cloneDeep(state.planogramToEdit.constraints) || [];

    if (constraint.id === 0) {
      constraint.date = new Date();
      constraint.id = _getNewNumberId();
    }

    let indexFinded = constraintsToPush.findIndex(co => co.id === constraint.id);
    if (indexFinded !== -1) {
      constraintsToPush[indexFinded] = constraint;
    } else {
      constraintsToPush = [...constraintsToPush, constraint];
    }

    _log('[AddConstraints]', constraintsToPush);

    const newState = {
      planogramToEdit: { ...state.planogramToEdit, constraints: constraintsToPush },
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(RemoveConstraints)
  removeConstraints(context: StateContext<PlanogramStateModel>, action: RemoveConstraints): void {
    const constraintId = _cloneDeep(action?.id);

    const state = context.getState();
    let constraintsToPush = _cloneDeep(state.planogramToEdit.constraints) || [];

    constraintsToPush = constraintsToPush.filter(co => co.id !== constraintId);

    _log('[RemoveConstraints]', constraintsToPush, constraintId);

    const newState = {
      planogramToEdit: { ...state.planogramToEdit, constraints: constraintsToPush },
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(SetConstraintsView)
  setConstraintsView(context: StateContext<PlanogramStateModel>, action: SetConstraintsView): void {
    let newConstraintView = action.payload;

    const newState = {
      constraintView: newConstraintView,
    };

    context.patchState(newState);
  }

  @Action(PlanogramForExportAction)
  planogramForExport(context: StateContext<PlanogramStateModel>, action: PlanogramForExportAction): any {
    const state = context.getState();
    const planogramId = state.planogramToEdit.id;

    return this.service.planogramForExport(planogramId).pipe(
      tap(data => {
        const newAction = new PlanogramForExportSuccessAction(data);

        return this.store.dispatch(newAction);
      }),
      catchError(errors => {
        const newAction = new PlanogramSetErrorAction(errors);

        return this.store.dispatch(newAction);
      })
    );
  }

  @Action(PlanogramForExportSuccessAction)
  planogramForExportSucces(context: StateContext<PlanogramStateModel>, action: PlanogramForExportSuccessAction): any {
    const _items = action.payload;

    context.patchState({
      excelUrl: _items,
    });
  }

  @Action(CloneModuleAction)
  async cloneModuleAction(context: StateContext<PlanogramStateModel>, payload: CloneModuleAction) {
    const state = context.getState();
    const { moduleId, cloneProds } = payload;

    if (state?.planogramToEdit?.modules == null) return;

    const moduleToClone = _cloneDeep(
      state.planogramToEdit.modules.find(mod => {
        return mod.id === moduleId;
      })
    );

    if (!moduleToClone) return;

    moduleToClone.id = _getTmpModuleId();
    moduleToClone.order += 0.1;

    moduleToClone.shelfs = moduleToClone.shelfs.map(shelf => {
      if (!cloneProds) {
        shelf.prods = [];
      } else {
        shelf.prods = shelf.prods.map(prod => {
          return { ...prod, id: _getTmpProdId() };
        });
      }
      shelf.id = _getTmpShelfId();
      return shelf;
    });

    let editedModules = [...state.planogramToEdit.modules, moduleToClone];
    editedModules = _orderBy(editedModules, ['order']);

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    if (true && _isDev()) _log('[cloneModuleAction]', payload);

    context.patchState(newState);
  }

  @Action(MirrorProdsInShelfs)
  async mirrorProdsShelfs(context: StateContext<PlanogramStateModel>, payload: MirrorProdsInShelfs) {
    const state = context.getState();

    const editedPlanogram = { ...state?.planogramToEdit };

    if (editedPlanogram?.modules == null) return;

    const { shelfIds } = payload;
    const shelfIdsMap = shelfIds?.length > 0 ? shelfIds.map(shelfId => String(shelfId)) : null;
    const allPlanogram = shelfIdsMap === null;

    let editedModules = (editedPlanogram?.modules || []).map((mod, indexMod) => {
      const editedShelfs = (mod?.shelfs || []).map(shelf => {
        if (allPlanogram || (shelfIdsMap || []).includes(String(shelf?.id))) {
          let editedShelf = _clonePlanogram(shelf);

          let mirrorProds = (editedShelf?.prods || []).map((mirrorProd, i) => {
            //MIRROR NORMALSHELF
            if (mirrorProd != null && shelf?.type === 'normalShelf-level') {
              const order = i * -1;
              return { ...mirrorProd, order };
            }

            //MIRROR SIMPLEPEG / PITSHELF
            if (
              mirrorProd?.position?.x != null &&
              mirrorProd?.size != null &&
              shelf?.width != null &&
              (shelf?.type === 'simplePeg-level' || shelf?.type === 'pitShelf-level')
            ) {
              let newX =
                (shelf.width || 0) - (shelf.marginRight || 0) - (mirrorProd.position.x + mirrorProd.size.w) + (shelf.marginLeft || 0);

              return { ...mirrorProd, position: { ...mirrorProd.position, x: newX || 0 } };
            }

            return mirrorProd;
          });

          mirrorProds = _orderBy(mirrorProds, ['order']);
          return { ...editedShelf, prods: mirrorProds };
        }

        return shelf;
      });

      if (allPlanogram) {
        const orderMod = indexMod * -1;
        return { ...mod, shelfs: editedShelfs, order: orderMod };
      }

      return { ...mod, shelfs: editedShelfs };
    });

    //MIRROR MODULES
    if (allPlanogram) {
      //ODER MODS
      editedModules = _orderBy(editedModules, ['order']);
    }

    // MIRROR MATERIAL POP
    const floats = allPlanogram
      ? (editedPlanogram?.floats || []).map(float => {
          let widthOffset = (float?.prods?.[0]?.itemData?.size?.w || 0) + 0;

          const float_position_x = (editedPlanogram?.stageSize?.w || 0) - (float?.position.x || 0) - widthOffset;

          return { ...float, position: { ...float?.position, x: float_position_x } };
        })
      : editedPlanogram?.floats || [];

    //NEW STATE
    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...editedPlanogram, modules: editedModules, floats }, state, true),
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  // DES-3954
  @Action(AutoCompleteShelf)
  async autoCompleteShelf(context: StateContext<PlanogramStateModel>, action: AutoCompleteShelf): Promise<any> {
    const state = context.getState();

    const { shelfId, remainingWidthSpace } = action;

    const prodList = state.prodList;
    const actualList = prodList?.prodListdata || [];
    const planogramToEditProds = _getPlanogramToEditProdsItemData(state);
    const planogramToEditProdsCodes = planogramToEditProds.map(p => p?._internalCodeUom).filter(p => p != null);

    const __addProdList = shelf => {
      let shelfWidth = shelf.width - ((shelf.marginRight || 0) + (shelf.marginLeft || 0));
      let shelfHeight = shelf.height;
      let shelfDepth = shelf._depth || Infinity;
      let minPrdSepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;
      let remainingShelfWidth = remainingWidthSpace || shelfWidth;

      let newProds = [];

      (actualList.map(_itemDataToShelfProd) || []).forEach((prod, i) => {
        let sameCode = planogramToEditProdsCodes.includes(prod?.itemData?._internalCodeUom);
        let entersWidth = prod.size.w + minPrdSepX <= remainingShelfWidth;
        let entersHeight = prod.size.h <= shelfHeight;
        let entersDepth = prod.size.f || 0 <= shelfDepth;

        if (true && !sameCode && entersWidth && entersHeight && entersDepth) {
          newProds.push(prod);
          remainingShelfWidth -= prod.size.w + minPrdSepX;
        }
      });

      let rv = [...(remainingWidthSpace != null ? shelf.prods : []), ...newProds];

      return rv;
    };

    let __changed = false;
    const editedModules = state.planogramToEdit.modules.map(mod => {
      let newShelfs = mod.shelfs.map(shelf => {
        if (shelf.id !== shelfId) return shelf;

        let rvNewShelf = { ...shelf, prods: __addProdList(shelf) };

        __changed = (rvNewShelf.prods || [])?.length !== (shelf.prods || [])?.length;

        return rvNewShelf;
      });

      return { ...mod, shelfs: newShelfs };
    });

    if (!__changed) return;

    let newState = {
      planogramToEdit: _auxRecalcModulesAndChilds({ ...state.planogramToEdit, modules: editedModules }, state, true),
      ..._addUndo(state),
    };

    if (true && _isDev()) {
      _log('autoCompleteShelf', { shelfId, actualList, planogramToEditProds, editedModules, planogramToEditProdsCodes });
    }

    context.patchState(newState);
  }

  @Action(UpdateTotalSlots)
  async updateTotalSlots(context: StateContext<PlanogramStateModel>, payload: UpdateTotalSlots) {
    const { floatId, value } = payload;
    const state = context.getState();

    if (floatId === undefined) return;
    let floatIndex = state.planogramToEdit.floats.findIndex(f => String(f.idFloatRef) === String(floatId));
    if (floatIndex === -1) {
      _warn('float not found', { floatId, floats: state.planogramToEdit.floats });
      return;
    }

    const planogramToEdit = _immutable.del(state.planogramToEdit, `floats.${floatIndex}`);

    let floats = state.planogramToEdit.floats.map(item => {
      if (item.idFloatRef === floatId) {
        return {
          ...item,
          totalSlots: value,
        };
      }
      return item;
    });

    const newState = {
      planogramToEdit: { ...planogramToEdit, floats },
      ..._addUndo(state),
    };

    context.patchState(newState);
  }

  @Action(SaveTotalSlots)
  saveTotalSlots(context: StateContext<PlanogramStateModel>, payload: SaveTotalSlots) {
    const state = context.getState();
    const _floats = state.planogramToEdit.floats.map(item => ({
      id: Number(item.id.toLocaleString().replace('_FLOAT-', '')),
      totalSlot: item.totalSlots,
    }));
    this.service.saveTotalSlots(_floats).subscribe(res => {});
  }
  @Action(SaveEditedPlanogramAndTotalSlots)
  async saveEditedPlanogramAndTotalSlots(context: StateContext<PlanogramStateModel>, payload: SaveEditedPlanogramAndTotalSlots) {
    await this.store.dispatch(new SaveTotalSlots());
    this.store.dispatch(new SaveEditedPlanogram());
  }
}

const _traversePlanogramToEditProds = (state: PlanogramStateModel, callBack) => {
  const actualState = state;
  const planogramToEdit = actualState.planogramToEdit;

  planogramToEdit.modules.forEach(mod => {
    mod.shelfs.forEach(shelf => {
      shelf.prods.forEach(prod => {
        if (callBack) callBack(prod);
      });
    });
  });
};

const _traversePlanogramToEditShelfs = (state: PlanogramStateModel, callBack) => {
  const actualState = state;
  const planogramToEdit = actualState.planogramToEdit;

  planogramToEdit.modules.forEach(mod => {
    mod.shelfs.forEach(shelf => {
      if (callBack) callBack(shelf);
    });
  });
};

const _traversePlanogramToEditMods = (state: PlanogramStateModel, callBack) => {
  const actualState = state;
  const planogramToEdit = actualState.planogramToEdit;

  planogramToEdit.modules.forEach(mod => {
    if (callBack) callBack(mod);
  });
};

const _getPlanogramToEditProdsItemData = state => {
  let planogramToEditProds = [];

  _traversePlanogramToEditProds(state, prod => {
    planogramToEditProds.push(prod?.itemData);
  });

  planogramToEditProds = _uniqueElementsByKey(planogramToEditProds as [], '_internalCodeUom');

  return planogramToEditProds;
};

const _itemDataToShelfProd = (itemData, i = 0) => {
  const pdrodToAdd = {
    id: _getTmpProdId(),
    itemData: { ...itemData, _internalItemId: _set_internalItemId(itemData.itemId, itemData.unitOfMeasureId) },
    size: null,
    order: 10000000 + i,
  };

  pdrodToAdd.size = pdrodToAdd.size || _calcSizeProd(pdrodToAdd);

  return pdrodToAdd;
};

//Usar cada vez antes que se vaya a patchear un módulo o sus hijos
const _auxRecalcModulesAndChilds = (planogram, state, recalcModSize = false, forceView = null) => {
  const _logTime = _isDev() && false;
  if (_logTime) console.time();

  let _viewType = forceView || state.planogramToEdit._viewType;

  let tmpMods = _auxSetModuleOrderInfo(planogram.modules);

  let tmpFloats = _auxSetFloatInfo(planogram.floats);

  let stageSize = planogram.stageSize || state.planogramToEdit.stageSize;

  if (recalcModSize) {
    if (!true && _isDev()) _log('[recalcModSize] view', _viewType);

    let recalc = _calcPlanogramPosAndSize(tmpMods, _viewType);

    if (recalc && recalc.modules) {
      tmpMods = recalc.modules;
      stageSize = { w: recalc.totalWidth, h: recalc.totalHeight };
    }
  }

  if (_logTime) {
    console.timeEnd();
  }

  return { ...planogram, modules: tmpMods, floats: tmpFloats, stageSize, _viewType };
};

const _addUndo = actualState => {
  let actualUndos = actualState.planogramToEditUndos || [];
  actualUndos = actualUndos.slice((DEFAULT_MAX_UNDOS - 1) * -1);

  //Si está en un undo, descarta los undos siguientes
  if (actualState.planogramToEditUndosIndex !== 0)
    actualUndos = actualUndos.slice(0, actualUndos.length - actualState.planogramToEditUndosIndex);

  let planogramToEditUndos = actualUndos;
  let currentPlanogram = _clonePlanogram(actualState.planogramToEdit);
  let lastUndoPlanogram = actualUndos[actualUndos.length - 1];

  //Si el actual es distinto al último lo agrega (solo para valores pertinentes, nullea los q se ignoran)
  if (
    !_equal(
      {
        ...currentPlanogram,
        _viewType: null,
        constraints: null,
        planogramName: null,
        description: null,
        categoriesSelected: null,
        storesSelected: null,
        planogramType: null,
        promotionMaterial: null,
        imageUrl: null,
        width: null,
        height: null,
        depth: null,
      },
      {
        ...lastUndoPlanogram,
        _viewType: null,
        constraints: null,
        planogramName: null,
        description: null,
        categoriesSelected: null,
        planogramType: null,
        promotionMaterial: null,
        storesSelected: null,
        imageUrl: null,
        width: null,
        height: null,
        depth: null,
      }
    )
  ) {
    planogramToEditUndos.push(currentPlanogram);
  }

  let planogramToEditUndosIndex = 0;

  return { planogramToEditUndos, planogramToEditUndosIndex, planogramTimeLineIndex: null };
};
