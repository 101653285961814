import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { kayshFlushOperator, kayshOperator } from '@karacas/kaysh';
import { TranslateService } from '@ngx-translate/core';
import { __getItemsTypesOxxo } from '@prisma/components/generic-select-promotion-types/services/generic-select-promotion-types.service';
import { GenericTagsSelectV2Service } from '@prisma/components/generic-tags-select-v2/generic-tag-select-v2.service';
import { ResponseMessagesGenericPrintComponent } from '@prisma/components/responseMessagesGenericPrint/responseMessagesGenericPrint.component';
import {
  FormModel_scopeItems_FE,
  FormModel_scopeStores_FE,
  mapActionVGiftItemScopeFromBE,
  mapActionVGiftItemScopeToBE,
  mapItemScopeFromBE,
  mapItemScopeToBE,
  mapStoreScopeFromBE,
  mapStoreScopeToBE,
} from '@prisma/containers/model/scope.models';
import { _isDev, _isFeDev, _log, _logTap, _useDummyData, _warn } from '@shared/aux_helper_environment';
import {
  _cloneDeep,
  _createUnicId,
  _getFormatDate,
  _ms,
  _normalizeObjectToCompare,
  _objToHash,
  _pick,
  _roundDec,
  _throwError,
  _toNumber,
} from '@shared/aux_helper_functions';
import { GlobalAlertService, IanTranslateService } from 'core/services/ian-core-singleton.service';
import { environment } from 'environments/environment';
import json5 from 'json5';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  ActionScopeType,
  ActionsV2_DiscountType,
  ActionsV2_FormModel,
  ActionsV2_FormModel_activationData,
  ActionsV2_FormModel_bank,
  ActionsV2_FormModel_mainData,
  ActionsV2_FormModel_paymentMethod,
  ActionsV2_FormModel_paymentMethods,
  ActionsV2_FormModel_paymentMethodsCombinations,
  ActionsV2_StatusEnum,
  ActionsV2_Types,
  ActionsV2_ValidationStatus,
  ActionsV2__paymentSelectionType,
  ActionsV2_validationObject,
  FixedPriceDiscount,
  FixedPriceDiscountCombos,
  FormModel_promotionCombos_FE,
  SupportTextItem,
  TicketMessageValidationRulesDTO,
} from './actions/actions-edit/store/actionsV2-FormStateModel';
import { ActionsV2_list_service } from './actions/actions-list/ActionsV2_list.service';
import { CustomFieldAttributeTypeSelModel } from './actions/actions-list/stores/actionsV2_list.model';
import { __getActionPromoDefaultYear } from './periods/periods-list/generic_components/year_selector_promotion.component';
import {
  DUMMY_ACTIONV2_editItem,
  DUMMY_ActionsV2_Banks,
  DUMMY_ActionsV2_paymentMethodsList,
  DUMMY_ActionsV2_promotionValidationList,
  FIXED_PRICE_DISCOUNT_DUMMY_DATA,
  TICKET_MESSAGE_RULES_DUMMY_DATA,
  dummyDataPromotionOxxo,
} from './promotionsv2.dummyData';
import { DevSettingsService, FeatureFlagsKeysEnum } from 'core/services/dev-settings.service';
import { COMBOS_DISCOUNT_DUMMY_DATA, promotionCombosDummyData } from './actions/actions-edit/store/actionsV2-DummyData';
import { PromotionCombo, PromotionComboDTO } from './actions/actions-edit/combos-components-v2/models/combosV2.model';
import { dummyDataSpaces } from './availablePromotionalSpaces/store/DummyData';
import { AvailablePromotionalSpacesDto } from './availablePromotionalSpaces/store/available-promotional-spaces.model';
import { ReportType, outputFormat } from './reports.model';
import { CategoryTreeModelGenSelected } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.component';
import { SelectTreeModelGenSelected } from '@prisma/components/generic-select-tree/generic-select-tree.component';
import {
  PAYMENT_METHODS_DUMMY_DATA,
  PaymentMethodsSelectTree,
  SELECT_TREE_DUMMY_DATA_BANKS,
  SelectTreeModelGen,
} from '@prisma/components/generic-select-tree/generic-select-tree.model';

@Injectable({
  providedIn: 'root',
})
export class PromotionsV2Service {
  private configBasePrices = environment.apiBaseUrl_prices;
  private configBaseSalesAndStock = environment.apiBaseUrl_salesandstock;
  private configBase = environment.apiBaseUrl_promotionV2;
  private configBaseSpaces = environment.apiBaseUrl_spaces;
  private actionConfig = environment.promotionV2.action.api;
  private spacesConfig = environment.spaces.planograms.api;
  private configurationConfig = environment.promotionV2.configuration.api;
  private campaignConfig = environment.promotionV2.campaign.api;
  private periodConfig = environment.promotionV2.periods.api;
  private configBaseApi = environment.apiBaseUrl_identity;

  arrayOfData = [this.serviceT.geTagsTree(2), this.serviceT.geTagsTree(1)];
  itemTagList = null;
  storeTagList = null;

  constructor(
    private http: HttpClient,
    private serviceT: GenericTagsSelectV2Service,
    private globalAlertService: GlobalAlertService,
    public _translateService: IanTranslateService,
    private actionsV2_list_service: ActionsV2_list_service,
    private configFlags: DevSettingsService
  ) {
    /**/
  }

  generateProductListReport(filters): Observable<any> {
    const url = `${this.configBase}${this.actionConfig.resources.generateProductListReport}?promotionTypeId=${filters.promotionTypeId}&promotionPeriodId=${filters.periodId}&popMaterialVendor=${filters.vendorId}`;

    return this.http.get(url, { responseType: 'text' as 'json' }).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::generateProductListReport (tap)\n\tdata: %o`, _data)),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'generateProductListReport');
        }
        let rv = null;
        return of(rv).pipe(delay(500));
      })
    );
  }

  generateLogisiticsReport(filters): Observable<any> {
    const url = `${this.configBase}${this.actionConfig.resources.generateLogisticsReport}?promotionTypeId=${filters.promotionTypeId}&promotionPeriodId=${filters.periodId}&popMaterialVendor=${filters.vendorId}`;

    return this.http.get(url, { responseType: 'text' as 'json' }).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::generateLogisiticsReport (tap)\n\tdata: %o`, _data)),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'generateLogisiticsReport');
        }
        let rv = null;
        return of(rv).pipe(delay(500));
      })
    );
  }

  generatePromotionReport(filters): Observable<any> {
    let url = `${this.configBase}${this.actionConfig.resources.generatePromotionReport}`;
    url += `?promotionTypeId=${filters.promotionTypeId}&promotionPeriodId=${filters.periodId}`;

    return this.http.get(url, { responseType: 'text' as 'json' }).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::generatePromotionReport (tap)\n\tdata: %o`, _data)),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'generatePromotionReport');
        }
        let rv = null;
        return of(rv).pipe(delay(500));
      })
    );
  }

  getPromotionTypeList(): Observable<any> {
    const url = `${this.configBase}${this.periodConfig.resources.getPromotionTypeList}`;

    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getPromotionTypeList (tap)\n\tdata: %o`, _data)),
      map(data => {
        if (data == null) {
          return _throwError('NO DATA', PromotionsV2Service);
        }

        if ((data as any)?.length > 0) {
          if (_isDev()) {
            data = (data as any).map(el => {
              return { ...el, id: el.id || el.code };
            });
          } else {
            if (data[0].id == null) console.error('getPromotionTypeList data sin id');
          }
        }

        return data;
      }),
      kayshOperator('getPromotionTypeList', null, {
        localStorage: false,
        maxItems: 100,
        maxTime: _ms('60m'),
      }),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'promotionTypeList');
        }
        let rv = dummyDataPromotionOxxo.promotionTypeList();
        return of(rv).pipe(delay(500));
      })
    );
  }

  getStartingWeekDaysList(): Observable<any> {
    const url = `${this.configBase}${this.actionConfig.resources.getStartingWeekDaysList}`;

    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getStartingWeekDaysList (tap)\n\tdata: %o`, _data)),
      map(data => {
        if (data == null) {
          return _throwError('NO DATA', PromotionsV2Service);
        }
        return data;
      }),
      kayshOperator('getStartingWeekDaysList', null, {
        localStorage: false,
        maxItems: 100,
        maxTime: _ms('60m'),
      }),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'getStartingWeekDays');
        }
        let rv = dummyDataPromotionOxxo.getStartingWeekDays;
        return of(rv).pipe(delay(500));
      })
    );
  }

  getActionForm(id, forceRefresh = false): Observable<ActionsV2_FormModel> | any {
    const url = `${this.configBase}${this.actionConfig.resources.getActionById}/${id}`;

    if (forceRefresh) kayshFlushOperator('getActionForm');

    kayshFlushOperator('getPromotionValidationList');

    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getActionForm (tap)\n\tdata: %o`, _data)),
      map(data => _actionDataFromBe(data)),
      map(data => {
        if (data == null) {
          return _throwError('NO DATA', PromotionsV2Service);
        }
        return data;
      }),
      kayshOperator('getActionForm', id, {
        localStorage: false,
        maxItems: 100,
        maxTime: _ms('1m'),
      }),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'DUMMY_ACTIONV2_editItem');
        }
        let rv = _actionDataFromBe(DUMMY_ACTIONV2_editItem);
        rv.mainData.id = id;
        return of(rv).pipe(delay(500));
      })
    );
  }

  _cache_campaingCheckInternalCodeAlreadyExist = {};
  campaingCheckInternalCodeAlreadyExist($code: string, type?: any): Observable<boolean> {
    let code = String($code).trim();
    if (!code?.length) return of(false);

    let cache = this._cache_campaingCheckInternalCodeAlreadyExist[code];
    if (cache != null) return of(cache);

    const url = `${this.configBase}${this.campaignConfig.resources.codeExists}/${code}`;

    return of(true).pipe(
      delay(128 * 3),
      switchMap(data => this.http.get<boolean>(url)),
      tap(data => _logTap(`${PromotionsV2Service.name}::checkInternalCodeNotTaken (tap)\n\tdata: %o`, data)),
      tap(data => (this._cache_campaingCheckInternalCodeAlreadyExist[code] = data)),
      catchError(error => {
        if (true && _useDummyData()) {
          return of(false).pipe(
            delay(128 * 3),
            tap(data => (this._cache_campaingCheckInternalCodeAlreadyExist[code] = data))
          );
        }

        return _throwError(error, PromotionsV2Service);
      })
    );
  }
  reset_cache_campaingCheckInternalCodeAlreadyExist() {
    this._cache_campaingCheckInternalCodeAlreadyExist = {};
  }

  _cache_checkInternalCodeAlreadyExist = {};
  checkInternalCodeAlreadyExist($code: string, type?: any): Observable<boolean> {
    let code = String($code).trim();
    if (!code?.length) return of(false);

    let cache = this._cache_checkInternalCodeAlreadyExist[code + (type ?? '')];

    if (cache != null) return of(cache);
    let url: string;
    if (!(type?.length > 0)) {
      url = `${this.configBase}${this.actionConfig.resources.codeExists}/${code}`;
    }

    if (type === 'period') {
      url = `${this.configBase}${this.periodConfig.resources.codeExists}/${code}`;
    }

    return of(true).pipe(
      delay(128 * 3),
      switchMap(data => this.http.get<boolean>(url)),
      tap(data => _logTap(`${PromotionsV2Service.name}::checkInternalCodeNotTaken (tap)\n\tdata: %o`, data)),
      tap(data => (this._cache_checkInternalCodeAlreadyExist[code + (type ?? '')] = data)),
      catchError(error => {
        if (true && _useDummyData()) {
          return of(false).pipe(
            delay(128 * 3),
            tap(data => (this._cache_checkInternalCodeAlreadyExist[code + (type ?? '')] = data))
          );
        }

        return _throwError(error, PromotionsV2Service);
      })
    );
  }
  reset_cache_checkInternalCodeAlreadyExist() {
    this._cache_checkInternalCodeAlreadyExist = {};
  }

  _cache_checkExternalCodeAlreadyExist = {};
  checkExternalCodeAlreadyExist($code: string, type?: any): Observable<boolean> {
    let code = String($code).trim();
    if (!code?.length) return of(false);

    let cache = this._cache_checkExternalCodeAlreadyExist[code];
    if (cache != null) return of(cache);

    const url = `${this.configBase}${this.actionConfig.resources.codeExternalExists}/${code}`;

    return of(true).pipe(
      delay(128 * 3),
      switchMap(data => this.http.get<boolean>(url)),
      tap(data => _logTap(`${PromotionsV2Service.name}::checkExternalCodeAlreadyExist (tap)\n\tdata: %o`, data)),
      tap(data => (this._cache_checkExternalCodeAlreadyExist[code] = data)),
      catchError(error => {
        if (true && _useDummyData()) {
          return of(false).pipe(
            delay(128 * 3),
            tap(data => (this._cache_checkExternalCodeAlreadyExist[code] = data))
          );
        }

        return _throwError(error, PromotionsV2Service);
      })
    );
  }
  reset_cache_checkExternalCodeAlreadyExist() {
    this._cache_checkExternalCodeAlreadyExist = {};
  }

  saveActionForm(payLoad: ActionsV2_FormModel, isPublish = false) {
    let _payload: ActionsV2_FormModel = _cloneDeep(payLoad);

    const url = !isPublish
      ? `${this.configBase}${this.actionConfig.resources.saveAction}`
      : `${this.configBase}${this.actionConfig.resources.publishAction}`;

    const printName = !isPublish ? '[saveActionForm]' : '[publishActionForm]';

    const _payloadBE = _actionDataToBe(_payload);

    if (true) _log('[saveActionForm]', { _payload, _payloadBE });

    //DES-1651
    const _mergeMapinterCeptWarning = mergeMap(async (data: any) => {
      if (data == null || !(data._responseMessages?.length > 0) || data._responseSuccess === false) return data;

      const responseMessages = (data._responseMessages || []).filter(
        e => e?.keyword === 'InvalidStoreScope' || e?.keyword === 'InvalidManualStoreScopes'
      );
      if (!(responseMessages?.length > 0)) return data;

      _warn('\n\n[saveActionForm intercept] WARNING', { $data: data, responseMessages });

      //Alert with custom component
      const $component = ResponseMessagesGenericPrintComponent;
      const $dataToInject = { dataWarnings: data._responseMessages };
      const $title = this._translateService.instant('GENERIC_NOTICE');
      await this.globalAlertService.customComponentBody($component, $dataToInject, $title);

      return data;
    });
    this.resetCachegetFixedPriceDiscount();

    return this.http.post(url, _payloadBE).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::${printName} (tap)\n\tdata: %o`, _data)),
      map(_data => _actionDataFromBe(_data)),
      map(_data => {
        if (_data == null) return _throwError('NO DATA', PromotionsV2Service);
        return _data;
      }),
      _mergeMapinterCeptWarning,
      catchError(error => {
        if (true && _useDummyData()) {
          if (_payload.mainData.id == 0) _payload.mainData.id = 1;
          _payload.mainData.status = !isPublish ? ActionsV2_StatusEnum.Modified : ActionsV2_StatusEnum.Current;

          _log('[DUMMY DATA SAVE]', _actionDataToBe(_payload));

          return of(_payload).pipe(delay(500));
        }
        return _throwError(error, PromotionsV2Service);
      })
    );
  }

  coverageActionForm(payLoad: ActionsV2_FormModel, tagId: number, completeCoverage = false, pageData?: any) {
    let _payload: ActionsV2_FormModel = _cloneDeep(payLoad);

    const qs: Array<string> = ['&'];

    if (pageData?.pageIndex) {
      qs.push(`pageIndex=${pageData.pageIndex}`);
    }

    if (pageData?.pageSize) {
      qs.push(`pageSize=${pageData.pageSize}`);
    }

    const url = `${this.configBase}${this.actionConfig.resources.checkCoverage}?tagId=${tagId}&completeCoverage=${completeCoverage}${
      !(qs.length > 1) ? '' : qs.join('&')
    }`;

    const printName = '[coverageActionForm]';

    const _payloadBE = _actionDataToBe(_payload);

    if (true) _log(printName, { _payload, _payloadBE });

    return this.http.post(url, _payloadBE).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::${printName} (tap)\n\tdata: %o`, _data)),
      kayshOperator(
        'coverageActionForm',
        { ..._payloadBE, url, pageData },
        { localStorage: false, maxTime: _ms('1h'), useCacheReplay: false }
      ),
      catchError(error => {
        if (true && _useDummyData()) {
          return of(_mockCoverage).pipe(delay(16));
        }
        return _throwError(error, PromotionsV2Service);
      })
    );
  }

  publishActionForm(payLoad: any) {
    return this.saveActionForm(payLoad, true);
  }

  _cacheSalesChannelCodes = null;
  getSalesChannelCodes(): Observable<any> {
    let cache = this._cacheSalesChannelCodes;
    if (cache != null) return of(cache);

    const url = `${this.configBasePrices}${this.actionConfig.resources.salesChannelCodes}`;

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::geSalesChannelCodes (tap)\n\tdata: %o`, data)),
      tap(data => {
        if (data) this._cacheSalesChannelCodes = data;
      }),
      catchError(error => {
        if (true && _useDummyData()) {
          let rv = [
            {
              code: 'ECO',
              name: 'E-Commerce',
              icon: null,
            },
            {
              code: 'RAP',
              name: 'RAPPI',
              icon: null,
            },
          ];

          this._cacheSalesChannelCodes = rv;
          return of(rv);
        }

        return _throwError(error, PromotionsV2Service);
      })
    );
  }

  _cacheLoyaltyProgramCodes = null;
  getLoyaltyProgramCodes(): Observable<any> {
    let cache = this._cacheLoyaltyProgramCodes;
    if (cache != null) return of(cache);

    const url = `${this.configBaseSalesAndStock}${this.actionConfig.resources.loyaltyProgramCodes}`;

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getLoyaltyProgramCodes (tap)\n\tdata: %o`, data)),
      tap(data => {
        if (data) this._cacheLoyaltyProgramCodes = data;
      }),
      catchError(error => {
        if (true && _useDummyData()) {
          let rv = [
            {
              code: 'TSC',
              name: 'ServiClub',
            },
            {
              code: 'TSC2',
              name: 'ServiClub2',
            },
          ];

          this._cacheLoyaltyProgramCodes = rv;
          return of(rv).pipe(delay(500));
        }

        return _throwError(error, PromotionsV2Service);
      })
    );
  }

  _cachePaymentMethods = null;
  getPaymentMethods(): Observable<ActionsV2_FormModel_paymentMethod[]> {
    let cache = this._cachePaymentMethods;
    if (cache != null) return of(cache);

    const url = `${this.configBaseSalesAndStock}${this.actionConfig.resources.PaymentMethods}`;

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getPaymentMethods (tap)\n\tdata: %o`, data)),
      tap(data => {
        if (data) this._cachePaymentMethods = data;
      }),
      catchError(error => {
        if (true && _useDummyData()) {
          let rv = DUMMY_ActionsV2_paymentMethodsList;

          this._cachePaymentMethods = rv;
          return of(rv).pipe(delay(0));
        }

        return _throwError(error, PromotionsV2Service);
      })
    );
  }

  getPaymentMethodsV2(): Observable<PaymentMethodsSelectTree[]> {
    const USE_DUMMY_DATA = false;
    if (USE_DUMMY_DATA) {
      return of(_mapPaymentMethodsFromBe(PAYMENT_METHODS_DUMMY_DATA, this._translateService));
    }

    const _url = `${this.configBaseSalesAndStock}${this.actionConfig.resources.PaymentMethodsV2}`;

    return this.http.get<PaymentMethodsSelectTree[]>(_url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getPaymentMethodsV2 (tap)\n\tdata: %o`, data)),
      map(data => _mapPaymentMethodsFromBe(data, this._translateService)),
      kayshOperator('getPaymentMethodsV2', null, { localStorage: false, maxTime: _ms('30m') })
    );
  }

  _cacheBanks = {};
  getBanks(filters: any = { pageIndex: 0, pageSize: 1000 }): Observable<ActionsV2_FormModel_bank[]> {
    let filterHash = filters ? _objToHash(filters) : 'NULL';

    let cache = this._cacheBanks[filterHash];
    if (cache != null) return of(cache);

    const qs: Array<string> = ['?'];

    if (filters.pageIndex) {
      qs.push(`pageIndex=${filters.pageIndex}`);
    }

    if (filters.pageSize) {
      qs.push(`pageSize=${filters.pageSize}`);
    }

    const url = `${this.configBase}${this.actionConfig.resources.bankList}${qs.join('&')}`;

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getBanks (tap)\n\tdata: %o`, data)),
      map(data => data?.items),
      tap(data => {
        if (data) this._cacheBanks[filterHash] = data;
      }),
      catchError(error => {
        if (true && _useDummyData()) {
          let rv = DUMMY_ActionsV2_Banks;

          this._cacheBanks[filterHash] = rv;
          return of(rv).pipe(delay(0));
        }

        return _throwError(error, PromotionsV2Service);
      })
    );
  }

  getCampaignById(campaignId): any {
    return this.http
      .get(`${this.configBase}${this.campaignConfig.resources.getById}${campaignId}`)
      .pipe(tap(data => _logTap(`${PromotionsV2Service.name}::get (tap)\n\tgetList: %o`, data)));
  }

  getActionById(actionId): any {
    return this.http
      .get(`${this.configBase}${this.actionConfig.resources.getById}${actionId}`)
      .pipe(tap(data => _logTap(`${PromotionsV2Service.name}::getActionById (tap)\n\getActionById: %o`, data)));
  }

  saveCampaign(campaign) {
    return this.http.post(`${this.configBase}${this.campaignConfig.resources.save}`, campaign, {
      responseType: 'json',
      observe: 'response',
    });
  }

  savePromotionalSpaces(campaign) {
    return this.http.post(`${this.configBase}${this.actionConfig.resources.savePromotionalSpaces}`, campaign, {
      responseType: 'json',
      observe: 'response',
    });
  }

  getPromotionalPeriods(
    itempromotionTypeId: number = 1,
    year: number = __getActionPromoDefaultYear(),
    getOnlyCurrentPeriods: boolean = false
  ): Observable<any> {
    if (itempromotionTypeId == null || year == null) return of(null);

    const USE_DUMMY_DATA = false;

    if (USE_DUMMY_DATA) {
      return of(__getPromotionalPeriodsDummy()).pipe(
        delay(16),
        kayshOperator('getPromotionalPeriods', null, { localStorage: false, maxTime: _ms('8h') })
      );
    }

    const url = `${this.configBase}${this.periodConfig.resources.list}?promotionTypeId=${itempromotionTypeId}&year=${year}&onlyCurrentPeriods=${getOnlyCurrentPeriods}`;

    return this.http.get(url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getPromotionalPeriods (tap)\n\tdata: %o`, data)),
      map(data => {
        return (data as any).map(el => {
          let rv = {
            value: el.name,
            ...el,
            validFrom: _getFormatDate(el.validFrom),
            validTo: _getFormatDate(el.validTo),
          };
          return rv;
        });
      }),
      kayshOperator(
        'getPromotionalPeriods',
        { itempromotionTypeId, year, getOnlyCurrentPeriods },
        { localStorage: false, maxTime: _ms('30m') }
      )
    );
  }

  getFixedPriceDiscount(payLoad: ActionsV2_FormModel): Observable<FixedPriceDiscount> {
    let _payload: ActionsV2_FormModel = _cloneDeep(payLoad);

    const USE_DUMMY_DATA = false;
    if (USE_DUMMY_DATA) {
      return of(FIXED_PRICE_DISCOUNT_DUMMY_DATA).pipe(delay(16));
    }

    const url = `${this.configBase}${this.actionConfig.resources.fixedPriceDiscounts}`;

    return this.http.post<FixedPriceDiscount>(url, _actionDataToBe(_payload)).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getFixedPriceDiscount (tap)\n\tdata: %o`, data)),
      kayshOperator(
        'getFixedPriceDiscount',
        {
          id: payLoad?.mainData?.id,
          storeScope: _normalizeObjectToCompare(_payload.storeScope),
          itemScope: _normalizeObjectToCompare(_payload.itemScope),
          fixPrice: _payload?.activationData?.fixPrice,
          quantity: _payload?.activationData?.minimumQuantity,
        },
        { localStorage: false, maxTime: _ms('30m') }
      ),
      catchError(error => {
        if (error && _isDev() && false) {
          return of(FIXED_PRICE_DISCOUNT_DUMMY_DATA);
        }
        if (error && !_isDev()) {
          _throwError(error, 'getFixedPriceDiscount');
          return of({
            discount: null,
            regularPrice: null,
            loyaltyDiscount: null,
            loyaltyRegularPrice: null,
            exceptionalDiscounts: [],
          });
        }
      })
    );
  }

  getFixedPriceCombosDiscounts(payLoad: ActionsV2_FormModel): Observable<FixedPriceDiscountCombos[]> {
    let _payload: ActionsV2_FormModel = _cloneDeep(payLoad);

    const USE_DUMMY_DATA = false;
    if (USE_DUMMY_DATA) {
      return of(COMBOS_DISCOUNT_DUMMY_DATA);
    }

    const url = `${this.configBase}${this.actionConfig.resources.fixedPriceCombosDiscounts}`;

    const promotionCombos = _mapPromotionCombosToBe(_payload?.promotionCombosV2);
    const combosPriceCache = promotionCombos.map(combo => ({ id: combo.id, value: combo.value }));

    return this.http.post<FixedPriceDiscountCombos[]>(url, _actionDataToBe(_payload)).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getFixedPriceCombosDiscounts (tap)\n\tdata: %o`, data)),
      kayshOperator(
        'getFixedPriceCombosDiscounts',
        {
          id: _payload.mainData?.id,
          combos: _normalizeObjectToCompare(_payload.promotionCombosV2),
          storeScope: _normalizeObjectToCompare(_payload.storeScope),
          itemScope: _normalizeObjectToCompare(_payload.itemScope),
          combosPriceCache: _normalizeObjectToCompare(combosPriceCache),
        },
        { localStorage: false, maxTime: _ms('30m') }
      )
    );
  }

  resetCachegetFixedPriceCombosDiscount() {
    return kayshFlushOperator('getFixedPriceCombosDiscounts');
  }

  resetCachegetFixedPriceDiscount() {
    return kayshFlushOperator('getFixedPriceDiscount');
  }

  getVendorList(): Observable<any[]> {
    //TODO: Ver URL
    let url = `${this.configBase}${this.actionConfig.resources.vendorList}`;

    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getVendorList (tap)\n\tdata: %o`, _data)),
      kayshOperator('getPromoVendorList', null, { localStorage: false, maxTime: _ms('30m') }),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'getVendorList');
        }
        let rv = null;
        return of(rv).pipe(delay(500));
      })
    );
  }

  getPromotionValidationList(id, resetCache = false): Observable<ActionsV2_validationObject> {
    if (id == null || String(id) === String(0)) {
      return of({
        actionId: id,
        validationStatus: null,
        validations: [],
      });
    }

    if (resetCache) kayshFlushOperator('getPromotionValidationList');
    this.actionsV2_list_service.reset_cache_actionList();

    let url = `${this.configBase}${this.actionConfig.resources.actionValidation}/${id}`;

    //DUMY-DATA
    if (false && _isFeDev()) {
      return of(DUMMY_ActionsV2_promotionValidationList)
        .pipe(delay(1000))
        .pipe(kayshOperator('getPromotionValidationList', { url }, { localStorage: false, maxTime: _ms('30s') }));
    }

    return this.http.get<ActionsV2_validationObject>(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getPromotionValidation (tap)\n\tdata: %o`, _data)),
      kayshOperator('getPromotionValidationList', { url }, { localStorage: false, maxTime: _ms('10s') }),
      map(items => _mapValidations(items)),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'getPromotionValidation');
        }
        let rv = null;
        return of(rv).pipe(delay(500));
      })
    );
  }

  setPromotionValidation(validationId, approved: boolean): Observable<ActionsV2_validationObject> {
    if (validationId == null || String(validationId) === String(0) || approved == null) return of(null);

    let url = `${this.configBase}${this.actionConfig.resources.setValidationStatus}`;

    kayshFlushOperator('getPromotionValidationList');

    return this.http
      .post(url, {
        validationId: validationId,
        newStatus: approved ? 'Passed' : 'Failed',
      })
      .pipe(
        tap(_data => _logTap(`${PromotionsV2Service.name}::getPromotionValidation (tap)\n\tdata: %o`, _data)),
        catchError(error => {
          if (!_useDummyData()) {
            return _throwError('NO DATA', 'getPromotionValidation');
          }
          let rv = null;
          return of(rv).pipe(delay(500));
        })
      );
  }

  //DES-4645
  getActionExceptionalValueList(id, actionScopeType: ActionScopeType): Observable<any> {
    if (id === 0 || id === null) return of(null);

    let url = `${this.configBase}${
      actionScopeType === ActionScopeType.Simple
        ? this.actionConfig.resources.actionExceptionalValueList
        : this.actionConfig.resources.actionExceptionalValueListMixed
    }/${id}`;

    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getActionExceptionalValueList (tap)\n\tdata: %o`, _data)),
      kayshOperator('getActionExceptionalValueList', { id }, { localStorage: false, maxTime: _ms('10m') }),
      catchError(error => {
        if (!_useDummyData()) {
          return _throwError('NO DATA', 'getActionExceptionalValueList');
        }
        let rv = null;
        return of(rv).pipe(delay(500));
      })
    );
  }
  resetActionExceptionalValueListCache() {
    return kayshFlushOperator('getActionExceptionalValueList');
  }

  deleteExceptionalValueList(actionId: number) {
    const _url = `${this.configBase}${this.actionConfig.resources.deleteActionExceptionalValueList}/${actionId}`;

    return this.http
      .delete(_url)
      .pipe(tap(_data => _logTap(`${PromotionsV2Service.name}::deleteExceptionalValueList (tap)\n\tdata: %o`, _data)));
  }

  //DES-4869
  getActionValidationStatus(id): Observable<ActionsV2_ValidationStatus> {
    const url = `${this.configBase}${this.actionConfig.resources.getActionValidationStatus}/${id}`;
    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getActionValidationStatus (tap)\n\tdata: %o`, _data)),
      map((data: ActionsV2_ValidationStatus) => {
        return data;
      })
    );
  }

  //DES-4919
  getValidationReport(actionId = null, validationId = null): any {
    let url = `${this.configBase}${this.actionConfig.resources.validationReport}`;

    if (actionId && Number(actionId) > 0) url += '?actionId=' + actionId;

    if (validationId && Number(validationId) > 0) url += '&validationId=' + validationId;

    return this.http.get(url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::get (tap)\n\getValidationReport: %o`, data)),
      catchError(error => {
        return _throwError('getValidationReport');
      })
    );
  }
  // PRO-262

  revalidate(actionId: string) {
    let url = `${this.configBase}${this.actionConfig.resources.revalidate}/${actionId}`;

    return this.http.post(url, {}).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::get (tap)\n\revalidate: %o`, data)),
      catchError(error => {
        return _throwError('revalidate');
      })
    );
  }

  //PRO-333
  getAvailablePromotionalSpacesList($filters): Observable<AvailablePromotionalSpacesDto> {
    if (_isDev() && false) {
      if (false)
        return of({
          items: [],
          totalItemCount: 10,
          pageIndex: 0,
          pageSize: 1,
        }).pipe(delay(1000));

      if (false) return of(dummyDataSpaces).pipe(delay(1000));
    }

    const qs: Array<string> = ['?'];

    if ($filters.pageIndex) {
      qs.push(`pageIndex=${$filters.pageIndex}`);
    }

    if ($filters.pageSize) {
      qs.push(`pageSize=${$filters.pageSize}`);
    }

    let url = `${this.configBase}${this.actionConfig.resources.availablePromotionalSpacesList}`;

    let filters = { ...$filters, promotionPeriodId: $filters.promotionPeriodId[0] };

    if (true) {
      delete filters.promotionTypeIds;
      delete filters.promotionTypes;
      //   delete filters.promotionYear;
    }

    return this.http.post<any>(url, filters).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::get (tap)\n\getAvailablePromotionalSpacesList: %o`, data)),
      catchError(error => {
        if (true && _isDev()) return of(dummyDataSpaces).pipe(delay(1000));
        return _throwError('availablePromotionalSpacesList');
      })
    );
  }

  //PRO-333
  generateAvailablePromotionalSpacesReport(id, filters): Observable<string> {
    const url = `${this.configBaseApi}${this.actionConfig.resources.generateAvailablePromotionalSpacesReport}?reportType=${ReportType.spacesAvailability}&outputFormat=${outputFormat.excel}`;
    filters = { ...filters, planogramPopMaterialId: id, promotionPeriodId: filters.promotionPeriodId[0] };
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(url, filters, { headers, responseType: 'text' }).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::generateAvailablePromotionalSpacesReport (tap)\n\tdata: %o`, _data)),
      catchError(error => {
        return _throwError('generateAvailablePromotionalSpacesReport');
      })
    );
  }

  getPromotionalSupportTextList(id?: number): Observable<SupportTextItem[]> {
    const USE_DUMMY_DATA = false;
    if (USE_DUMMY_DATA) {
      return of([
        { id: 1, text: 'Comparte a tu antojo' },
        { id: 2, text: 'Consiente a tu hambre in home' },
        { id: 3, text: 'Consiente a tu hambre on the go' },
        { id: 4, text: 'Edicion especial' },
      ]);
    }

    let url = `${this.configBaseApi}${this.configurationConfig.resources.getSupportTextList}`;

    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getPromotionalSupportTextList (tap)\n\tdata: %o`, _data)),
      catchError(error => {
        return _throwError('getPromotionalSupportTextList');
      })
    );
  }

  getPromotionalExampleMessage(actionId: number): Observable<string> {
    const USE_DUMMY_DATA = false;
    if (USE_DUMMY_DATA) {
      return of('2 x $160 Coca cola 2,25Lts,Sprint 2,25Lts Combinalo como quieras.');
    }

    const url = `${this.configBaseApi}${this.actionConfig.resources.promotionalExampleMessage}/${actionId}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.get(url, { headers, responseType: 'text' }).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getPromotionalExampleMessage (tap)\n\tdata: %o`, _data)),
      catchError(error => {
        return _throwError('getPromotionalExampleMessage');
      })
    );
  }

  refreshPromotionStatus(actionId?: number): Observable<string> {
    if (!actionId) return;
    const USE_DUMMY_DATA = false;

    if (USE_DUMMY_DATA) {
      return of('New');
    }

    const url = `${this.configBaseApi}${this.actionConfig.resources.promotionStatus}/${actionId}`;

    return this.http.get(url).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::refreshPromotionStatus (tap)\n\tdata: %o`, _data)),
      catchError(error => {
        return _throwError('refreshPromotionStatus');
      })
    );
  }

  //
  //  POP MATERIAL
  //
  getplanogramPopMaterialList(actionId?: string | number): Observable<any> {
    const USE_DUMMY_DATA = false;

    if (USE_DUMMY_DATA) {
      return of(__getplanogramPopMaterialIdDummy()).pipe(
        delay(16),
        kayshOperator('getplanogramPopMaterialIdDirect', null, { localStorage: false, maxTime: _ms('30m') })
      );
    }

    let url = `${this.configBaseSpaces}${this.spacesConfig.resources.popMaterialList}`;
    if (!!actionId) {
      url = `${url}?actionId=${actionId}`;
    }
    return this.http.get(url).pipe(
      tap(data => _logTap(`${PromotionsV2Service.name}::getplanogramPopMaterialList (tap)\n\tdata: %o`, data)),
      map(data => {
        return (data as any).map(el => {
          let rv = {
            ...el,
            id: el?.id ?? _toNumber(el.planogramId) * 100000 + _toNumber(el.popMaterialId),
            value: el?.fullName || el.popMaterialName + ' / ' + el.planogramTitle,
          };
          return rv;
        });
      }),
      kayshOperator('getplanogramPopMaterialList', { url }, { localStorage: false, maxTime: _ms('5m') }),
      catchError(error => {
        if (true && _useDummyData()) {
          return of(__getplanogramPopMaterialIdDummy()).pipe(delay(500));
        }
      })
    );
  }

  getCalculatedPlanogramPopMaterialList(promotionTypeId: number, storeScope: FormModel_scopeStores_FE, itemScope: FormModel_scopeItems_FE) {
    let _payload = { promotionTypeId, storeScope, itemScope };

    const url = `${this.configBaseSpaces}${this.spacesConfig.resources.calculatedPopMaterialList}`;

    return this.http.post(url, _payload).pipe(
      tap(_data => _logTap(`${PromotionsV2Service.name}::getCalculatedPlanogramPopMaterialList (tap)\n\tdata: %o`, _data)),
      kayshOperator('getCalculatedPlanogramPopMaterialList', { ..._payload }, { localStorage: false, maxTime: _ms('1h') }),
      catchError(error => {
        return _throwError(error, PromotionsV2Service);
      })
    );
  }

  //
  //
  //
  getTicketMessageValidatorRules(): Observable<TicketMessageValidationRulesDTO[]> {
    const USE_DUMMY_DATA = false;
    if (USE_DUMMY_DATA) {
      return of(TICKET_MESSAGE_RULES_DUMMY_DATA.sort((a, b) => a.order - b.order));
    }
    const url = `${this.configBase}${this.actionConfig.resources.ticketMessage}`;
    return this.http.get<TicketMessageValidationRulesDTO[]>(url).pipe(
      map(_data => _mapTicketMessageValidationRulesFromBE(_data)),
      tap(_data => _logTap(`${PromotionsV2Service.name}::getTicketMessageValidatorRules (tap)\n\tdata: %o`, _data)),
      kayshOperator('getTicketMessageValidatorRules', null, { localStorage: false, maxTime: _ms('30m') })
    );
  }
}
//-----------------------------------

let __mainDataKeys = Object.keys({
  id: null,
  name: null,
  code: null,
  externalCode: null,
  type: null,
  message: null,
  observation: null,
  validFrom: null,
  validTo: null,
  frequency: null,
  frequencyDays: null,
  campaignId: null,
  status: null,
  publishStatus: null,
  timeRange: null,
  accumulatesWithOtherActions: null,
  _selectTimeRangeAllDayAllDay: null,
  //
  promotionTypeId: null,
  promotionPeriodId: null,
  promotionYear: null,
  validationStatus: null,
  estimatedSales: null,
} as ActionsV2_FormModel_mainData);

let __activationDataKeys = Object.keys({
  assortmentMethod: null,
  salesChannelCodes: null,
  loyaltyProgramCodes: null,
  minimumQuantity: null,
  payUnits: null,
  sameItem: null,
  minimumValue: null,
  minimumTotalValue: null,
  discount: null,
  discountType: null,
  fixPrice: null,
  shippingDiscount: null,
  maximumDiscountValue: null,
  untilStockLast: null,
  communicationType: null,
  communicationDisplay: null, //DES-4763
  loyaltyProgram: null,
  discountFixedPrice: null,
  discountLoyalty: null,
  discountExceptionalPrice: null,
  communicationBasedOnAssortment: null,
  communicateRegularPrice: null,
  promotionalExampleMessage: null,
  supportTextId: null,
  planogramPopMaterialIds: null,
} as ActionsV2_FormModel_activationData);

let __scopeItemsKeys = 'categoryIdsToInclude categoryIdsToExclude tagValueIdsToInclude tagValueIdsToExclude itemIdsToExclude itemIds'
  .split(' ')
  .map(st => st.trim())
  .filter(st => st.length);

let __storeScopeKeys = 'tagValueIdsToInclude tagValueIdsToExclude storeIdsToExclude storeIds'
  .split(' ')
  .map(st => st.trim())
  .filter(st => st.length);

let __scopeItemsKeysFE = 'itemIds selectionType genericCategorysSelection tagValueIdsToInclude tagValueIdsToExclude'
  .split(' ')
  .map(st => st.trim())
  .filter(st => st.length);

let __scopeGiftItemsKeysFE = 'itemIds selectionType genericCategorysSelection tagValueIdsToInclude tagValueIdsToExclude size'
  .split(' ')
  .map(st => st.trim())
  .filter(st => st.length);

let __storeScopeKeysFE = 'storeIds selectionType tagValueIdsToInclude tagValueIdsToExclude'
  .split(' ')
  .map(st => st.trim())
  .filter(st => st.length);

export const _actionDataFromBe = ($data: any): ActionsV2_FormModel => {
  if ($data == null) {
    console.error('NO DATA:', $data);
    return null;
  }

  let data = _cloneDeep($data);
  let publishEnable = data?.publishEnable;
  let mainData = _pick(data, [...__mainDataKeys, 'isSuggested' /*DES-4759*/]);
  let activationData = _pick(data, __activationDataKeys);
  let itemScope = _pick(data?.itemScope || {}, __scopeItemsKeys);
  let giftItemScope = _pick(data?.giftItemScope || {}, __scopeItemsKeys);
  let storeScope = _pick(data?.storeScope || {}, __storeScopeKeys);
  let promotionCombos = data?.promotionCombos || [];
  let discountRanges = data?.discountRanges || [];
  let paymentMethods = data?.paymentMethods || [];
  let binCodes = data?.binCodes || [];
  let actionScopeType = data?.actionScopeType || ActionScopeType.Simple;
  let paymentMethodCombinations = data?.paymentMethodCombinations || [];
  let _responseMessages = data?.responseMessages;
  let _responseSuccess = data?.responseSuccess;
  let storeScopeSelected = data?.storeScope;
  let itemIds = data?.itemIds;
  let storeIds = data?.storeIds;
  let customFields = data?.customFields;

  //ID
  if (_toNumber(mainData?.id) <= 0) {
    mainData.id = 0;
  }

  //PRO-520 cambio en el formato de fechas recibido back
  if (mainData?.validTo) {
    mainData.validTo = _getFormatDate(mainData.validTo);
  }
  if (mainData?.validFrom) {
    mainData.validFrom = _getFormatDate(mainData.validFrom);
  }

  //TIME RANGE //DES-1741
  mainData._selectTimeRangeAllDay = !(mainData.timeRange?.length > 0);
  if (mainData.timeRange?.length === 1 && mainData.timeRange[0]?.timeFrom === '00:00:00' && mainData.timeRange[0]?.timeTo === '00:00:00') {
    mainData._selectTimeRangeAllDay = true;
  }

  //MAPEO DE HORARIO //DES-1741
  if (mainData.timeRange?.length > 0) {
    mainData.timeRange = mainData.timeRange.map(tr => {
      let timeFrom = tr.timeFrom.split(':').slice(0, 2).join(':');
      let timeTo = tr.timeTo.split(':').slice(0, 2).join(':');
      return { timeFrom, timeTo };
    });
  } else {
    mainData.timeRange = null;
    mainData._selectTimeRangeAllDay = true;
  }

  // loyaltyProgram DES-4711
  if (activationData.loyaltyProgram?.length) {
    activationData.loyaltyProgram = activationData.loyaltyProgram.filter(
      lylItem => lylItem?.value != null && lylItem?.loyaltyProgramId != null
    );
  }

  //MAP ACTIVATION discount %
  if (activationData?.discountType === ActionsV2_DiscountType.Percentage) {
    activationData.discount = _toNumber(activationData?.discount || 0) * 100;

    if (activationData.loyaltyProgram?.length) {
      activationData.loyaltyProgram = activationData.loyaltyProgram.map(lylItem => ({
        ...lylItem,
        value: _toNumber(lylItem?.value || 0) * 100,
      }));
    }
  }

  //MAP ACTIVATION shippingDiscount %
  if (activationData.shippingDiscount != null) {
    activationData.shippingDiscount = _toNumber(activationData?.shippingDiscount || 0) * 100;
  }

  //MAP ITEM_SCOPE
  if (itemScope != null) {
    itemScope = mapItemScopeFromBE(itemScope);
  }

  //MAP GIFT_ITEM_SCOPE
  if (giftItemScope != null) {
    giftItemScope = mapActionVGiftItemScopeFromBE(giftItemScope);
  }

  //MAP STORE_SCOPE
  if (storeScope != null) {
    storeScope = mapStoreScopeFromBE(storeScope);
  }

  //SETEA _paymentSelection
  let _paymentSelection = ActionsV2__paymentSelectionType.None;

  if (paymentMethods?.length || binCodes?.length > 0) {
    _paymentSelection = ActionsV2__paymentSelectionType.PaymentMethods;
    paymentMethodCombinations = [];
  }

  if (paymentMethodCombinations?.length) {
    if (mainData?.type === ActionsV2_Types.Gift) {
      _paymentSelection = ActionsV2__paymentSelectionType.PaymentMethodCombinations;
      paymentMethods = [];
    } else {
      paymentMethodCombinations = [];
    }
  }

  if (paymentMethods?.length) paymentMethods = mapPaymentMethodsFromBE(_cloneDeep(paymentMethods));

  if (paymentMethodCombinations?.length) {
    paymentMethodCombinations = mapPaymentMethodsCombinationsFromBE(_cloneDeep(paymentMethodCombinations));
  }

  //promotion combos v2
  let staggeredCombosV2: PromotionCombo;
  let mixedCombosV2: PromotionCombo[] = [];
  let promotionCombosV2: PromotionCombo[];
  const USE_DUMMY_DATA = false;
  if (USE_DUMMY_DATA && _isDev()) promotionCombos = _cloneDeep(promotionCombosDummyData);

  let staggeredPromotionCombos = (promotionCombos as PromotionComboDTO[]).filter(combo => combo.isStaggered);
  let ids = staggeredPromotionCombos[0]?.block.map(item => _createUnicId());
  staggeredPromotionCombos = staggeredPromotionCombos.map(combo => ({
    ...combo,
    block: combo.block.map((block, blockIndex) => ({ ...block, blockId: ids[blockIndex] })),
  }));
  mixedCombosV2 = (promotionCombos as PromotionComboDTO[])
    .filter(combo => !combo.isStaggered)
    ?.map(combo => ({ block: combo.block, staggered: combo.isStaggered, value: combo.value === 0 ? null : combo.value, id: combo.id }));

  if (!!(staggeredPromotionCombos.length > 0)) {
    staggeredCombosV2 = {
      block: [...staggeredPromotionCombos[0].block],
      staggered: true,
      value: staggeredPromotionCombos[0].value === 0 ? null : staggeredPromotionCombos[0].value,
      id: staggeredPromotionCombos[0].id,
      childrenCombos: staggeredPromotionCombos.slice(1).map(combo => {
        return {
          comboId: combo.id,
          parentId: staggeredPromotionCombos[0].id, // id del promotion combo con index 0
          childrenBlocks: combo.block.map(block => ({ blockId: block.blockId, stock: block.stock })),
          value: combo.value === 0 ? null : combo.value,
        };
      }),
    };
    promotionCombosV2 = [staggeredCombosV2, ...mixedCombosV2];
  } else {
    promotionCombosV2 = [...mixedCombosV2];
  }

  return {
    publishEnable,
    mainData,
    activationData,
    itemScope,
    storeScope,
    discountRanges,
    giftItemScope,
    _responseMessages,
    _responseSuccess,
    promotionCombosV2,
    _payments: {
      _paymentSelection,
      paymentMethods,
      paymentMethodCombinations,
      binCodes,
    },
    actionScopeType,
    promotionCombos,
    customFields,
    storeScopeSelected,
    itemIds,
    storeIds,
  };
};

const getCustomFieldsParse = data => {
  let rv = null;

  if (data?.customFields) {
    try {
      rv = json5.parse(data?.customFields);
    } catch (e) {}
  }

  return rv;
};

export const _mapValidations = (data: ActionsV2_validationObject): ActionsV2_validationObject => {
  if (data?.validations) {
    data?.validations?.sort((a, b) => {
      if (a.validationType && b.validationType) {
        return a.validationType.localeCompare(b.validationType);
      } else {
        return 0;
      }
    });
  }
  return data;
};

export const _actionDataToBe = ($data: ActionsV2_FormModel): any => {
  if ($data == null) {
    console.error('NO DATA:', $data);
    return null;
  }

  let data: ActionsV2_FormModel = _cloneDeep($data);
  let publishEnable = data?.publishEnable;
  let mainData = _pick(data?.mainData || {}, __mainDataKeys);
  let activationData = _pick(data?.activationData || {}, __activationDataKeys);
  let itemScope = { itemScope: _pick(data?.itemScope || {}, __scopeItemsKeysFE) };
  let giftItemScope = { giftItemScope: _pick(data?.giftItemScope || {}, __scopeGiftItemsKeysFE) };
  let storeScope = { storeScope: _pick(data?.storeScope || {}, __storeScopeKeysFE) };
  let discountRanges = { discountRanges: [...(data?.discountRanges || [])] };
  let binCodes = [...(data?._payments?.binCodes || [])];
  let paymentMethods = [...(data?._payments?.paymentMethods || [])];
  let paymentMethodCombinations = [...(data?._payments?.paymentMethodCombinations || [])];
  let promotionCombos = [...(data?.promotionCombos || [])];
  let actionScopeType = data?.actionScopeType;
  let itemIds = data?.itemIds;
  let storeIds = data?.storeIds;
  let customFields = data?.customFields;

  //ID
  if (_toNumber(mainData?.id) <= 0) {
    mainData.id = 0;
  }

  //MAP ACTIVATION
  if (activationData.minimumQuantity != null && _toNumber(activationData.minimumQuantity) < 0) {
    activationData.minimumQuantity = null;
  }

  //PRO-520 cambio en el formato de fechas enviado a back
  if (mainData?.validTo) {
    mainData.validTo = _getFormatDate(mainData.validTo);
  }
  if (mainData?.validFrom) {
    mainData.validFrom = _getFormatDate(mainData.validFrom);
  }

  //PRO-207
  if (actionScopeType !== ActionScopeType.Simple) {
    activationData.minimumQuantity = null;
    activationData.sameItem = true;
  }

  if (activationData.payUnits != null && _toNumber(activationData.payUnits) < 0) {
    activationData.payUnits = null;
  }

  //MAPEO DE HORARIO //DES-1741
  if (mainData.timeRange?.length > 0) {
    mainData.timeRange = mainData.timeRange.map(tr => {
      let timeFrom = tr.timeFrom ? tr.timeFrom + ':00' : null;
      let timeTo = tr.timeTo ? tr.timeTo + ':00' : null;
      return { timeFrom, timeTo };
    });
  } else {
    mainData.timeRange = [];
  }

  if (mainData.frequencyDays == null) {
    mainData.frequencyDays = [];
  }
  // loyaltyProgram DES-4711 // Si hay un programa GLOBAL anula el particular
  if (true && activationData.loyaltyProgramCodes?.length > 0) {
    activationData.loyaltyProgram = [];
  }

  // loyaltyProgram DES-4711
  if (activationData.loyaltyProgram?.length) {
    activationData.loyaltyProgram = activationData.loyaltyProgram
      .filter(lylItem => lylItem?.value != null && lylItem?.loyaltyProgramId != null)
      .filter((lylItem, i) => i === 0) //Por ahora solo permite una sola opción
      .map(lylItem => ({
        ...lylItem,
        value: _toNumber(lylItem?.value),
      }));
  }

  //MAP ACTIVATION discount %
  if (activationData?.discountType === ActionsV2_DiscountType.Percentage) {
    activationData.discount = Math.max(0, _toNumber(activationData?.discount || 0) / 100);

    if (activationData.loyaltyProgram?.length) {
      activationData.loyaltyProgram = activationData.loyaltyProgram.map(lylItem => ({
        ...lylItem,
        value: Math.max(0, _toNumber(lylItem?.value || 0) / 100),
      }));
    }
  }

  //MAP ACTIVATION shippingDiscount %
  if (activationData.shippingDiscount != null) {
    activationData.shippingDiscount = Math.max(0, _toNumber(activationData?.shippingDiscount || 0) / 100);
  }

  //MAP ITEM_SCOPE
  if (itemScope != null) {
    itemScope = mapItemScopeToBE(itemScope, false, undefined, true); //Force onlyItemsAllowedForSelling to false
  }

  //MAP GIFT ITEM_SCOPE
  if (mainData.type === ActionsV2_Types.Gift) {
    if (giftItemScope != null) giftItemScope = mapActionVGiftItemScopeToBE(giftItemScope, false); //Force onlyItemsAllowedForSelling to false
  } else {
    giftItemScope = null;
  }

  //MAP STORE_SCOPE
  if (storeScope != null) {
    storeScope = mapStoreScopeToBE(storeScope);

    //DES-1651: Redunda los itemsScopes para storeScope por performance
    (storeScope as any).itemScopes = (mainData.type === ActionsV2_Types.Gift ? [giftItemScope, itemScope] : [itemScope]).filter(
      el => el != null
    );
  }

  //paymentMethodCombinations solo para GIFTs
  if ((mainData?.type !== ActionsV2_Types.Gift || paymentMethods.length) && paymentMethodCombinations?.length) {
    paymentMethodCombinations = [];
  }

  if (paymentMethods?.length) paymentMethods = mapPaymentMethodsToBE(_cloneDeep(paymentMethods));

  if (paymentMethodCombinations?.length) {
    paymentMethodCombinations = mapPaymentMethodsCombinationsToBE(_cloneDeep(paymentMethodCombinations));
  }

  //DES-4727
  if (mainData && mainData.validationStatus == null) mainData.validationStatus = ActionsV2_ValidationStatus.Pending;

  //Map promotion combos
  if (!!(data?.promotionCombosV2.length > 0)) {
    promotionCombos = _mapPromotionCombosToBe(data?.promotionCombosV2);

    let itemIds = [];

    promotionCombos.forEach((obj: PromotionComboDTO) => {
      obj.block.forEach(block => {
        itemIds.push(...block.items);
      });
    });

    itemScope['itemIds'] = itemIds;
  }

  let rv = {
    ...discountRanges,
    ...activationData,
    ...mainData,
    publishEnable,
    paymentMethods,
    paymentMethodCombinations,
    itemScope,
    giftItemScope,
    storeScope,
    promotionCombos,
    actionScopeType,
    itemIds,
    storeIds,
    customFields,
    binCodes,
  };

  return rv;
};

const _mapPromotionCombosToBe = (data: PromotionCombo[]): PromotionComboDTO[] => {
  let promotionCombosV2: PromotionComboDTO[] = [];
  let mixedCombos: PromotionCombo[] = data?.filter(combo => !combo.staggered);
  let staggeredCombo: PromotionCombo = data?.find(combo => combo.staggered);
  //map staggered
  if (staggeredCombo) {
    promotionCombosV2.push({
      block: staggeredCombo.block.map(block => ({ items: block.items, stock: block.stock, name: block.name })),
      isStaggered: true,
      value: staggeredCombo.value,
      id: staggeredCombo.id,
    });
    if (!!(staggeredCombo.childrenCombos.length > 0)) {
      staggeredCombo.childrenCombos.forEach(combo => {
        promotionCombosV2.push({
          block: combo.childrenBlocks.map((childCombo, index) => {
            return {
              items: staggeredCombo.block[index].items,
              stock: childCombo.stock,
              name: staggeredCombo.block[index].name,
            };
          }),
          isStaggered: true,
          value: combo.value ?? 0,
          id: combo.comboId,
        });
      });
    }
  }

  if (!!(mixedCombos.length > 0)) {
    //map mixed
    const mixedCombosClone: PromotionComboDTO[] = mixedCombos.map(combo => ({
      block: combo.block.map(block => ({ items: block.items, stock: block.stock, name: block.name })),
      id: combo.id,
      value: combo.value ?? 0,
      isStaggered: false,
    }));
    promotionCombosV2 = [...promotionCombosV2, ...mixedCombosClone];
  }
  return promotionCombosV2;
};

const mapPaymentMethodsFromBE = (data: ActionsV2_FormModel_paymentMethods[]): ActionsV2_FormModel_paymentMethods[] => {
  let rv = (data || [])
    .filter(obj => obj != null)
    .map(pm => {
      let _rv = pm;

      if (_rv.sponsorship) {
        _rv.sponsorship = _roundDec(_toNumber(_rv?.sponsorship) * 100, 2);
      }

      return pm;
    });

  return rv;
};

const mapPaymentMethodsToBE = (data: ActionsV2_FormModel_paymentMethods[]): ActionsV2_FormModel_paymentMethods[] => {
  let rv = (data || [])
    .filter(obj => obj != null)
    .map(pm => {
      let _rv = pm;

      if (_rv.sponsorship) {
        _rv.sponsorship = _toNumber(_rv?.sponsorship) / 100;
      }

      return pm;
    });

  return rv;
};

const mapPaymentMethodsCombinationsFromBE = (
  data: ActionsV2_FormModel_paymentMethodsCombinations[]
): ActionsV2_FormModel_paymentMethodsCombinations[] => {
  let rv = data || [];

  return rv;
};

const mapPaymentMethodsCombinationsToBE = (
  data: ActionsV2_FormModel_paymentMethodsCombinations[]
): ActionsV2_FormModel_paymentMethodsCombinations[] => {
  let rv = data || [];

  return rv;
};

const _mapPaymentMethodsFromBe = (data: PaymentMethodsSelectTree[], translate): PaymentMethodsSelectTree[] => {
  return data.map(lvl1 => ({
    ...lvl1,
    level: 3,
    binCode: lvl1?.items[0]?.items[0]?.binCode?.toLocaleString().slice(0, 3) + `-${lvl1.id}`,
    items: lvl1.items.map(lvl2 => ({
      ...lvl2,
      level: 4,
      binCode: lvl2?.items[0]?.binCode?.toLocaleString().slice(0, 6) + `-${lvl2.id}`,
      parentBinCode: lvl1?.items[0]?.items[0]?.binCode?.toLocaleString().slice(0, 3) + `-${lvl1.id}`,
      items: lvl2.items.map(lvl3 => ({
        level: 5,
        ...lvl3,
        name: translate.instant('PROMOV2.ACTIONS_EDIT.PAYMENTS.' + lvl3.name.toUpperCase().trim(), lvl3.name) || lvl3.name,
        parentBinCode: lvl2?.items[0]?.binCode?.toLocaleString().slice(0, 6) + `-${lvl2.id}`,
      })),
    })),
  }));
};

const _mapTicketMessageValidationRulesFromBE = (data: TicketMessageValidationRulesDTO[]): TicketMessageValidationRulesDTO[] => {
  return data
    ?.map(rule => {
      const match = /^\/(.*)\/([gimsuy]*)$/.exec(rule.regex as string);
      let regExp: RegExp;
      if (match) {
        const [, pattern, flags] = match;
        regExp = new RegExp(pattern, flags);
      } else {
        regExp = new RegExp(rule.regex);
      }
      return { ...rule, regex: regExp };
    })
    .sort((a, b) => a.order - b.order);
};

const dummyWarningResponse = {
  accumulatesWithOtherActions: false,
  assortmentMethod: 'DoNotActivate',
  availableStock: null,
  campaignId: null,
  code: '3x2 SodaPopBase',
  discount: 1,
  shippingDiscount: null,
  discountedUnits: 1,
  discountRanges: null,
  discountType: 'Percentage',
  externalCode: null,
  fixPrice: null,
  frequency: 'EveryDay',
  frequencyDays: [],
  giftItemDescription: null,
  giftItemId: null,
  id: 3,
  isSurchage: false,
  itemCodes: ['574390369', '564252928', '568478332', '566054418', '579770219', '9548866', '566019065', '554845492', '9587493', '9547825'],
  itemIds: [15, 23, 27, 32, 34, 36, 38, 41, 47, 49],
  itemScope: {
    categoryIdsToInclude: [22, 200],
    categoryIdsToExclude: [],
    tagValueIdsToInclude: [28],
    tagValueIdsToExclude: [],
    itemIds: [],
    itemIdsToExclude: [],
    onlyItemsAllowedForSelling: false,
  },
  loyaltyProgramCodes: [],
  maximumDiscountValue: null,
  communicationType: null,
  communicationDisplay: null,
  maximumMonthlyDiscountValue: null,
  message: null,
  minimumQuantity: 3,
  minimumTotalValue: null,
  minimumValue: null,
  name: '3x2 SodaPopBase',
  observation: null,
  paymentMethodCombinations: [],
  paymentMethods: [],
  promotionsPublishedDate: null,
  quantityToPay: 0,
  salesChannelCodes: [],
  sameItem: true,
  status: 'New',
  storeCodes: ['S004'],
  storeIds: [5],
  storeScope: {
    tagValueIdsToInclude: [],
    tagValueIdsToExclude: [],
    storeIds: [3, 5, 8],
    storeIdsToExclude: [],
    itemScopes: [
      {
        categoryIdsToInclude: [22, 200],
        categoryIdsToExclude: [],
        tagValueIdsToInclude: [28],
        tagValueIdsToExclude: [],
        itemIds: [],
        itemIdsToExclude: [],
        onlyItemsAllowedForSelling: false,
      },
    ],
  },
  giftItemScope: null,
  timeRange: [],
  type: 'BuyAPayB',
  untilStockLast: true,
  validFrom: '2022-06-01T03:00:00+00:00',
  validTo: null,
  responseSuccess: true,
  responseMessages: [
    {
      type: 'Warning',
      _____keyword: 'InvalidStoreScope',
      values: '',
      link: '',
    },
    {
      type: 'Warning',
      keyword: 'InvalidManualStoreScopes',
      values:
        'S002 - PrisMart Winter Park,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E,S007 - PrisMart Altamonte Springs E',
      link: '',
    },
  ],
};

export const __getcustomFieldsDummy = (promotionTypeId, caller) => {
  let promotionType =
    __getItemsTypesOxxo().find(pr => String(pr.id) === String(promotionTypeId)) ||
    __getItemsTypesOxxo().find(pr => String(pr.code) === String(promotionTypeId));

  if (promotionType == null) {
    _warn('NO promotionType', promotionType, promotionTypeId, __getItemsTypesOxxo());
    return __flatCustomFieldsToGroup([]);
  }

  _log('<__getcustomFieldsDummy>', { promotionTypeId, caller });

  return __getcustomFieldsByPromotionType(promotionType, caller);
};

export const __getcustomFieldsByPromotionType = (promotionType, caller) => {
  _log('<__getcustomFieldsByPromotionType>', { promotionType, caller });
  return __flatCustomFieldsToGroup(promotionType?.customFields || []);
};

export const __flatCustomFieldsToGroup = (flatCustomFieldsArray: any[]) => {
  let rv = [];

  (flatCustomFieldsArray || []).forEach(($attr, i) => {
    let attr = _cloneDeep($attr);
    let groupName = attr.groupName;
    let attrId = attr.id ?? i;
    let groupNameIndex = rv.findIndex(gr => String(gr?.groupName) === String(groupName));

    let typeId = attr?.type;

    let dataTypes = [
      // (Number = 1), (Text = 2), (Range = 3), (Boolean = 4), (List = 5);
      CustomFieldAttributeTypeSelModel.NUMBER,
      CustomFieldAttributeTypeSelModel.STRING,
      CustomFieldAttributeTypeSelModel.DATETIME,
      CustomFieldAttributeTypeSelModel.BOOLEAN,
      CustomFieldAttributeTypeSelModel.LIST,
    ];

    let dataType = dataTypes[_toNumber(typeId) + 1];

    attr = { id: i, value: null, dataType, ...attr };

    if (groupNameIndex > -1 && rv?.[groupNameIndex]?.atributes) {
      rv[groupNameIndex].atributes.push(attr);
    } else {
      let groupId = rv?.length;
      let group = {
        id: groupId + '.' + attrId,
        groupName: groupName,
        atributes: [attr],
      };
      rv.push(group);
    }
  });

  return rv;
};

export const __getPromotionalPeriodsDummy = () => {
  _log('<__promotionalPeriodsDummy>');
  return __promotionalPeriodsDummy;
};

let __promotionalPeriodsDummy = [
  { id: 1, value: 'Periodo Promocional 1', validFrom: '2023-05-01T00:00:00-03:00', validTo: '2023-05-30T00:00:00-03:00' },
  { id: 2, value: 'Periodo Promocional 2', validFrom: '2023-07-01T00:00:00-03:00', validTo: '2023-07-30T00:00:00-03:00' },
  { id: 3, value: 'Periodo Promocional 3', validFrom: '2023-08-10T00:00:00-03:00', validTo: '2023-08-20T00:00:00-03:00' },
  { id: 4, value: 'Periodo Promocional 4', validFrom: '2024-09-11T00:00:00-03:00', validTo: '2024-12-20T00:00:00-03:00' },
];

export const __getplanogramPopMaterialIdDummy = () => {
  _log('<__getplanogramPopMaterialIdDummy>');
  return __planogramPopMaterialIdDummy;
};

let __planogramPopMaterialIdDummy = [
  { id: 1, value: 'Planogram Pop Material 1' },
  { id: 2, value: 'Planogram Pop Material 2' },
  { id: 3, value: 'Planogram Pop Material 3' },
  { id: 4, value: 'Planogram Pop Material 4' },
];

let _mockCoverage = [
  {
    tagValue: 'Código Plaza Norte',
    coverage: 0.72,
    minimumCoverage: 0.84,
    detail: [
      {
        tagValue: 'Plaza Oeste',
        storeId: 0,
        storeCode: '23467',
        storeName: 'storeName',
        itemId: 0,
        itemCode: '5765879',
        itemName: 'itemName',
      },
      {
        tagValue: 'Plaza Este',
        storeId: 0,
        storeCode: '4356345',
        storeName: 'storeName',
        itemId: 0,
        itemCode: '7435634',
        itemName: 'itemName',
      },
    ],
  },
  {
    tagValue: 'Código Plaza Sur',
    coverage: 0.62,
    minimumCoverage: 0.24,
    detail: [
      {
        tagValue: 'Plaza Oeste',
        storeId: 0,
        storeCode: '65423',
        storeName: 'storeName',
        itemId: 0,
        itemCode: '234234',
        itemName: 'itemName',
      },
      {
        tagValue: 'Plaza Este',
        storeId: 0,
        storeCode: '745633',
        storeName: 'storeName',
        itemId: 0,
        itemCode: '45621',
        itemName: 'itemName',
      },
    ],
  },
];
