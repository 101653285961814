import { getDateFormat, LenguageDateFormatType } from './../../../app.config';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { _getEnv, _isDev, _isFeDev, _isLab, _isStaging, _log } from '@shared/aux_helper_environment';
import { _toNumber } from '@shared/aux_helper_functions';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { DevSettingsService, FeatureFlagsKeysEnum } from 'core/services/dev-settings.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';

const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
const _saveGlobalSettingsInLocalStorage = () => _getEnv('saveGlobalSettingsInLocalStorage');

//Deveulve el zoom que tiene el elemento 'app' //UXV2
let _appSelector = null;
export function _getAppZoomScale() {
  const _isEnabled = _getEnv('useUXV2');
  if (_isEnabled !== true) 1;

  if (document == null) return;

  if (_appSelector == null) {
    let _appSelectorTmp = document.querySelector('html._UXV2_ app');

    if (_appSelectorTmp == null) return 1;

    _appSelector = _appSelectorTmp;
  }

  let zoom = _toNumber((getComputedStyle(_appSelector) as any)?.zoom || 1);

  return zoom;
}

export function _setAppZoomScale(scale: number /* entre 0 y 1*/, saveInLocalStorage = true) {
  const _isEnabled = _getEnv('useUXV2');
  if (_isEnabled !== true) return;

  if (document == null || scale == null) return;

  scale = _toNumber(scale);

  if (scale <= 0 || scale > 1) return;

  if (_appSelector == null) {
    let _appSelectorTmp = document.querySelector('html._UXV2_ app');

    if (_appSelectorTmp == null) return 1;

    _appSelector = _appSelectorTmp;
  }

  if (_appSelector?.style == null) return;

  _appSelector.style.setProperty('--ux_zoom_scale', `${scale}`);

  if (saveInLocalStorage) _setLocalStorage__ux_zoom_scale(scale);
}

const _internal_getEnv_useUXV2 = () => {
  if (_isFeDev() && false) return false;
  // DES-1416
  return !!_getEnv('useUXV2');
};

//get UXV2
export const _getUseUXV2 = (): boolean => {
  const _isEnabled = _internal_getEnv_useUXV2();
  if (!_isEnabled) return false;

  let _html = document.querySelector('html');

  if (_html == null) return false;

  return _html.classList.contains('_UXV2_');
};

//Setea UXV2
export const _setUseUXV2 = () => {
  const _isEnabled = _internal_getEnv_useUXV2();
  if (!_isEnabled) return;

  let _html = document.querySelector('html');

  if (_html == null || _getUseUXV2() === true) return;

  _html.classList.add('_UXV2_');

  _getLocalStorage__ux_zoom_scale();

  _fixGetBoundingClientRectScale();
};

const _getLocalStorage__ux_zoom_scale = () => {
  if (!_saveGlobalSettingsInLocalStorage()) return;

  let valKey = _keyVer + 'globalSettings.--ux_zoom_scale';

  let value = localStorage.getItem(valKey);

  if (value !== null) _setAppZoomScale(_toNumber(value));
};

const _setLocalStorage__ux_zoom_scale = value => {
  if (!_saveGlobalSettingsInLocalStorage()) return;

  let valKey = _keyVer + 'globalSettings.--ux_zoom_scale';

  localStorage.setItem(valKey, String(value));
};

function getChromeVersion() {
  let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
}

const isChorme128 = Number(getChromeVersion()) >= 128;

//Sobrescribe el método getBoundingClientRect teniendo en cuenra _getAppZoomScale() //UXV2
function _fixGetBoundingClientRectScale() {
  if (Element.prototype.getBoundingClientRect && true) {
    const DefaultGetBoundingClientRect = Element.prototype.getBoundingClientRect;

    (Element as any).prototype.getBoundingClientRectCustomScale = function (invert: boolean = false, noScale = false) {
      let data = DefaultGetBoundingClientRect.apply(this, arguments);
      let zoom = _getAppZoomScale();

      //FIX: Se generaba un offset pq el boundingBox no estaba contemlpando la escala del zoom
      //     Esto se da a partir de la versión 128 de chromium
      //     lo mismo le pasó a JQUERY https://github.com/jquery/jquery-ui/issues/2292
      //     Por eso se checkea la versión de Chrome para applicarlo de una manera u la otra
      let fixBoundingClientRectWhitZoom = isChorme128;
      if (!fixBoundingClientRectWhitZoom) noScale = invert;

      if (zoom === 1 || noScale === true) return data;

      if (invert) zoom = 1 / zoom;

      data = JSON.parse(JSON.stringify(data));

      data.x = data.x * zoom;
      data.y = data.y * zoom;
      data.top = data.top * zoom;
      data.left = data.left * zoom;
      data.right = data.right * zoom;
      data.bottom = data.bottom * zoom;

      if (invert) {
        data.width = data.width * zoom;
        data.height = data.height * zoom;
      }

      return data;
    };

    if (!isChorme128) (Element as any).prototype.getBoundingClientRect = (Element as any).prototype.getBoundingClientRectCustomScale;
  }
}

@Component({
  selector: 'global-settings-panel',
  template: `
    <!-- -- -->
    <ng-container *ngIf="isOpen">
      <div id="notification-panel-wrapp" fxLayout="column" fxFlex>
        <mat-list class="date" cdkFocusRegionStart>
          <h3 matsubheader="" cdkfocusinit="" class="mat-subheader">
            <b class="gSettings-title">{{ lang?.TITLE }}</b> &nbsp;
          </h3>
        </mat-list>
        <div>
          <div class="gSettings-container">
            <!-- //Theme -->
            <ng-container *ngIf="canUseDarkTheme">
              <div style="display: block;">
                <mat-divider></mat-divider>
                <ng-container>
                  <h4 class="color-green">
                    <b> {{ lang.THEME }}:</b>
                  </h4>
                  <mat-button-toggle-group
                    name="fontStyle"
                    class="toggle-grpup toggle-grpup-two-options"
                    #zoomSelection="matButtonToggleGroup"
                    [value]="colorTheme"
                    (change)="setTheme($event?.value)"
                  >
                    <mat-button-toggle value="theme-default"><mat-icon>light_mode</mat-icon>{{ lang.LIGTH }}</mat-button-toggle>
                    <mat-button-toggle value="theme-dark"><mat-icon>dark_mode</mat-icon>{{ lang.DARK }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </ng-container>
              </div>
            </ng-container>
            <!-- DATE FORMAT -->
            <ng-container *ngIf="canUseDateFormat">
              <div style="display: block;">
                <mat-divider></mat-divider>
                <ng-container>
                  <h4 class="color-green">
                    <b> {{ lang.DATE_FORMAT }}:</b>
                  </h4>
                  <mat-button-toggle-group
                    name="fontStyle"
                    class="toggle-grpup toggle-grpup-two-options"
                    [value]="dateFormat"
                    (change)="setDateFormat($event?.value)"
                  >
                    <mat-button-toggle [value]="DDMMYYYY">DD/MM/YYYY</mat-button-toggle>
                    <mat-button-toggle [value]="MMDDYYYY">MM/DD/YYYY</mat-button-toggle>
                  </mat-button-toggle-group>
                </ng-container>
              </div>
            </ng-container>

            <!-- //Scale / Zoom -->
            <ng-container>
              <div style="display: block;">
                <mat-divider></mat-divider>
                <ng-container>
                  <h4 class="color-green">
                    <b> {{ lang?.FONT_SIZE }}:</b>
                  </h4>
                  <mat-button-toggle-group
                    name="fontStyle"
                    class="toggle-grpup"
                    #zoomSelection="matButtonToggleGroup"
                    [value]="_zoomSelection"
                    (change)="changeZoom($event)"
                  >
                    <mat-button-toggle value="scale-75"><mat-icon>text_decrease</mat-icon>{{ lang?.SMALL }}</mat-button-toggle>
                    <mat-button-toggle value="scale-100"><mat-icon>text_format</mat-icon>{{ lang?.NORMAL }}</mat-button-toggle>
                    <mat-button-toggle value="scale-125"><mat-icon>text_increase</mat-icon>{{ lang?.BIG }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </ng-container>
              </div>
            </ng-container>

            <!-- //DEV SETTINGS -->
            <global-dev-settings></global-dev-settings>
          </div>
        </div>
      </div>
      <!-- -- -->
    </ng-container>
  `,
  styles: [
    `
      .gSettings-title {
        margin-top: 16px;
        font-size: 20px;
      }

      .gSettings-container {
        margin: 16px;
      }

      .toggle-grpup {
        border-radius: 4px;
        width: 100%;
      }

      .toggle-grpup mat-button-toggle {
        width: 33.3%;
      }

      .toggle-grpup-two-options mat-button-toggle {
        width: 50% !important;
      }

      :host {
        margin-bottom: 32px;
      }

      :host ::ng-deep mat-button-toggle {
        font-size: 13px;
      }

      :host ::ng-deep mat-button-toggle mat-icon {
        font-size: 18px;
        margin-right: 2px;
        vertical-align: middle;
      }

      :host h4 {
        font-weight: 300;
      }
    `,
  ],
})
export class GlobalSettingsPanelComponent implements OnInit {
  lang = null as any;

  _zoomSelection = null;

  scalesValues = {
    'scale-75': 0.75,
    'scale-100': 0.875,
    'scale-125': 1,
  };

  isOpen = false;

  colorTheme: any;
  dateFormat: any;

  isFeDev = _isFeDev();
  isDev = _isDev();
  isLab = _isLab();
  isStaging = _isStaging();

  DDMMYYYY = LenguageDateFormatType.DDMMYYYY;
  MMDDYYYY = LenguageDateFormatType.MMDDYYYY;

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private translate: IanTranslateService,
    private devSettingsService: DevSettingsService,
    private _fuseConfigService: FuseConfigService,
    public dialog: MatDialog
  ) {
    this.getScale();
  }

  get canUseDarkTheme() {
    return true || this.isLab || this.isStaging || this.isDev;
  }

  get canUseDateFormat() {
    let rv = this.devSettingsService.hasFeatureFlag(FeatureFlagsKeysEnum['FeatureFlag__DES-3172']);
    return !!rv;
  }

  get canUserDevSettings() {
    if (this.devSettingsService == null) return false;
    return this.devSettingsService.__canShowDevSettings;
  }

  ngOnInit(): void {
    this._fuseSidebarService.getSidebar('globalSettings').openedChanged.subscribe(data => {
      this.isOpen = data;
      if (this.isOpen) this.setLangs();
    });

    this._fuseConfigService.config.subscribe(config => {
      this.colorTheme = config.colorTheme;
    });

    this.dateFormat = getDateFormat();
  }

  get isDarkTheme() {
    return this.colorTheme === 'theme-dark';
  }

  setLangs() {
    if (this.lang != null) return;

    this.lang = {
      TITLE: this.translate.instant('GLOBAL_CONFIG.TITLE'),
      //
      FONT_SIZE: this.translate.instant('GLOBAL_CONFIG.FONT_SIZE'),
      SMALL: this.translate.instant('GLOBAL_CONFIG.SMALL'),
      NORMAL: this.translate.instant('GLOBAL_CONFIG.NORMAL'),
      BIG: this.translate.instant('GLOBAL_CONFIG.BIG'),
      //
      THEME: this.translate.instant('GLOBAL_CONFIG.THEME'),
      LIGTH: this.translate.instant('GLOBAL_CONFIG.LIGTH'),
      DARK: this.translate.instant('GLOBAL_CONFIG.DARK'),
      DATE_FORMAT: this.translate.instant('GLOBAL_CONFIG.DATE_FORMAT'),
    };
  }

  getScale() {
    let realScale = _getAppZoomScale();

    let scale = Object.keys(this.scalesValues).find(key => this.scalesValues[key] == realScale) || 'scale-100';

    this._zoomSelection = scale;
  }

  changeZoom(e) {
    if (e?.value == null) return;

    this._zoomSelection = e.value;

    let scale = this.scalesValues[e.value];

    if (scale != null) _setAppZoomScale(scale);
  }

  setTheme(value: String) {
    if (value !== 'theme-default' && value !== 'theme-dark') return;

    this._fuseConfigService.setConfig({ colorTheme: value });
  }

  async setDateFormat(value: string) {
    if (value !== this.DDMMYYYY && value !== this.MMDDYYYY) return;

    let confirm = await this.promptConfirmation();
    if (!confirm) return;
    this.dateFormat = value;
    localStorage.setItem(_keyVer + 'dateFormat', value);
    (window as any)?.location?.reload?.();
  }

  async promptConfirmation() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '550px',
      data: {
        message: this.translate.instant('GLOBAL_CONFIG.RESET_WARNING', null),
        title: this.translate.instant('GENERIC_WARNING', null),
        labelConfirm: this.translate.instant('GENERIC_RESET', null),
        labelCancel: this.translate.instant('GENERIC_CANCEL', null),
        disableClose: true,
        timeToConfirm: 720,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }
}
