import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@env';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';
import { _isDev } from '@shared/aux_helper_environment';
import { _orderBy, _uniqueElements } from '@shared/aux_helper_functions';
import { DevSettingsService, FeatureFlagsKeysEnum, normaliceFFkey } from 'core/services/dev-settings.service';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

@Component({
  selector: 'global-dev-settings-feature-flags',
  template: `
    <ng-container>
      <div class="scroll-list">
        <mat-list>
          <mat-list-item *ngFor="let item of featureFlagsValues; let i = index">
            <ng-container *ngIf="i + 1 !== featureFlagsValues.length">
              <mat-divider></mat-divider>
            </ng-container>

            <div mat-line class="title">
              <span
                [style.font-weight]="item?.userValue !== item?.defaultValue ? 'bold' : 'normal'"
                (click)="oepenUrl(item?.cardId)"
                style="cursor: pointer;"
                [matTooltip]="
                  item?.key +
                  '
Default: ' +
                  item?.defaultValue +
                  '
Click to open in Jira'
                "
              >
                {{ item?.cardId }}
              </span>
            </div>

            <div mat-line class="description">
              <span
                (click)="copyKey(item?.key)"
                [matTooltip]="
                  item?.key +
                  '
Default: ' +
                  item?.defaultValue +
                  '
Click to copy in clipboard'
                "
              >
                {{ item?.description }}
              </span>
              <ng-container *ngIf="false">
                / {{ item?.userValue | json }} / {{ item?.defaultValue | json }} /
                {{ item?.userValue !== item?.defaultValue | json }}
              </ng-container>
            </div>

            <div class="flex"></div>
            <mat-slide-toggle
              [checked]="item?.userValue"
              (change)="item.userValue = !item?.userValue"
              [disabled]="item.editable === false"
            ></mat-slide-toggle>
          </mat-list-item>
        </mat-list>
      </div>
      <br />

      <div dir="rtl">
        <div class="hasFlagChanges">
          <button mat-button color="accent" (click)="saveFlagChanges()" [disabled]="!hasFlagChanges">
            {{ lang?.SAVE }}
          </button>
          <button *ngIf="hasFlagChanges" mat-button color="secundary" (click)="canceFlagChanges()">
            {{ lang?.CLEAN }}
          </button>
          <button *ngIf="hasFlagChangesInLocalStorage" mat-button color="secundary" (click)="resetFlagChanges()">
            {{ lang?.RESET }}
          </button>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host ::ng-deep mat-list {
      }

      :host ::ng-deep mat-list-item {
        padding-right: 6px !important;
      }

      :host ::ng-deep .mat-list-item-content {
        padding: 0px !important;
      }

      :host ::ng-deep .mat-list-base {
        padding-top: 0px !important;
      }

      .scroll-list {
        max-height: calc(100vh - 425px);
        min-height: 216px !important;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .scroll-list::-webkit-scrollbar {
        width: 6px !important;
        opacity: 0.5 !important;
      }

      :host .description {
        width: calc(250px);
        max-height: 36px;
        overflow: hidden;
      }

      :host .description span {
        word-wrap: break-word;
        text-wrap: balance;
        display: block;
        width: 100%;
        padding-top: 4px;
        line-height: 1.1;
      }
    `,
  ],
})
export class GlobalSettingsFeatureFlagsComponent implements OnInit {
  lang = null as any;
  featureFlagsValues = null;

  constructor(private translate: IanTranslateService, private devSettingsService: DevSettingsService, public dialog: MatDialog) {
    this.setLangs();
    this.setFeatureFlags();
  }

  async ngOnInit() {}

  setLangs() {
    if (this.lang != null) return;

    this.lang = {
      SAVE: this.translate.instant('GENERIC_SAVE'),
      CLEAN: this.translate.instant('GENERIC_CANCEL'),
      RESET: this.translate.instant('GENERIC_RESET_ALT'),
    };
  }

  setFeatureFlags() {
    if (this.devSettingsService == null) return;

    const featureFlags = this.devSettingsService.__getAllFeatureFlagsValues();
    const featureFlags_EnumKeysLocal = this.devSettingsService.__getAllFeatureFlagsLocal();
    const featureFlags_AWS_object = this.devSettingsService.__getAllFeatureFlagsValues_AWS_object();

    //Hace un merge entre las FF de AWS y las locales
    const featureFlagsKeys = _uniqueElements([...Object.keys(featureFlags), ...featureFlags_EnumKeysLocal]);

    if (!(featureFlagsKeys.length > 0)) return;

    this.featureFlagsValues = [];

    featureFlagsKeys.forEach(key => {
      let isDafaultTrue: Boolean = !!this.devSettingsService.__getDefaultValues()[key] === true;
      let featureFlag_AWS_metaData = featureFlags_AWS_object[key]; //CORE-24

      //filtra los FF que por defualt están en true para los noDevs
      let isDafaultTrueFilteredForNoDevs: Boolean = isDafaultTrue && _isDev() === false && false;

      let cardId: string = key.split('__')[0].toUpperCase();
      let description: string = key.replace(key.split('__')[0], '').replaceAll('_', ' ').replaceAll('-', ' ').toLowerCase();
      let defaultValue: Boolean = Boolean(this.devSettingsService.__getDefaultValues()[key]);

      //Es editable si vino de AWS con editable en true o para los devs si la FF figura en el enum
      let editable: Boolean = featureFlag_AWS_metaData?.editable || (_isDev() && featureFlags_EnumKeysLocal.includes(key));

      cardId = normaliceFFkey(key.split('__')[1].toUpperCase());

      let idKey = key;

      let isInEnum = FeatureFlagsKeysEnum[idKey] != null;

      description = (featureFlag_AWS_metaData?.description || key).replaceAll('_', ' ').replaceAll('-', ' ').toLowerCase();

      if (description[0] != '[') {
        if (isInEnum) {
          description = '[fe] ' + description;
        } else {
          description = '[be] ' + description;
        }
      }

      description = description.replace('[fe]', '[FE]').replace('[be]', '[BE]');

      if (key.length > 0 && !isDafaultTrueFilteredForNoDevs) {
        this.featureFlagsValues.push({
          key: key,
          cardId: cardId,
          description: description,
          value: Boolean(featureFlags[key]),
          userValue: Boolean(featureFlags[key]),
          defaultValue: Boolean(defaultValue),
          editable: Boolean(editable),
        });
      }
    });

    //SORT
    this.featureFlagsValues = _orderBy(this.featureFlagsValues, ['editable', 'cardId'], ['desc', 'asc']);
  }

  get hasFlagChanges() {
    if (this.devSettingsService == null) return false;

    return this.featureFlagsValues?.length > 0 && this.featureFlagsValues.some(e => e.value !== e.userValue);
  }

  get hasFlagChangesInLocalStorage() {
    if (this.devSettingsService == null) return false;

    return this.devSettingsService.__hasLocalStorage();
  }

  async saveFlagChanges() {
    const rv = {};

    this.featureFlagsValues.forEach(obj => {
      rv[obj.key] = obj.userValue;
    });

    let confirm = await this.promptConfirmation();
    if (!confirm) return;

    this.devSettingsService?.__setFeatureFlagsUserValues?.(rv, true);
  }

  canceFlagChanges() {
    this.setFeatureFlags();
  }

  async resetFlagChanges() {
    let confirm = await this.promptConfirmation();
    if (!confirm) return;

    this.devSettingsService?.__resetFeatureFlagsUserValues?.(true);
  }

  oepenUrl(des) {
    (window as any).open(environment.atlassianJiraUrl + des, '_blank');
  }

  copyKey(key) {
    (navigator as any).clipboard?.writeText?.(key);
  }

  async promptConfirmation() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '550px',
      data: {
        message: 'Para cambiar este seteo hace falta reiniciar la aplicación',
        title: 'Aviso',
        labelConfirm: 'Reiniciar',
        labelCancel: 'Cancelar',
        disableClose: true,
        timeToConfirm: 720,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }
}
