export interface SelectTreeModelGen {
  id: number;
  name: string;
  level: number;
  parentId: number | null;
  items: Array<SelectTreeModelGen>;
}
export interface PaymentMethodsSelectTree extends SelectTreeModelGen {
  binCode: string | number;
  _parentId?: string | number;
  parentBinCode?: string | number;
  items: Array<PaymentMethodsSelectTree>;
}

export const SELECT_TREE_DUMMY_DATA_BANKS: PaymentMethodsSelectTree[] = [
  {
    id: 1,
    name: 'MASTERCARD',
    level: 3,
    parentId: null,
    binCode: null,
    items: [
      {
        id: 2,
        name: 'SCOTIABANK',
        level: 4,
        parentId: 1,
        binCode: null,
        items: [
          {
            id: 6,
            name: 'TODOS',
            level: 5,
            parentId: 2,
            binCode: '12345678',
            items: [],
          },
          {
            id: 7,
            name: 'Debito',
            level: 5,
            parentId: 2,
            binCode: '12345679',
            items: [],
          },
          {
            id: 5,
            name: 'Credito',
            level: 5,
            parentId: 2,
            binCode: '12345677',
            items: [],
          },
        ],
      },
      {
        id: 23,
        name: 'ASDASD',
        level: 4,
        parentId: 1,
        binCode: null,
        items: [
          {
            id: 3,
            name: 'TODOS',
            level: 5,
            parentId: 23,
            binCode: '22345678',
            items: [],
          },
          {
            id: 4,
            name: 'Debito',
            level: 5,
            parentId: 23,
            binCode: '22345679',
            items: [],
          },
          {
            id: 5,
            name: 'Credito',
            level: 5,
            parentId: 23,
            binCode: '22345677',
            items: [],
          },
        ],
      },
    ],
  },
  {
    id: 10,
    name: 'VISA',
    level: 3,
    parentId: null,
    binCode: null,
    items: [
      {
        id: 11,
        name: 'BANCOLOMBIA',
        level: 4,
        parentId: 10,
        binCode: null,
        items: [
          {
            id: 14,
            name: 'TODOS',
            level: 5,
            parentId: 11,
            binCode: '32345678',
            items: [],
          },
          {
            id: 15,
            name: 'Debito',
            level: 5,
            parentId: 11,
            binCode: '32345679',
            items: [],
          },
          {
            id: 16,
            name: 'Credito',
            level: 5,
            parentId: 11,
            binCode: '32345677',
            items: [],
          },
        ],
      },
      {
        id: 24,
        name: 'BBVA',
        level: 4,
        parentId: 10,
        binCode: null,
        items: [
          {
            id: 17,
            name: 'TODOS',
            level: 5,
            parentId: 24,
            binCode: '42345678',
            items: [],
          },
          {
            id: 18,
            name: 'Debito',
            level: 5,
            parentId: 24,
            binCode: '42345679',
            items: [],
          },
          {
            id: 19,
            name: 'Credito',
            level: 5,
            parentId: 24,
            binCode: '42345677',
            items: [],
          },
        ],
      },
    ],
  },
];
export const SELECT_TREE_DUMMY_DATA = [
  {
    name: 'NO CATEGORY',
    id: 999997,
    parentId: null,
    level: 3,
    items: [
      {
        name: 'NO CATEGORY',
        id: 999998,
        parentId: 999997,
        level: 4,
        items: [
          {
            name: 'NO CATEGORY',
            id: 999999,
            parentId: 999998,
            level: 5,
            items: [],
          },
        ],
      },
    ],
  },
  {
    name: 'Fast Food Bebidas',
    id: 3,
    parentId: null,
    level: 3,
    items: [
      {
        name: 'Aguas frescas',
        id: 239,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Aguas frescas. MProv',
            id: 278,
            parentId: 239,
            level: 5,
            items: [],
          },
          {
            name: 'Aguas frescas. MP',
            id: 395,
            parentId: 239,
            level: 5,
            items: [],
          },
          {
            name: 'Aguas frescas.Insum',
            id: 444,
            parentId: 239,
            level: 5,
            items: [],
          },
          {
            name: 'Aguas frescas.Insum',
            id: 1042,
            parentId: 239,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Insumos Bebidas',
        id: 251,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Insumos Beb. MP',
            id: 279,
            parentId: 251,
            level: 5,
            items: [],
          },
          {
            name: 'Insumos Beb. MProv',
            id: 1017,
            parentId: 251,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'BARRA ESPRESSO',
        id: 138,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'AMERICANO',
            id: 284,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Capuchino',
            id: 353,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Regalo',
            id: 398,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Promocionales',
            id: 400,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Comida',
            id: 422,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Extra',
            id: 446,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'CAPUCHINO CALIENTE',
            id: 458,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Espresso',
            id: 470,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Recarga',
            id: 539,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Nieve',
            id: 557,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Grano',
            id: 593,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Frappe',
            id: 627,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Capuchino',
            id: 631,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Malteada',
            id: 652,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'CHOCOLATE',
            id: 697,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'MALTEADA',
            id: 761,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Panera Barra',
            id: 783,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'CAPUCHINO FRIO',
            id: 825,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'CAFÃ ESPRESSO',
            id: 882,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'EXTRAS',
            id: 898,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'NIEVE',
            id: 920,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Tisana',
            id: 977,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Jugo Natural',
            id: 996,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'LATTE',
            id: 1002,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'Reposteria',
            id: 1021,
            parentId: 138,
            level: 5,
            items: [],
          },
          {
            name: 'FRAPPE',
            id: 1026,
            parentId: 138,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Americano',
        id: 203,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'AmericanoMProv.Insum',
            id: 297,
            parentId: 203,
            level: 5,
            items: [],
          },
          {
            name: 'Americano frios',
            id: 319,
            parentId: 203,
            level: 5,
            items: [],
          },
          {
            name: 'AmericanoMProv.Insum',
            id: 611,
            parentId: 203,
            level: 5,
            items: [],
          },
          {
            name: 'Americano. MProv',
            id: 757,
            parentId: 203,
            level: 5,
            items: [],
          },
          {
            name: 'AmericanoMP.Insum',
            id: 765,
            parentId: 203,
            level: 5,
            items: [],
          },
          {
            name: 'Americano andatti',
            id: 782,
            parentId: 203,
            level: 5,
            items: [],
          },
          {
            name: 'AmericanoMP.Insum',
            id: 983,
            parentId: 203,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Gourmet',
        id: 195,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'GOURMETÂ Espresso',
            id: 300,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'Franke AP Chocolate ',
            id: 331,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'GOURMETÂ Latte',
            id: 351,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'GOURMETÂ Lechero',
            id: 515,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'Franke AP Capuchino ',
            id: 634,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'Extras',
            id: 735,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'GOURMETÂ Capuchino',
            id: 756,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'GOURMETÂ Chocolate',
            id: 780,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'Franke AP Latte frio',
            id: 799,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'Franke AP Americano ',
            id: 818,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'GOURMETÂ  Nieve',
            id: 931,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'GOURMET Americano',
            id: 951,
            parentId: 195,
            level: 5,
            items: [],
          },
          {
            name: 'GOURMETÂ  Frappe',
            id: 1020,
            parentId: 195,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Refresco Maquina',
        id: 219,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Refresco maq. MProv',
            id: 314,
            parentId: 219,
            level: 5,
            items: [],
          },
          {
            name: 'Refresco Mprov.Insum',
            id: 667,
            parentId: 219,
            level: 5,
            items: [],
          },
          {
            name: 'Refresco maq. MP',
            id: 867,
            parentId: 219,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Preparados on Site',
        id: 101,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Licuados',
            id: 332,
            parentId: 101,
            level: 5,
            items: [],
          },
          {
            name: 'Smoothies',
            id: 716,
            parentId: 101,
            level: 5,
            items: [],
          },
          {
            name: 'Bebidas on Site',
            id: 816,
            parentId: 101,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Te frio',
        id: 241,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Te frio.Insum',
            id: 378,
            parentId: 241,
            level: 5,
            items: [],
          },
          {
            name: 'Te frio. MProv',
            id: 463,
            parentId: 241,
            level: 5,
            items: [],
          },
          {
            name: 'Te frio. MP',
            id: 624,
            parentId: 241,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Capuchino',
        id: 250,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Capuchinos frios',
            id: 384,
            parentId: 250,
            level: 5,
            items: [],
          },
          {
            name: 'CapuchinoMP.Insum',
            id: 629,
            parentId: 250,
            level: 5,
            items: [],
          },
          {
            name: 'Capuchino. MP (Col)',
            id: 776,
            parentId: 250,
            level: 5,
            items: [],
          },
          {
            name: 'Capuchino andatti',
            id: 777,
            parentId: 250,
            level: 5,
            items: [],
          },
          {
            name: 'CapuchinoMProv.Insum',
            id: 826,
            parentId: 250,
            level: 5,
            items: [],
          },
          {
            name: 'Capuchino Nescafe',
            id: 848,
            parentId: 250,
            level: 5,
            items: [],
          },
          {
            name: 'CapuchinoMProv.Insum',
            id: 884,
            parentId: 250,
            level: 5,
            items: [],
          },
          {
            name: 'CapuchinoMP.Insum',
            id: 1013,
            parentId: 250,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Frappe',
        id: 171,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'FrappeMP.Insum',
            id: 388,
            parentId: 171,
            level: 5,
            items: [],
          },
          {
            name: 'FrappeMProv.Insum',
            id: 591,
            parentId: 171,
            level: 5,
            items: [],
          },
          {
            name: 'Frappe. MProv',
            id: 622,
            parentId: 171,
            level: 5,
            items: [],
          },
          {
            name: 'FrappeMP.Insum',
            id: 650,
            parentId: 171,
            level: 5,
            items: [],
          },
          {
            name: 'FrappeMProv.Insum',
            id: 856,
            parentId: 171,
            level: 5,
            items: [],
          },
          {
            name: 'Frappe andatti',
            id: 1033,
            parentId: 171,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Te Caliente',
        id: 209,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Te caliente.Insum',
            id: 402,
            parentId: 209,
            level: 5,
            items: [],
          },
          {
            name: 'Te caliente. MP',
            id: 562,
            parentId: 209,
            level: 5,
            items: [],
          },
          {
            name: 'Te caliente. MProv',
            id: 927,
            parentId: 209,
            level: 5,
            items: [],
          },
          {
            name: 'Te caliente.Insum',
            id: 944,
            parentId: 209,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Malteada',
        id: 264,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Malteada. MProv',
            id: 493,
            parentId: 264,
            level: 5,
            items: [],
          },
          {
            name: 'Malteada. MP',
            id: 696,
            parentId: 264,
            level: 5,
            items: [],
          },
          {
            name: 'Malteada.Insum',
            id: 715,
            parentId: 264,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Capuchino andatti es',
        id: 136,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Capuchino Kobalto',
            id: 589,
            parentId: 136,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Nieve',
        id: 154,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Nieve',
            id: 669,
            parentId: 154,
            level: 5,
            items: [],
          },
          {
            name: 'Nieve',
            id: 1043,
            parentId: 154,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Atole',
        id: 265,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Atole. MP',
            id: 699,
            parentId: 265,
            level: 5,
            items: [],
          },
          {
            name: 'Atole. MProv',
            id: 831,
            parentId: 265,
            level: 5,
            items: [],
          },
          {
            name: 'Atole.Insum',
            id: 972,
            parentId: 265,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Jugos',
        id: 121,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Jugos MP',
            id: 733,
            parentId: 121,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Multibebida FF',
        id: 259,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'Multibebida Andatti',
            id: 928,
            parentId: 259,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Andatti espresso',
        id: 95,
        parentId: 3,
        level: 4,
        items: [
          {
            name: 'capuchino',
            id: 1050,
            parentId: 95,
            level: 5,
            items: [],
          },
        ],
      },
    ],
  },
  {
    name: 'Servicios',
    id: 11,
    parentId: null,
    level: 3,
    items: [
      {
        name: 'Servicio Financiero',
        id: 135,
        parentId: 11,
        level: 4,
        items: [
          {
            name: 'Envio de dinero',
            id: 280,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Monedero Electronico',
            id: 336,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Tarjetas',
            id: 357,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Seguros',
            id: 360,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Remesas',
            id: 441,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Gift cards',
            id: 540,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'IngresoFueradeTienda',
            id: 551,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Retiros',
            id: 566,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'SPIN',
            id: 592,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Productos',
            id: 623,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Saldazo',
            id: 743,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Gift cards SKU',
            id: 874,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Cajeros Automaticos',
            id: 894,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Seguros SKU',
            id: 908,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Corresponsalias',
            id: 934,
            parentId: 135,
            level: 5,
            items: [],
          },
          {
            name: 'Medios de Pago',
            id: 953,
            parentId: 135,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Servicios prepago',
        id: 123,
        parentId: 11,
        level: 4,
        items: [
          {
            name: 'Transporte',
            id: 342,
            parentId: 123,
            level: 5,
            items: [],
          },
          {
            name: 'Servicios Publicos',
            id: 769,
            parentId: 123,
            level: 5,
            items: [],
          },
          {
            name: 'Apps',
            id: 911,
            parentId: 123,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Soluciones en tienda',
        id: 210,
        parentId: 11,
        level: 4,
        items: [
          {
            name: 'Internet',
            id: 391,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Videocajeros',
            id: 394,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Puntos de entrega',
            id: 474,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Servicios en Tienda',
            id: 498,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Gift cards SKU',
            id: 646,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Cajeros Automaticos',
            id: 676,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Gift cards',
            id: 680,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Casetas telefonicas',
            id: 828,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Kiosco',
            id: 853,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Loteria en Linea',
            id: 906,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Venta de Boletos',
            id: 982,
            parentId: 210,
            level: 5,
            items: [],
          },
          {
            name: 'Espacio de terceros',
            id: 1014,
            parentId: 210,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Recepcion de Pagos',
        id: 216,
        parentId: 11,
        level: 4,
        items: [
          {
            name: 'Nacional',
            id: 602,
            parentId: 216,
            level: 5,
            items: [],
          },
          {
            name: 'Local',
            id: 785,
            parentId: 216,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Puntos de Entrega',
        id: 200,
        parentId: 11,
        level: 4,
        items: [
          {
            name: 'Recepcion de paquete',
            id: 747,
            parentId: 200,
            level: 5,
            items: [],
          },
        ],
      },
      {
        name: 'Comercio Electronico',
        id: 229,
        parentId: 11,
        level: 4,
        items: [
          {
            name: 'Paqueteria',
            id: 854,
            parentId: 229,
            level: 5,
            items: [],
          },
          {
            name: 'Pagos',
            id: 901,
            parentId: 229,
            level: 5,
            items: [],
          },
          {
            name: 'Gift cards',
            id: 930,
            parentId: 229,
            level: 5,
            items: [],
          },
        ],
      },
    ],
  },
];

export const PAYMENT_METHODS_DUMMY_DATA: PaymentMethodsSelectTree[] = [
  {
    id: 12,
    name: 'TODOS',
    level: 1,
    parentId: null,
    binCode: null,
    items: [
      {
        id: 229,
        name: 'COPAYMENT DE MEXICO',
        level: 2,
        parentId: 12,
        binCode: null,
        items: [{ id: 3, name: 'All', level: 3, parentId: 229, binCode: '50023200', items: null }],
      },
      {
        id: 234,
        name: 'SABADELL',
        level: 2,
        parentId: 12,
        binCode: null,
        items: [{ id: 3, name: 'All', level: 3, parentId: 234, binCode: '50023100', items: null }],
      },
      {
        id: 193,
        name: 'CR DITOS SCOTIABANK',
        level: 2,
        parentId: 12,
        binCode: null,
        items: [{ id: 3, name: 'All', level: 3, parentId: 193, binCode: '50023000', items: null }],
      },
      {
        id: 184,
        name: 'KUBO FINANCIERO',
        level: 2,
        parentId: 12,
        binCode: null,
        items: [{ id: 3, name: 'All', level: 3, parentId: 184, binCode: '50022900', items: null }],
      },
    ],
  },
  {
    id: 13,
    name: 'VISA',
    level: 1,
    parentId: null,
    binCode: null,
    items: [
      {
        id: 330,
        name: 'PRESTAMOS ABCD',
        level: 2,
        parentId: 13,
        binCode: null,
        items: [
          { id: 3, name: 'All', level: 3, parentId: 330, binCode: '50105200', items: null },
          { id: 1, name: 'Credit', level: 3, parentId: 330, binCode: '50105201', items: null },
        ],
      },
      {
        id: 232,
        name: 'SANTANDER CONSUMO',
        level: 2,
        parentId: 13,
        binCode: null,
        items: [
          { id: 3, name: 'All', level: 3, parentId: 232, binCode: '50123400', items: null },
          { id: 1, name: 'Credit', level: 3, parentId: 232, binCode: '50123401', items: null },
        ],
      },
      {
        id: 295,
        name: 'CACAO PAYCARD',
        level: 2,
        parentId: 13,
        binCode: null,
        items: [
          { id: 1, name: 'Credit', level: 3, parentId: 295, binCode: '50123301', items: null },
          { id: 3, name: 'All', level: 3, parentId: 295, binCode: '50123300', items: null },
        ],
      },
      {
        id: 334,
        name: 'CONSUBANCO',
        level: 2,
        parentId: 13,
        binCode: null,
        items: [
          { id: 0, name: 'Debit', level: 3, parentId: 334, binCode: '50123502', items: null },
          { id: 3, name: 'All', level: 3, parentId: 334, binCode: '50123500', items: null },
        ],
      },
    ],
  },
];
