import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { _isDev, _isFeDev, _log, _logTap, _useDummyData } from '@shared/aux_helper_environment';
import { _cloneDeep, _equal, _get, _handleErrorWithDummyData, _noVal, _objToHash, _throwError } from '@shared/aux_helper_functions';
import { PagedList } from 'core/models/paged-list.model';
import { isObservable, Observable, of } from 'rxjs';
import { tap, catchError, map, shareReplay } from 'rxjs/operators';
import { IMV2ListModel, IMV2RowModel } from './stores/IMV2_list.model';
import { IMV2ListDummyData } from './IMV2_list.dummyData';
import { MAX_PAGE_SIZE } from '@prisma/models/defined-data';
import {
  GenericListPayloadFE,
  _mapPayloadClipBoard,
} from '@prisma/components/general-clipboard-list-modal/general-clipboard-list-modal.component';
import { GenericCategorySelectionMiniV2Service } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.service';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';

const getIMV2ListFromBE_Map = (list: PagedList<IMV2ListModel>): PagedList<IMV2RowModel> => {
  const mappedItems = list.items.map(IMV2 => {
    return {
      ...IMV2,
      subcategory: IMV2.categoryName,
      type: IMV2.discriminator,
      category: IMV2.parentCategoryName,
      itemCreatedOn: new Date(IMV2.itemCreatedOn),
    };
  });
  return { ...list, items: mappedItems };
};

@Injectable({
  providedIn: 'root',
})
export class IMV2_list_service {
  private configBase = _get(environment, 'apiBaseUrl_prices');
  private config = _get(environment, 'itemmasterv2.api');

  constructor(
    private http: HttpClient,
    private prismaDynamicEnv: PrismaDynamicEnv,
    private serviceCategory: GenericCategorySelectionMiniV2Service
  ) {}

  getList(filters): Observable<PagedList<IMV2RowModel>> {
    const qs: Array<string> = ['?'];

    if (filters.pageIndex) {
      qs.push(`pageIndex=${filters.pageIndex}`);
    }

    if (filters.pageSize) {
      qs.push(`pageSize=${filters.pageSize}`);
    }

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filters?.categories || [],
      level5: filters?.subcategory || [],
    });

    let url = `${this.configBase}${this.config.resources.getList}${qs.join('&')}`;

    return this.http
      .post<PagedList<IMV2ListModel>>(url, { ...filters, categories: lowLevel?.level4 || [], subcategory: lowLevel?.level5 || [] })
      .pipe(
        map(data => getIMV2ListFromBE_Map(data)),
        tap(data => _logTap(`${IMV2_list_service.name}::getList (tap)\n\tdata: %o`, data)),
        catchError(error =>
          _handleErrorWithDummyData(
            true && _useDummyData(),
            error,
            IMV2_list_service,
            getIMV2ListFromBE_Map(IMV2ListDummyData.IMV2ListWithoutMap),
            'IMV2ListDummyData.tagsList'
          )
        )
      );
  }

  undelete(data): Observable<any> {
    const { filter, ...rest } = _cloneDeep(data);

    const url = this.configBase + this.config.resources.undelete;

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filter?.categories || [],
      level5: filter?.subcategory || [],
    });

    const itemsIds = (rest.itemsIds || []).filter(el => el != null && String(el) == el && String(el).length > 0);

    let payLoad = { ...rest, filter: { ...filter, categories: lowLevel?.level4 || [], subcategory: lowLevel?.level5 || [] }, itemsIds };

    if (false && _isDev()) {
      _log(payLoad, itemsIds, rest.itemsIds);
      return of(null);
    }

    return this.http.post<any>(url, payLoad).pipe(
      tap(data => _logTap(`${IMV2_list_service.name}::undelete (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, IMV2_list_service))
    );
  }

  delete(data): Observable<any> {
    const { filter, ...rest } = _cloneDeep(data);

    const url = this.configBase + this.config.resources.delete;

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filter?.categories || [],
      level5: filter?.subcategory || [],
    });

    const itemsIds = (rest.itemsIds || []).filter(el => el != null && String(el) == el && String(el).length > 0);

    let payLoad = { ...rest, filter: { ...filter, categories: lowLevel?.level4 || [], subcategory: lowLevel?.level5 || [] }, itemsIds };

    if (false && _isDev()) {
      _log(payLoad, itemsIds, rest.itemsIds);
      return of(null);
    }

    return this.http.post<any>(url, payLoad).pipe(
      tap(data => _logTap(`${IMV2_list_service.name}::delete (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, IMV2_list_service))
    );
  }

  massivePublish(data): Observable<any> {
    const { filter, ...rest } = _cloneDeep(data);

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filter?.categories || [],
      level5: filter?.subcategory || [],
    });

    const itemsIds = (rest.itemsIds || []).filter(el => el != null && String(el) == el && String(el).length > 0);

    let payLoad = { ...rest, filter: { ...filter, categories: lowLevel?.level4 || [], subcategory: lowLevel?.level5 || [] }, itemsIds };

    if (false && _isDev()) {
      _log(payLoad, itemsIds, rest.itemsIds);
      return of(null);
    }

    const url = this.configBase + this.config.resources.massivePublish;

    return this.http
      .post<any>(url, { ...rest, filter: { ...filter, categories: lowLevel?.level4 || [], subcategory: lowLevel?.level5 || [] } })
      .pipe(
        tap(data => _logTap(`${IMV2_list_service.name}::massivePublish (tap)\n\tdata: %o`, data)),
        catchError(error => _throwError(error, IMV2_list_service))
      );
  }

  clone(data): Observable<any> {
    const url = this.configBase + this.config.resources.clone;
    return this.http.post<any>(url, data).pipe(
      tap(data => _logTap(`${IMV2_list_service.name}::clone (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, IMV2_list_service))
    );
  }

  cacheItemList: any = {};
  /** Get Items by id / internal code / external code */
  getItemsIdByCode(payload: GenericListPayloadFE): Observable<PagedList<any>> {
    let filterHash = payload ? _objToHash(payload) : 'NULL';
    /*Si tiene cache lo devuelve y no va a BE*/
    if (this.cacheItemList[filterHash]) {
      return isObservable(this.cacheItemList[filterHash]) ? this.cacheItemList[filterHash] : of(this.cacheItemList[filterHash]);
    }

    const _maxSize = MAX_PAGE_SIZE;
    // remplazado getGenericItemList por getGenericItemListV2 para devolver nada si no tiene filtros
    const url = `${this.configBase}${this.config.resources.getGenericItemListV2}?pageSize=${_maxSize}`;
    const _data = _mapPayloadClipBoard(payload);

    let rv = this.http.post<PagedList<any>>(url, _data).pipe(
      tap(data => _logTap(`${IMV2_list_service.name}::getIdsByInternalCodeProduct (tap)\n\tdata: %o`, data)),
      catchError(error =>
        _handleErrorWithDummyData(
          true && _useDummyData(),
          error,
          IMV2_list_service,
          [1623, 1624, 1365, 1167],
          '[1623, 1624, 1365, 1167]',
          []
        )
      )
    );

    let cacheReplay = rv.pipe(shareReplay());

    this.cacheItemList[filterHash] = cacheReplay;

    return cacheReplay;
  }

  resetItemsIdByCode() {
    this.cacheItemList = {};
  }

  isReadOnly(): boolean {
    return this.prismaDynamicEnv.getConf('brandCustomization.itemMaster.isReadOnly') || false;
  }

  hasRestrictItemsTypes(): boolean {
    return this.prismaDynamicEnv.getConf('brandCustomization.itemMaster.hasRestrictItemsTypes') || false;
  }

  restrictedItemsTypes(): string[] {
    if (!this.hasRestrictItemsTypes()) return null;
    return this.prismaDynamicEnv.getConf('brandCustomization.itemMaster.restrictedItemsTypes');
  }
}
