import { _log } from '@shared/aux_helper_environment';
import {
  ActionsV2_FormModel_actionPaymentMethodTypeEnum,
  ActionsV2_FormModel_bank,
  ActionsV2_FormModel_paymentMethod,
  ActionsV2_FormModel_paymentMethodsCombinations,
  ActionsV2_ValidationStatus,
  ActionsV2_validationObject,
  TicketMessageValidationRulesDTO,
  ValidationStatus,
} from './actions/actions-edit/store/actionsV2-FormStateModel';
import { dummyPeriodsMockup } from './periods/periods-list/calendar-list.dummyData';

const periodByPromotionType = id => {
  return id !== 1 ? generarFechasAleatoriasParaSetDeDatos(id) : [];
  return dummyPeriodsMockup.forcedPeriod;
};

function generarFechasAleatoriasParaSetDeDatos(cantidadRegistros) {
  _log('<generarFechasAleatoriasParaSetDeDatos>');

  const registros = [];

  const fechas = generarFechasAleatorias(cantidadRegistros);

  for (let i = 1; i < cantidadRegistros; i++) {
    const fecha = fechas[i];
    const date = {
      id: i,
      code: 'P' + i,
      name: 'Periodo ' + i,
      startingWeekDays: 4,
      durationInDays: 35,
      promotionType: 1,
      validFrom: fecha.setDate(fecha.getDate() - 35),
      validTo: fecha,
      order: i,
      extraPlanningDays: 0,
      isSpecialEvent: false,
    };
    registros.push(date);
  }

  return registros;
}

function generarFechasAleatorias(cantidadRegistros) {
  _log('<generarFechasAleatorias>');

  const registros = [];
  const fechaInicio = new Date('2023-01-01');
  const fechaFin = new Date('2023-12-31');

  for (let i = 0; i < cantidadRegistros; i++) {
    const tiempoInicio = fechaInicio.getTime();
    const tiempoFin = fechaFin.getTime();
    const tiempoAleatorio = Math.random() * (tiempoFin - tiempoInicio) + tiempoInicio;
    const fechaAleatoria = new Date(tiempoAleatorio);
    registros.push(fechaAleatoria);
  }

  return registros;
}

const promotionTypeList = () => {
  _log('<promotionTypeList>');

  /*   return [
    { id: 1, code: 'NAC', name: 'Nacionales', minimumCoverage: 0.85 },
    { id: 2, code: 'CERV', name: 'Cerveza', minimumCoverage: 0.7 },
    { id: 3, code: 'CV', name: 'Corta Vigencia', minimumCoverage: 0.7 },
    { id: 4, code: 'LE', name: 'Lealtad', minimumCoverage: 0.7 },
    { id: 5, code: 'LOC', name: 'Locales', minimumCoverage: 0.7 },
    { id: 6, code: 'REAC', name: 'De reacción', minimumCoverage: 0.7 },
  ]; */
  return [
    {
      code: 'NAC',
      name: 'Nacionales',
      planningDays: 0,
      minimumCoverage: 0.85,
      customFields: [
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Tipo de Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '$ por paquete vendido al cliente|$ por unidad vendida al cliente',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Valor de la Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'RFC del Proveedor',
          type: 0,
          regex: '/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([A-Z\\d]{2})([A\\d])$/',
          validationHint: 'El formato del RFC tiene que ser: XAXX010101000',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Receso',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Hogar',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Base',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
      ],
      createdBy: 'PRISMA',
      createdOn: '2023-04-12T16:16:12.502653+00:00',
      updatedBy: 'PRISMA',
      updatedOn: '2023-04-12T16:16:12.502653+00:00',
      id: 1,
      isDeleted: false,
      domainEvents: null,
    },
    {
      code: 'CERV',
      name: 'Cerveza',
      planningDays: 0,
      minimumCoverage: 0.7,
      customFields: [
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Tipo de Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '$ por paquete vendido al cliente|$ por unidad vendida al cliente',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Valor de la Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'RFC del Proveedor',
          type: 0,
          regex: '/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([A-Z\\d]{2})([A\\d])$/',
          validationHint: 'El formato del RFC tiene que ser: XAXX010101000',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Receso',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Hogar',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Base',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
      ],
      createdBy: 'PRISMA',
      createdOn: '2023-04-12T16:16:12.502653+00:00',
      updatedBy: 'PRISMA',
      updatedOn: '2023-04-12T16:16:12.502653+00:00',
      id: 2,
      isDeleted: false,
      domainEvents: null,
    },
    {
      code: 'CV',
      name: 'Corta Vigencia',
      planningDays: 0,
      minimumCoverage: 0.7,
      customFields: [
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Tipo de Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '$ por paquete vendido al cliente|$ por unidad vendida al cliente',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Valor de la Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'RFC del Proveedor',
          type: 0,
          regex: '/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([A-Z\\d]{2})([A\\d])$/',
          validationHint: 'El formato del RFC tiene que ser: XAXX010101000',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Receso',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Hogar',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Base',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
      ],
      createdBy: 'PRISMA',
      createdOn: '2023-04-12T16:16:12.502653+00:00',
      updatedBy: 'PRISMA',
      updatedOn: '2023-04-12T16:16:12.502653+00:00',
      id: 3,
      isDeleted: false,
      domainEvents: null,
    },
    {
      code: 'LE',
      name: 'Lealtad',
      planningDays: 0,
      minimumCoverage: 0.7,
      customFields: [
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Tipo de Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '$ por paquete vendido al cliente|$ por unidad vendida al cliente',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Valor de la Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'RFC del Proveedor',
          type: 0,
          regex: '/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([A-Z\\d]{2})([A\\d])$/',
          validationHint: 'El formato del RFC tiene que ser: XAXX010101000',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Receso',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Hogar',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Base',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
      ],
      createdBy: 'PRISMA',
      createdOn: '2023-04-12T16:16:12.502653+00:00',
      updatedBy: 'PRISMA',
      updatedOn: '2023-04-12T16:16:12.502653+00:00',
      id: 4,
      isDeleted: false,
      domainEvents: null,
    },
    {
      code: 'LOC',
      name: 'Locales',
      planningDays: 0,
      minimumCoverage: 0.7,
      customFields: [
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Tipo de Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '$ por paquete vendido al cliente|$ por unidad vendida al cliente',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Valor de la Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'RFC del Proveedor',
          type: 0,
          regex: '/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([A-Z\\d]{2})([A\\d])$/',
          validationHint: 'El formato del RFC tiene que ser: XAXX010101000',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Receso',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Hogar',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Base',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
      ],
      createdBy: 'PRISMA',
      createdOn: '2023-04-12T16:16:12.502653+00:00',
      updatedBy: 'PRISMA',
      updatedOn: '2023-04-12T16:16:12.502653+00:00',
      id: 5,
      isDeleted: false,
      domainEvents: null,
    },
    {
      code: 'REAC',
      name: 'De reacción',
      planningDays: 0,
      minimumCoverage: 0.7,
      customFields: [
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Tipo de Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '$ por paquete vendido al cliente|$ por unidad vendida al cliente',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'Valor de la Bonificación',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Aportación del Proveedor a la Promoción',
          name: 'RFC del Proveedor',
          type: 0,
          regex: '/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([A-Z\\d]{2})([A\\d])$/',
          validationHint: 'El formato del RFC tiene que ser: XAXX010101000',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Receso',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Hogar',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
        {
          groupName: 'Cadena de Suministro',
          name: 'Cargo a tienda en Plazas - Base',
          type: 0,
          regex: '',
          validationHint: '',
          isMandatory: false,
          listValues: '',
        },
      ],
      createdBy: 'PRISMA',
      createdOn: '2023-04-12T16:16:12.502653+00:00',
      updatedBy: 'PRISMA',
      updatedOn: '2023-04-12T16:16:12.502653+00:00',
      id: 6,
      isDeleted: false,
      domainEvents: null,
    },
  ];
};

const getPromotionTypeById = id => {
  _log('<getPromotionTypeById>');
  return promotionTypeList().find(promo => promo.id === id);
};

const getStartingWeekDays = [
  { id: 1, name: 'Lunes' },
  { id: 2, name: 'Martes' },
  { id: 3, name: 'Miercoles' },
  { id: 4, name: 'Jueves' },
  { id: 5, name: 'Viernes' },
  { id: 6, name: 'Sabado' },
  { id: 7, name: 'Domingo' },
];

const taskList = promotionType => {
  _log('<taskList>');
  return [
    {
      id: 1,
      responsibleRoleId: 'AC',
      consultedRoleId: 'Promos',
      name: 'Inicio de captura de promociones Nacionales en Sistema Cuanti',
      observations: '',
      startTaskXDaysBefore: 56,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 2,
      responsibleRoleId: 'AC',
      consultedRoleId: 'Variedad',
      name: "Fecha límite para altas de artículos por SIV y extensiones de cobertura de FA's",
      observations: '',
      startTaskXDaysBefore: 45,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 3,
      responsibleRoleId: 'AC',
      consultedRoleId: 'Promos',
      name: 'Fecha límite para capturar promociones Nacionales en Sistema Cuanti',
      observations: '',
      startTaskXDaysBefore: 43,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 4,
      responsibleRoleId: 'MKT OS',
      consultedRoleId: 'Promos',
      name: 'Publicación de Concentrado de Promociones Nacionales (Pre-Promoxxo)',
      observations: '',
      startTaskXDaysBefore: 42,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 5,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Promos (Marco Camacho)',
      name: 'Último día de actualización de Inventario de Espacios',
      observations: '',
      startTaskXDaysBefore: 42,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 6,
      responsibleRoleId: 'AC',
      consultedRoleId: 'Variedad',
      name: 'Fecha límite para solicitar excepciones a "Regla + 1 sabor" para promociones accionables',
      observations: '',
      startTaskXDaysBefore: 41,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 7,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Local (Angie)',
      name: 'Inicio captura promociones Locales',
      observations: '',
      startTaskXDaysBefore: 38,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 8,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Promos',
      name: 'Fecha límite de validación de promociones nacionales (Pre-Promoxxo)',
      observations: '',
      startTaskXDaysBefore: 37,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 9,
      responsibleRoleId: 'AC',
      consultedRoleId: 'Promos',
      name: 'Fecha limite para solicitar modificación/cancelación de promociones proceso nacional',
      observations: '',
      startTaskXDaysBefore: 37,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 10,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Local (Angie)',
      name: 'Fecha límite para enviar promociones locales a MKT OS',
      observations: '',
      startTaskXDaysBefore: 35,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 11,
      responsibleRoleId: 'AC',
      consultedRoleId: 'Variedad',
      name: 'Fecha limite para retro / accionables de cobertura en planogramas',
      observations: '',
      startTaskXDaysBefore: 35,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 12,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Cadena de Suministro',
      name: 'Fecha límite para cargos de promociones locales en portal de promociones.',
      observations: '',
      startTaskXDaysBefore: 34,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 13,
      responsibleRoleId: 'AC',
      consultedRoleId: 'promos',
      name: 'Fecha límite para Vo.Bo. De diseños POP por categoría (promos nacionales)',
      observations: '',
      startTaskXDaysBefore: 31,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 14,
      responsibleRoleId: 'AC',
      consultedRoleId: 'Local (Angie)',
      name: 'Fecha límite de validación de promociones locales',
      observations: '',
      startTaskXDaysBefore: 30,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 15,
      responsibleRoleId: 'CAT OS',
      consultedRoleId: 'Cat',
      name: 'Inicio de configuración de promociones nacionales',
      observations: '',
      startTaskXDaysBefore: 24,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 16,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Local (Angie)',
      name: 'Fecha límite para Vo.Bo. De diseños POP por plazas (Promociones Locales)',
      observations: '',
      startTaskXDaysBefore: 23,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 17,
      responsibleRoleId: 'MKT OS',
      consultedRoleId: 'Promos',
      name: 'Fecha límite de publicación de PromOXXO',
      observations: '',
      startTaskXDaysBefore: 14,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 18,
      responsibleRoleId: 'MKT OS',
      consultedRoleId: 'Promos',
      name: 'Presentación de promOXXO a Plazas en Audioconferencia',
      observations: '',
      startTaskXDaysBefore: 10,
      promotionTypeId: 1,
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 19,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Plaza',
      name: 'Presentación de promOXXO Comisionistas',
      observations: '',
      startTaskXDaysBefore: '9',
      promotionTypeId: '1',
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 20,
      responsibleRoleId: 'CAT OS',
      consultedRoleId: 'Cat',
      name: 'Fecha límite de envío de reportes de promociones nacionales configuradas a plazas',
      observations: '',
      startTaskXDaysBefore: '8',
      promotionTypeId: '1',
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 21,
      responsibleRoleId: 'OS / PLAZA',
      consultedRoleId: 'promos',
      name: 'Recepción de Material POP en plaza',
      observations: '',
      startTaskXDaysBefore: '7',
      promotionTypeId: '1',
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 22,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Cat',
      name: 'Fecha límite de retroalimentación a catálogos OS de las promociones nacionales configuradas (11:00 am a más tardar)',
      observations: '',
      startTaskXDaysBefore: '3',
      promotionTypeId: '1',
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
    {
      id: 23,
      responsibleRoleId: 'PLAZA',
      consultedRoleId: 'Cat',
      name: 'Último día para validar si hay promociones que no pasan en POS  ---->(Día de prueba en tienda)',
      observations: '',
      startTaskXDaysBefore: '1',
      promotionTypeId: '1',
      durationInDays: '',
      periods: generarFechasAleatorias(13),
    },
  ];
};

export const dummyDataPromotionOxxo = {
  promotionTypeList,
  getPromotionTypeById,
  periodByPromotionType,
  taskList,
  getStartingWeekDays,
};

export const DUMMY_ACTIONV2_editItem = {
  discountRanges: [],
  assortmentMethod: 'ActivateOnlyDuringPromotion',
  salesChannelCodes: ['ECO', 'RAP'],
  loyaltyProgramCodes: ['TSC', 'TSC2'],
  minimumQuantity: '1',
  discountedUnits: '2',
  sameItem: true,
  minimumValue: '1',
  minimumTotalValue: null,
  discount: '1',
  discountType: 'AbsoluteValue',
  fixPrice: '100',
  shippingDiscount: 0.03,
  maximumDiscountValue: null,
  id: 1,
  name: 'Descuento Dummy Test',
  code: '123321123',
  externalCode: '123321123',
  type: 'Discount',
  message: 'Mesg',
  observation: 'Obs',
  timeFrom: '01:05:00',
  timeTo: '02:20:00',
  validFrom: '2021-12-09T03:00:00.000Z',
  validTo: '2021-12-16T03:00:00.000Z',
  frequency: 'EveryDay',
  frequencyDays: null,
  campaignId: null,
  status: 'Modified',
  paymentMethods: [
    {
      paymentMethodId: 1,
      paymentMethodName: null,
      bankId: null,
      bankName: null,
      sponsorship: null,
      installments: [
        {
          number: 1,
          interestRate: 0,
        },
        {
          number: 2,
          interestRate: 0,
        },
        {
          number: 6,
          interestRate: 0,
        },
      ],
      loyaltyProgramPoints: null,
      loyaltyProgramPointsEquivalenceInCash: null,
    },
    {
      paymentMethodId: 2,
      paymentMethodName: null,
      bankId: null,
      bankName: null,
      sponsorship: null,
      installments: null,
      loyaltyProgramPoints: null,
      loyaltyProgramPointsEquivalenceInCash: null,
    },
    {
      paymentMethodId: 4,
      paymentMethodName: null,
      bankId: null,
      bankName: null,
      sponsorship: null,
      installments: null,
      loyaltyProgramPoints: 111,
      loyaltyProgramPointsEquivalenceInCash: 111,
    },
  ],
  paymentMethodCombinations: [],
  itemScope: {
    categoryIdsToInclude: [785],
    categoryIdsToExclude: [],
    tagValueIdsToInclude: [2],
    tagValueIdsToExclude: [],
    itemIdsToExclude: [],
    itemIds: [2, 3, 4, 7],
  },
  storeScope: {
    tagValueIdsToInclude: [110],
    tagValueIdsToExclude: [],
    storeIdsToExclude: [],
    storeIds: [40, 41, 42],
  },
};

export const DUMMY_ActionsV2_paymentMethodCombinationsAxample: ActionsV2_FormModel_paymentMethodsCombinations[] = [
  {
    actionPaymentMethodCombination: [
      {
        type: ActionsV2_FormModel_actionPaymentMethodTypeEnum.Money,
        paymentMethodId: null,
        value: 0.0,
      },

      {
        type: ActionsV2_FormModel_actionPaymentMethodTypeEnum.Money,
        paymentMethodId: null,
        value: 0.0,
      },
    ],
  },
];

//api/salesandstock/payment-method/list
export const DUMMY_ActionsV2_paymentMethodsList: ActionsV2_FormModel_paymentMethod[] = [
  {
    id: 1,
    code: 'Visa Crédito',
    name: 'Visa Crédito',
    isCredit: true,
    isDebit: false,
    type: ActionsV2_FormModel_actionPaymentMethodTypeEnum.Money,
  },
  {
    id: 2,
    code: 'Efectivo',
    name: 'Efectivo',
    isCredit: false,
    isDebit: false,
    type: ActionsV2_FormModel_actionPaymentMethodTypeEnum.Money,
  },
  {
    id: 3,
    code: 'Visa Débito',
    name: 'Visa Débito',
    isCredit: false,
    isDebit: true,
    type: ActionsV2_FormModel_actionPaymentMethodTypeEnum.Money,
  },
  {
    id: 4,
    code: 'YPF SupreClub',
    name: 'YPF SupreClub',
    isCredit: false,
    isDebit: false,
    type: ActionsV2_FormModel_actionPaymentMethodTypeEnum.LoyaltyPoints,
  },
  ,
  {
    id: 5,
    code: 'Clarín 365',
    name: 'Clarín 365',
    isCredit: false,
    isDebit: false,
    type: ActionsV2_FormModel_actionPaymentMethodTypeEnum.LoyaltyPoints,
  },
];

//api/salesandstock/payment-method/list
export const DUMMY_ActionsV2_Banks: ActionsV2_FormModel_bank[] = [
  {
    id: 1,
    code: 'Banco Francés',
    name: 'Banco Francés',
  },
  {
    id: 2,
    code: 'Banco HSBC',
    name: 'Banco HSBC',
  },
  {
    id: 3,
    code: 'Banco Patagonia',
    name: 'Banco Patagonia',
  },
];

export const DUMMY_ActionsV2_promotionValidationList: ActionsV2_validationObject = {
  actionId: null,
  validationStatus: 'ActionRequired' as ActionsV2_ValidationStatus,
  validations: [
    {
      validationId: 6,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: true,
      roleId: null,
      status: ValidationStatus.passed,
      forcedApproval: null,
      failedCount: 1,
    },

    {
      validationId: 5,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: true,
      roleId: null,
      status: ValidationStatus.failed,
      forcedApproval: null,
      failedCount: 1,
    },
    //
    {
      validationId: 6,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: true,
      roleId: null,
      status: ValidationStatus.failed,
      forcedApproval: true,
      failedCount: 1,
    },

    {
      validationId: 5,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: true,
      roleId: null,
      status: ValidationStatus.failed,
      forcedApproval: false,
      failedCount: 1,
    },
    //
    {
      validationId: 1,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: false,
      roleId: null,
      status: ValidationStatus.failed,
      forcedApproval: true,
      failedCount: 1,
    },
    {
      validationId: 2,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: false,
      roleId: null,
      status: ValidationStatus.failed,
      forcedApproval: false,
      failedCount: 1,
    },
    {
      validationId: 3,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: false,
      roleId: null,
      status: ValidationStatus.failed,
      forcedApproval: null,
      failedCount: 1,
    },
    {
      validationId: 3,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: false,
      roleId: null,
      status: ValidationStatus.passed,
      forcedApproval: null,
      failedCount: 1,
    },
    //
    {
      validationId: 3,
      validationName: 'Alcance de Tiendas',
      validationDescription: 'Valida que el alcance de tiendas de la promocion no se exceda del alcance del espacio.',
      validationFailedMessage: 'Hay tiendas que no pertenecen a los espacios elegidos.',
      validationSuccessMessage: 'Se valido el alcance de tiendas.',
      isBlocker: null,
      roleId: null,
      status: ValidationStatus.failed,
      forcedApproval: null,
      failedCount: 1,
    },
  ],
};

export const FIXED_PRICE_DISCOUNT_DUMMY_DATA = {
  discount: 0.1,
  regularPrice: 100,
  loyaltyDiscount: 0.05,
  loyaltyRegularPrice: 100,
  exceptionalDiscounts: [
    {
      storeId: 11427,
      tagId: null,
      discount: 0.15,
      regularPrice: 100,
    },
    {
      storeId: null,
      tagId: 2,
      discount: 0.2,
      regularPrice: 100,
    },
  ],
};

export const TICKET_MESSAGE_RULES_DUMMY_DATA: TicketMessageValidationRulesDTO[] = [
  {
    id: 1,
    name: 'price',
    description: 'No se permiten precios en el mensaje.',
    regex: /(\$\d+(?:\.\d{1,2})?)|(\d+(?:\.\d{1,2})?\$)|(\$)/g,
    replaceFor: '',
    order: 1,
    isInputValidator: true,
  },

  {
    id: 2,
    name: 'specialCharacters',
    description: 'No se pueden usar caracteres especiales.',
    regex: /[^\w\s]/g,
    replaceFor: '',
    order: 3,
    isInputValidator: true,
  },
  {
    id: 3,
    name: 'doubleSpaces',
    description: 'No se permiten espacios dobles.',
    regex: /\s+/g,
    replaceFor: ' ',
    order: 4,
    isInputValidator: false,
  },
  {
    id: 4,
    name: 'maxCharacter',
    description: 'El máximo de caracteres es de 20.',
    regex: /^(.{20}).*$/,
    replaceFor: '$1',
    order: 5,
    isInputValidator: true,
  },
];
